import React, { useEffect, useState } from "react";

import { Input } from "../../../src/components/InputTemplate";
import ViewReport, { HeaderContainer } from "../admin/viewreport";
import { PixelStyle } from "../../../src/components/layout";
import { GetUserList } from "../admin/userList";
import { get, ref } from "firebase/database";
import { database } from "../../config/config";
import TableLayout, { Td, Tr } from "../../../src/components/tableLayout";
import { GoDownload } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { formattedDate } from "../../../src/components/functions/formatDates";
import { navItems } from "../../../src/components/sideNavComponents";
// ../../../src/components/loginLayout

const ClientReport = () => {
  const [filtered, setFiltered] = useState([]);
  const TableHead = ["create date",
    "Client Name",
    "Report Name",
    'Company Name',"submitted",
    "submitted by management", "Action"];
  const [generatedReports, setGeneratedReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [reportTemplates, setReportTemplates] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [reportName, setReportName] = useState("");
  const [reportTemplateId, setReportTemplateId] = useState(""); // State variable for selected reportTemplateId
  const [showReportData, setShowReportData] = useState("");
  const [searchTemplate, setSearchTemplate] = useState("");
  const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);
  const [templateSearchTerm, setTemplateSearchTerm] = useState("");
  const [clientLocations, setClientLocations] = useState("")
  const [clientName, setClientName] = useState("")

  const generatedReportRef = ref(database, "generatedReport");

  useEffect(() => {
    GetUserList().then((userList) => {
      console.log("userlist", userList);
      const filerType = userList.filter((user) => user.type === "Client");
      setUsers(filerType);
    });

    const reportTemplatesRef = ref(database, "reportTemplates");
    const clientLocationsRef = ref(database, "clientLocations");
    get(clientLocationsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const clientLocationsData = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setClientLocations(clientLocationsData);
        } else {
          setClientLocations([]); // Set to an empty array if no locations found
          console.log("No client locations found");
        }
      })
      .catch((error) => {
        setClientLocations([]); // Set to an empty array on error
        console.error(`Error fetching client locations: ${error.message}`);
      });

    get(reportTemplatesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const templatesArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setReportTemplates(templatesArray);
          // console.log(templatesArray);
        } else {
          setErrorMessage("No report templates found");
        }
      })
      .catch((error) => {
        setErrorMessage(`Error fetching report templates: ${error.message}`);
      });

      // 
  }, []);
  useEffect(() => {
    const fetchGeneratedReports = async () => {
      try {
        const snapshot = await get(generatedReportRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const reportsArray = Object.keys(data).map(async (key) => {
            const userId = data[key].userId;
            const userSnapshot = await get(ref(database, `users/${userId}`));
            if (userSnapshot.exists()) {
              const userData = userSnapshot.val();
              return {
                id: key,
                ...data[key],
                user: userData,
              };
            } else {
              console.log(`User with ID ${userId} not found`);
              return {
                id: key,
                ...data[key],
              };
            }
          });
          const resolvedReports = await Promise.all(reportsArray);
          setGeneratedReports(resolvedReports);
        } else {
          console.log("No generated reports found");
        }
      } catch (error) {
        console.error("Error fetching generated reports:", error);
      }
    };

    fetchGeneratedReports();
  }, []);

  const navigate = useNavigate()
console.log("generated", generatedReports)
  return (
    <div className={`w-full text-sm h-full ${PixelStyle} `}>
      <HeaderContainer pageTitle='Reporting'>
        <span className='text-black'>assign report</span>
      </HeaderContainer>

      <div className='w-full flex gap-3'>
        <div className='w-full flex   justify-between gap-3 lg:gap-10 bg-white shadow p-3 rounded-lg'>
          <div className='flex lg:flex-row flex-col lg:items-center  text-sm  gap-3 w-full'>
            <div className='flex items-center justify-between gap-3 w-full'>
              <Input
                className='w-full'
                type='text'
                placeholder='Report Name...'
              />
              <Input className='w-full' type='text' placeholder='Address...' />
              <Input className='w-full' type='date' placeholder='Date From' />
              <Input className='w-full' type='date' placeholder='Date to' />
            </div>
          </div>
        </div>

        <div className='bg-white shadow p-3 rounded-lg text-nowrap flex items-center'>
          <button
            className=' p-2 px-5 rounded-md bg-blue-500 text-white '
            // onClick={handleUpdateReportTemplate}
          >
            Filter
          </button>
        </div>
      </div>

      {!filtered.length > 0 && (
        <div className='bg-white border rounded-lg shadow mt-3 flex flex-col  w-full'>
          <div className='flex justify-between items-center w-full px-5 py-2'>
            <h4 className='text-2xl font-semibold'>Filtered Reports</h4>
          </div>
          <TableLayout
            TableHead={TableHead}
            // sortField={sortField}
            // sortOrder={sortOrder}
            toggleSortOrder=''
            headerFieldMapping={TableHead}
          >
           {generatedReports &&
              generatedReports.map((item, index) => {
                const reportName = reportTemplates.find(
                  (template) => template.id === item.reportTemplatesId
                );
                const clientName =  clientLocations.find((client) => client.id === item?.locationId)
                // console.log("clientName",clientName)
                return (
                  <Tr key={index}>
                    <Td>{formattedDate(item?.createdAt)}</Td>
                    <Td>{clientName?.clientName}</Td>
                    <Td>{reportName?.name}</Td>
                    <Td>{reportName?.companyName}</Td>
                    <Td className='font-semibold'>{item?.isSubmitted === true ? <p className='text-green-700 '>Submitted</p> : <p className='text-red-700 '>Not Submitted</p>}</Td>
                    <Td className='font-semibold'>{item?.isSubmittedByManagement === true ? <p className='text-green-700 '>Submitted</p> : <p className='text-red-700 '>Not Submitted</p>}</Td>
                   
                    <td
                      onClick={() =>
                        navigate(`/client-report/${item.id}`)
                      }
                    >
                      <button className='bg-blue-500 text-white px-3 py-2 rounded'>
                        View Report
                      </button>
                    </td>
                  </Tr>
                );
              })}
          </TableLayout>
        </div>
      )}
    </div>
  );
};

export default ClientReport;
