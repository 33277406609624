import React, { useEffect, useState } from "react";
import Layout, { PixelStyle } from "../../components/layout";
import TableLayout, { Td, Tr } from "../../components/tableLayout";
import { get, ref } from "firebase/database";
import { database } from "../../config/config";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BsCopy } from "react-icons/bs";
import Loader from "../../components/loader";
import { HeaderContainer } from "./viewreport";

export const TableHead = [
  // "S.N.",

  "first name",
  "last name",
  "email",
  "phone",
  "Street Address",
  "City",
  "State",
  "Zip Code",
  "Action",
];

export const UserTableHead = [
  "first name",
  "last name",
  "email",
  "phone",
  "company",
  "street address",
  "city",
  "state",
  "zip Code",
  "action",
];
export const GetUserList = async () => {
  const usersRef = ref(database, "users");

  try {
    const snapshot = await get(usersRef);

    if (snapshot.exists()) {
      const data = snapshot.val();
      const templatesArray = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));

      return templatesArray;
    } else {
      console.log("No User Profiles found");
      return [];
    }
  } catch (error) {
    console.error(`Error fetching User Profiles: ${error.message}`);
    return [];
  }
};

//  ----------------------------- EXPORT TO EXCEL FOR SECURITY AND MANAGEMENT LIST ----------------------------------
export const ExportPayloadSecond = (data) => {
  // Ensure data is always an array
  const payload = Array.isArray(data) ? data : [data];

  // Define the header mapping for renaming keys
  const headerMapping = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    streetAddress: "Street Address",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
  };

  // Map through each object in the payload
  const cleanedLeads = payload.map(
    ({ company, id, companyId, companyLocationId, createdAt, ...rest }) => {
      const renamedObject = {};

      // Rename keys according to the headerMapping
      Object.keys(rest).forEach((key) => {
        const newKey = headerMapping[key] || key;
        renamedObject[newKey] = rest[key];
      });

      return renamedObject;
    }
  );

  return cleanedLeads;
};
//  -----------------------------EXPORT TO EXCEL FOR USER LIST ----------------------------------
export const ExportPayloadClient = (data) => {
  // Ensure data is always an array
  const payload = Array.isArray(data) ? data : [data];

  // Define the header mapping for renaming keys
  const headerMapping = {
    companyLocation: "Company Location",
    companyName: "Company Name",
    companyId: "Company ID",
    companyLocationId: "Company Location ID",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    streetAddress: "Street Address",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
  };

  // Map through each object in the payload
  const cleanedLeads = payload.map(({ id, createdAt, ...rest }) => {
    const renamedObject = {};

    // Rename keys according to the headerMapping
    Object.keys(rest).forEach((key) => {
      const newKey = headerMapping[key] || key;
      renamedObject[newKey] = rest[key];
    });

    return renamedObject;
  });

  return cleanedLeads;
};

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedCompanyNames, setSelectedCompanyNames] = useState([]);
  const [selectedCompanyLocations, setSelectedCompanyLocations] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <div className={`w-full text-sm h-full ${PixelStyle} `}>
        <HeaderContainer pageTitle='security watch'>
          <span className='text-black'>security watch</span>
        </HeaderContainer>
        <div className='border'>
          <TableLayout TableHead={TableHead}>
            <>
              {users.length > 0 &&
                users.map((item, index) => {
                  return (
                    <Tr>
                      {/* <Td>{index + 1}</Td> */}
                      <Td>{item?.type}</Td>
                      <Td>{item?.name}</Td>
                      <Td>{item?.lname}</Td>
                      <Td>{item?.email}</Td>
                      <Td>{item?.company}</Td>
                      <Td>{item?.companyAdress}</Td>
                      <td className=''>
                        <div className='text-lg flex gap-1'>
                          {/* <button className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'>
                        <BsCopy />
                      </button>
                      <button className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'>
                        <MdOutlineRemoveRedEye />
                      </button>
                      <button className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'>
                        <FiEdit3 />
                      </button>
                      <button className='hover:text-red-500 hover:bg-red-100 p-1.5 rounded-lg'>
                        <RiDeleteBin6Line />
                      </button> */}
                        </div>
                      </td>
                    </Tr>
                  );
                })}
            </>
          </TableLayout>
          {!users.length > 0 && (
            <div className='flex justify-center items-center h-1/2 '>
              <Loader />
            </div>
          )}
        </div>
        <div className='py-20'></div>
      </div>
    </>
  );
};

export default UserList;
