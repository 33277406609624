import React, { useEffect, useState } from "react";
import { Input } from "../../components/InputTemplate";
import ViewReport, { HeaderContainer } from "../admin/viewreport";
import { PixelStyle } from "../../components/layout";
import { GetUserList } from "../admin/userList";
import { get, ref } from "firebase/database";
import { database } from "../../config/config";
import TableLayout, { Td, Tr } from "../../components/tableLayout";
import { GoDownload } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { formattedDate } from "../../components/functions/formatDates";
import { navItems } from "../../components/sideNavComponents";

const TableHead = [ "create date" , "Client Name","Report Name", "Company Name", "submitted", "Action"];

const ManagementReport = () => {
  const [users, setUsers] = useState([]);
  const [reportTemplates, setReportTemplates] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [reportName, setReportName] = useState("");
  const [reportTemplateId, setReportTemplateId] = useState(""); // State variable for selected reportTemplateId
  const [showReportData, setShowReportData] = useState("");
  const [searchTemplate, setSearchTemplate] = useState("");
  const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);
  const [templateSearchTerm, setTemplateSearchTerm] = useState("");
  const [clientLocations, setClientLocations] = useState("")
  const [clientName, setClientName] = useState("")

  const [isTemplateDropdownVisible, setTemplateDropdownVisible] =
    useState(false);
  useEffect(() => {
    GetUserList().then((userList) => {
      console.log("userlist", userList);
      const filerType = userList.filter((user) => user.type === "Client");
      setUsers(filerType);
    });

    const reportTemplatesRef = ref(database, "reportTemplates");
    const clientLocationsRef = ref(database, "clientLocations");
    get(clientLocationsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const clientLocationsData = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setClientLocations(clientLocationsData);
        } else {
          setClientLocations([]); // Set to an empty array if no locations found
          console.log("No client locations found");
        }
      })
      .catch((error) => {
        setClientLocations([]); // Set to an empty array on error
        console.error(`Error fetching client locations: ${error.message}`);
      });

    get(reportTemplatesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const templatesArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setReportTemplates(templatesArray);
          // console.log(templatesArray);
        } else {
          setErrorMessage("No report templates found");
        }
      })
      .catch((error) => {
        setErrorMessage(`Error fetching report templates: ${error.message}`);
      });

      // 
  }, []);

  const SearchfilteredTemplates = reportTemplates.filter((template) =>
    template.name.toLowerCase().includes(searchTemplate.toLowerCase())
  );

  console.log("SearchfilteredTemplates",SearchfilteredTemplates)

  const handleSelect = (templateId, templateName, templateLocation) => {
    console.log("templateId, templateName, templateLocation",templateId, templateName, templateLocation)
    setReportTemplateId(templateId);
    if (searchTemplate) {
      setSearchTemplate(templateName); // Update the search input with the selected template name
    }
    if (templateSearchTerm) {
      setTemplateSearchTerm(templateName);
      setShowReportData({
        ...showReportData,
        companyLocation: templateLocation,
      });
      // console.log("templateName",templateName)
    }
    setTemplateDropdownVisible(false);
    setShowTemplateDropdown(false); // Hide the dropdown after selection
  };
  const [generatedReports, setGeneratedReports] = useState([]);

  const generatedReportRef = ref(database, "generatedReport");

  // useEffect(() => {
  //   const reportTemplatesRef = ref(database, "reportTemplates");
  //   // Fetch data from Firebase Realtime Database
  //   get(reportTemplatesRef)
  //     .then((snapshot) => {
  //       if (snapshot.exists()) {
  //         const data = snapshot.val();
  //         const templatesArray = Object.keys(data).map((key) => ({
  //           id: key,
  //           ...data[key],
  //         }));
  //         setReportTemplates(templatesArray);
  //       } else {
  //         setErrorMessage("No report templates found");
  //       }
  //     })
  //     .catch((error) => {
  //       setErrorMessage(`Error fetching report templates: ${error.message}`);
  //     });
  // }, []);

  useEffect(() => {
    const fetchGeneratedReports = async () => {
      try {
        const snapshot = await get(generatedReportRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const reportsArray = Object.keys(data).map(async (key) => {
            const userId = data[key].userId;
            const userSnapshot = await get(ref(database, `users/${userId}`));
            if (userSnapshot.exists()) {
              const userData = userSnapshot.val();
              return {
                id: key,
                ...data[key],
                user: userData,
              };
            } else {
              console.log(`User with ID ${userId} not found`);
              return {
                id: key,
                ...data[key],
              };
            }
          });
          const resolvedReports = await Promise.all(reportsArray);
          setGeneratedReports(resolvedReports);
        } else {
          console.log("No generated reports found");
        }
      } catch (error) {
        console.error("Error fetching generated reports:", error);
      }
    };

    fetchGeneratedReports();
  }, []);

  // console.log("generatedReports", generatedReports);
  // console.log("reportTemplates", reportTemplates);
  // console.log("showReportData", showReportData);

const [filteredData,setFilteredData] = useState([])



  // Search Client Name
const [filterClientName,setFilterClientName] = useState([])
  useEffect(() => {
    const filteredClients = clientLocations ? clientLocations.filter((client) => 
      client.clientName && client.clientName.toLowerCase().includes(clientName.toLowerCase())
    ) : [];
     setFilterClientName(filteredClients)
    console.log("Filtered Clients", filteredClients);
  }, [clientName]);

  const [selectedClientNameId,setSelectedClientNameId] = useState("")
  const [openSearchBarClientName,setOpenSearchBarClientName] = useState(false)




  const navigate = useNavigate();
  return (
    <div className={`w-full text-sm h-full ${PixelStyle} `}>
      <HeaderContainer pageTitle='Reporting'>
        <span className='text-black'>assign report</span>
      </HeaderContainer>

      <div className='w-full flex gap-3'>
        <div className='w-full flex   justify-between gap-3 lg:gap-10 bg-white shadow p-3 rounded-lg'>
          <div className='flex lg:flex-row flex-col lg:items-center  text-sm  gap-3 w-full'>
            <div className='flex items-center justify-between gap-3 w-full'>
              <div className='relative w-full'>
                <Input className='w-[85%]' type='text' placeholder='Client Name' value={clientName} onChange={(e) =>{ setClientName(e.target.value); setOpenSearchBarClientName(e.target.value ==="" ? false : true )} } />
                {openSearchBarClientName && filterClientName.length > 0 && (
                  <ul className='absolute p-3 z-10 w-full mt-1 border border-gray-300 rounded bg-white shadow-lg max-h-52 overflow-y-auto'>
                    {filterClientName.map((client) => (
                      <li
                        key={client.id}
                        className='p-2 border-b hover:bg-gray-200 cursor-pointer'
                        onClick={() => {
                          setClientName(client.clientName);
                          setSelectedClientNameId(client.id)
                          setOpenSearchBarClientName(false)
                          setFilterClientName([]); // Clear the dropdown after selection
                        }}
                      >
                        {client.clientName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className='relative w-full'>
                <input
                  type='text'
                  placeholder='Search Report Template'
                  className='w-full p-2 border rounded shadow-sm outline-none'
                  value={searchTemplate}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSearchTemplate(value);
                    if (value) {
                      setTemplateSearchTerm(value)
                      setShowTemplateDropdown(true);
                    } else {
                      setShowTemplateDropdown(false);
                    }
                  }}
                />
                {showTemplateDropdown &&
                 (
                  <ul className='absolute p-3 z-10 w-full mt-1 border border-gray-300 rounded bg-white shadow-lg max-h-52 overflow-y-auto assignReportScroll'>
                    {SearchfilteredTemplates.length > 0 ? (
                      SearchfilteredTemplates.map(
                        (template) =>
                          template.name && (
                            <li
                              key={template.id}
                              className='p-2 border-b hover:bg-gray-200 cursor-pointer flex items-center gap-3'
                              onClick={() =>
                                handleSelect(template.id, template.name, template.companyLocation)
                              }
                            >
                              <div className='flex flex-col'>
                                <span className=' text-base font-semibold'>
                                  {template.name && template.name}
                                </span>
                                <span className=' flex '>
                                  <span className=''>
                                    {template.companyName}{" "}
                                  </span>
                                </span>
                                <span className='text-zinc-400 '>
                                  {template.companyLocation &&
                                    template.companyLocation}
                                </span>
                              </div>
                            </li>
                          )
                      )
                    ) : (
                      <li className='p-2 text-gray-500'>No templates found</li>
                    )}
                  </ul>
               
               )}
              </div>

              <Input className='w-full' type='text' value={showReportData?.companyLocation} placeholder='Address' disabled />
              <Input className='w-[75%]' type='date' placeholder='Date From' />
              <Input className='w-[75%]' type='date' placeholder='Date to' />
            </div>
          </div>
        </div>
        <div className='bg-white shadow p-3 rounded-lg text-nowrap flex items-center'>
          <button
            className=' p-2 lg:px-10 px-5 rounded-md bg-blue-500 text-white '
            // onClick={handleUpdateReportTemplate}
          >
            Filter
          </button>
        </div>
      </div>

      <div className='mt-10'>
        {/* {generatedReports.map((item, index) => {
          // const clientName = users.find((user) => user.id === item.userId)?.name;
          return (
            <>
            asdasd
              <div>{reportName}</div>
            </>
          );
        })} */}

        <div className='border bg-white rounded '>
          <div className='flex justify-between items-center w-full px-5 py-2'>
            <h4 className='text-2xl font-semibold'>Report Templates</h4>
            {/* <button
              className='text-zinc-500 p-2 rounded flex items-center gap-2'
              // onClick={() => ExportToExcel(ExportPayload(reportTemplates))}
            >
              <GoDownload className='text-2xl' />
            </button> */}
          </div>

          <TableLayout
            TableHead={TableHead}
            //   sortField={sortField}
            //   sortOrder={sortOrder}
            //   toggleSortOrder={toggleSortOrder}
            headerFieldMapping={TableHead}
          >
            {generatedReports &&
              generatedReports.map((item, index) => {
                const reportName = reportTemplates.find(
                  (template) => template.id === item.reportTemplatesId
                );
                const clientName =  clientLocations.find((client) => client.id === item?.locationId)
                // console.log("clientName",clientName)
                return ( item?.isSubmitted === true &&
                  <Tr key={index}>
                    <Td>{formattedDate(item?.createdAt)}</Td>
                    <Td>{clientName?.clientName}</Td>
                    <Td>{reportName?.name}</Td>
                    <Td>{reportName?.companyName}</Td>
                    <Td className='font-semibold'>{item?.isSubmittedByManagement === true ? <p className='text-green-700 '>Submitted</p> : <p className='text-red-700 '>Not Submitted</p>}</Td>
                   
                    <td
                      onClick={() =>
                        navigate(`/viewmanagementreport/${item.id}`)
                      }
                    >
                      <button className='bg-blue-500 text-white px-3 py-2 rounded'>
                        View Report
                      </button>
                    </td>
                  </Tr>
                );
              })}
          </TableLayout>
        </div>
      </div>
    </div>
  );
};

export default ManagementReport;
