import React, { useEffect, useState } from "react";
import Layout, { PixelStyle } from "../../components/layout";
import { HeaderContainer } from "./viewreport";
import { ExportPayloadSecond, GetUserList, TableHead } from "./userList";
import Loader from "../../components/loader";
import TableLayout, { Td, Tr } from "../../components/tableLayout";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GoDownload } from "react-icons/go";
import ExportToExcel from "../../components/exportToExcel";
import {
  equalTo,
  orderByChild,
  query,
  ref,
  remove,
  get,
} from "firebase/database";
import { database } from "../../config/config";
import EditUserListComponents from "../../components/editUserListComponents";
import { handleDelete } from "../../components/functions/deleteUserList";

const Managementlist = () => {
  const [users, setUsers] = useState([]);

  console.log("userlist");

  useEffect(() => {
    GetUserList().then((userList) => {
      console.log("userlist", userList);
      const filerType = userList.filter((user) => user.type === "Management");
      setUsers(filerType);
    });
  }, []);

  // const handleDelete = (userId) => {
  //   const isConfirmed = window.confirm(
  //     "Are you sure you want to delete this user and their associated reports?"
  //   );
  //   if (!isConfirmed) return;

  //   // Reference to the user's data
  //   const usersRef = ref(database, `users/${userId}`);

  //   // Reference to the report access permissions
  //   const reportsRef = ref(database, `reportUserAccessPermissions`);

  //   // Start by deleting the user
  //   remove(usersRef)
  //     .then(() => {
  //       // Query for reports with the matching userId
  //       const reportsQuery = query(
  //         reportsRef,
  //         orderByChild("userId"),
  //         equalTo(userId)
  //       );

  //       // Get the data to identify which reports to delete
  //       get(reportsQuery)
  //         .then((snapshot) => {
  //           if (snapshot.exists()) {
  //             const deletePromises = [];

  //             snapshot.forEach((childSnapshot) => {
  //               // Remove each report associated with the userId
  //               const reportRef = ref(
  //                 database,
  //                 `reportUserAccessPermissions/${childSnapshot.key}`
  //               );
  //               deletePromises.push(remove(reportRef));
  //             });

  //             // Execute all deletions
  //             Promise.all(deletePromises)
  //               .then(() => {
  //                 // If both deletions succeed, update the local state
  //                 setUsers(users.filter((user) => user.id !== userId));
  //               })
  //               .catch((error) => {
  //                 console.error(
  //                   `Error deleting associated reports: ${error.message}`
  //                 );
  //               });
  //           } else {
  //             console.log("No reports found for this user.");
  //             setUsers(users.filter((user) => user.id !== userId));
  //           }
  //         })
  //         .catch((error) => {
  //           console.error(`Error querying reports: ${error.message}`);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error(`Error deleting user: ${error.message}`);
  //     });
  // };

  // Apply filters to users data
  // ------------------------------ FILTERS --------------------------------------------
  // --------------------------------- SHORTING ---------------------------------------

  const [sortedReports, setSortedReports] = useState([]);

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null); // "asc" or "desc"

  const headerFieldMapping = {
    "first name": "firstName",
    "last name": "lastName",
    email: "email",
    phone: "phone",
    "Street Address": "streetAddress",
    City: "city",
    State: "state",
    "Zip Code": "zipCode",
  };

  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  // Apply filters to users data

  const toggleSortOrder = (header) => {
    const field = headerFieldMapping[header];
    if (field) {
      handleSort(field);
    }
  };
  const handleSort = (field) => {
    if (sortField === field) {
      if (sortOrder === "asc") {
        setSortOrder("desc");
      } else if (sortOrder === "desc") {
        setSortOrder(null); // Reset to original order on third click
        setSortField(null);
      } else {
        setSortOrder("asc");
      }
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    const filteredUsers = users.filter((user) => {
      return (
        (!filters.firstName ||
          user.firstName
            ?.toLowerCase()
            .includes(filters.firstName.toLowerCase())) &&
        (!filters.lastName ||
          user.lastName
            ?.toLowerCase()
            .includes(filters.lastName.toLowerCase())) &&
        (!filters.email ||
          user.email?.toLowerCase().includes(filters.email.toLowerCase())) &&
        (!filters.phone || user.phone?.includes(filters.phone)) &&
        (!filters.company ||
          user.company
            ?.toLowerCase()
            .includes(filters.company.toLowerCase())) &&
        (!filters.streetAddress ||
          user.streetAddress
            ?.toLowerCase()
            .includes(filters.streetAddress.toLowerCase())) &&
        (!filters.city ||
          user.city?.toLowerCase().includes(filters.city.toLowerCase())) &&
        (!filters.state ||
          user.state?.toLowerCase().includes(filters.state.toLowerCase())) &&
        (!filters.zipCode || user.zipCode?.includes(filters.zipCode))
      );
    });

    if (sortField) {
      const sorted = [...filteredUsers].sort((a, b) => {
        const aValue = a[sortField]?.toLowerCase() || "";
        const bValue = b[sortField]?.toLowerCase() || "";

        if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
        if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });

      setSortedReports(sorted);
    } else {
      setSortedReports(filteredUsers);
    }
  }, [filters, sortField, users, sortOrder]);

  const [editList, setEditList] = useState(false);
  const [editComponents, setEditComponents] = useState("");

  return (
    <>
      <div className={`w-full text-sm h-full ${PixelStyle} `}>
        <HeaderContainer pageTitle=' Management User List'>
          <span className='text-black'>Management User List</span>
        </HeaderContainer>
        <div className='border'>
          <div className='flex justify-between items-center w-full px-5 py-2'>
            <h4 className='text-2xl font-semibold'>User List</h4>
            <button
              className='text-zinc-500 p-2 rounded flex items-center gap-2'
              onClick={() => ExportToExcel(ExportPayloadSecond(users))}
            >
              <GoDownload className='text-2xl' />
            </button>
          </div>
          <TableLayout
            TableHead={TableHead}
            sortField={sortField}
            sortOrder={sortOrder}
            toggleSortOrder={toggleSortOrder}
            headerFieldMapping={headerFieldMapping}
          >
            <>
              <tr className='border-b bg-zinc-50 '>
                {TableHead.map((header, index) => (
                  <th scope='col' className='px-6 py-1' key={index}>
                    {/* Render input only for filterable fields */}
                    {header !== "Action" ? (
                      <input
                        placeholder={`Filter by ${header}`}
                        name={headerFieldMapping[header]} // map header to the user field
                        value={filters[headerFieldMapping[header]] || ""}
                        onChange={handleFilterChange}
                        className='border p-1 px-2 rounded-md font-normal outline-blue-500'
                      />
                    ) : null}
                  </th>
                ))}
                <th></th>
              </tr>

              {sortedReports.length > 0 &&
                sortedReports.map((item, index) => {
                  return (
                    <Tr>
                      {/* <Td>{index + 1}</Td> */}
                      {/* <Td>{item?.type}</Td> */}
                      <Td>{item?.firstName}</Td>
                      <Td>{item?.lastName}</Td>
                      <Td>{item?.email}</Td>
                      <Td>{item?.phone}</Td>

                      <Td>{item?.streetAddress}</Td>
                      <Td>{item?.city}</Td>
                      <Td>{item?.state}</Td>
                      <Td>{item?.zipCode}</Td>

                      <td className=''>
                        <div className='text-lg flex gap-1'>
                          {/* <button className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'>
                    <BsCopy />
                  </button> */}
                          {/* <button className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'>
                    <MdOutlineRemoveRedEye />
                  </button> */}
                          <button
                            className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'
                            onClick={() => {
                              setEditList(true);
                              setEditComponents(item);
                            }}
                          >
                            <FiEdit3 />
                          </button>
                          <button
                            className='hover:text-red-500 hover:bg-red-100 p-1.5 rounded-lg'
                            onClick={() =>
                              handleDelete(item?.id, setUsers, users)
                            }
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </td>
                    </Tr>
                  );
                })}
            </>
          </TableLayout>
          {!users.length > 0 && (
            <div className='flex justify-center items-center h-1/2 '>
              <Loader />
            </div>
          )}
        </div>
        <div className='py-20'></div>
      </div>

      {editList && (
        <EditUserListComponents
          setEditList={setEditList}
          editComponents={editComponents}
          setUsers={setUsers}
          users={users}
          // containCompany={true}
        />
      )}
    </>
  );
};

export default Managementlist;
