import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import { auth, database } from "../../config/config";
import LoginLayout from "../../components/loginLayout";
import { Input } from "../../components/loginLayout";
import { getFunctions, httpsCallable } from "firebase/functions";

function AdminRegister() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       navigate("/");
  //     }
  //   });
  //   return () => unsubscribe();
  // }, []);

  const EmailSentHandler = async (user) => {


    const functions = getFunctions();
    const sendAccountApprovalEmail = httpsCallable(functions, "sendAccountApprovalEmail");

    try {
      // Call the cloud function to send email
      await sendAccountApprovalEmail({
        email: user?.email,
        displayName: firstName,
        uid: user?.uid, // Ideally, this would be the user's Firebase UID
      });

      console.log("Account approval email sent successfully");
    } catch (error) {
      console.error("Error sending account approval email:", error);
    }
  };


  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== cpassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      // console.log("user", user);
      EmailSentHandler(user)
      const userRef = ref(database, "users/" + user.uid);

      await set(userRef, {
        company,
        email,
        firstName,
        lastName,
        password,
        type: "Admin",
        verified: false,
      });
      
      await auth.signOut();

      setSuccessMessage("Registration successful! Redirecting to login...");
      // setTimeout(() => {
      //   navigate("/");
      // }, 3000);
    } catch (error) {
      console.error("Registration failed:", error);
      setError(
        "An error occurred during registration. Please try again later."
      );
    }
  };

  
  return (
    <LoginLayout
      error={error}
      onSubmit={handleRegister}
      loginLink='/'
      Title='Register'
      layoutType='register'
    >
     
        <div className='flex gap-4 md:flex-row flex-col'>
          <Input
            type='text'
            placeholder='First Name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
       
          <Input
            type='text'
            placeholder='Last Name'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
       </div>
      <Input
        type='text'
        placeholder='Company Name'
        value={company}
        onChange={(e) => setCompany(e.target.value)}
        required
      />
      <Input
        type='email'
        placeholder='Email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
         <div className='flex gap-4 md:flex-row flex-col'>
        <Input
          type='password'
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Input
          type='password'
          placeholder='Confirm Password'
          value={cpassword}
          onChange={(e) => setCPassword(e.target.value)}
          required
        />
    </div>
      {successMessage && (
        <div className='mt-4 text-green-600'>{successMessage}</div>
      )}
    </LoginLayout>
  );
}

export default AdminRegister;
