import { ref, remove } from "firebase/database";
import { database, functions } from "../../config/config";
import { httpsCallable } from "firebase/functions";

export const handleDelete = (userId, setUsers, users) => {
  const isConfirmed = window.confirm(
    "Are you sure you want to delete this user and their associated reports?"
  );
  if (!isConfirmed) return;

  // Reference to the user's data
  const usersRef = ref(database, `users/${userId}`);

  // Reference to the report access permissions
  const reportsRef = ref(database, `reportUserAccessPermissions`);
  console.log("userId", userId);

  remove(usersRef)
    .then(async () => {
      // Remove the user from Firebase Authentication
      const deleteUserFunction = httpsCallable(functions, "deleteUser");
      await deleteUserFunction({ uid: userId });

      // Filter out the deleted template from the state
      setUsers(users.filter((user) => user.id !== userId));
    })
    .catch((error) => {
      // setErrorMessage(`Error deleting User: ${error.message}`);
      console.error(error);
    });

  // Start by deleting the user
  // remove(usersRef)
  //   .then(() => {
  //     // Query for reports with the matching userId
  //     const reportsQuery = query(
  //       reportsRef,
  //       orderByChild("userId"),
  //       equalTo(userId)
  //     );

  //     // Get the data to identify which reports to delete
  //     get(reportsQuery)
  //       .then((snapshot) => {
  //         if (snapshot.exists()) {
  //           const deletePromises = [];

  //           snapshot.forEach((childSnapshot) => {
  //             // Remove each report associated with the userId
  //             const reportRef = ref(
  //               database,
  //               `reportUserAccessPermissions/${childSnapshot.key}`
  //             );
  //             deletePromises.push(remove(reportRef));
  //           });

  //           // Execute all deletions
  //           Promise.all(deletePromises)
  //             .then(() => {
  //               // If both deletions succeed, update the local state
  //               setUsers(users.filter((user) => user.id !== userId));
  //             })
  //             .catch((error) => {
  //               console.error(
  //                 `Error deleting associated reports: ${error.message}`
  //               );
  //             });
  //         } else {
  //           console.log("No reports found for this user.");
  //           setUsers(users.filter((user) => user.id !== userId));
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(`Error querying reports: ${error.message}`);
  //       });
  //   })
  //   .catch((error) => {
  //     console.error(`Error deleting user: ${error.message}`);
  //   });
};
