import React from "react";
import Layout, { PixelStyle } from "../../components/layout";
import { HeaderContainer } from "./viewreport";

const ClientHours = () => {
  return (
    <>
      <div className={`w-full text-sm h-full ${PixelStyle} `}>
        <HeaderContainer pageTitle='security watch'>
          <span className='text-black'>security watch</span>
        </HeaderContainer>
      </div>
    </>
  );
};

export default ClientHours;
