import React, { useEffect, useState } from "react";
import Layout, { PixelStyle } from "../../components/layout";
import { HeaderContainer } from "./viewreport";
import { ExportPayloadClient, GetUserList, UserTableHead } from "./userList";
import Loader from "../../components/loader";
import TableLayout, { Td, Tr } from "../../components/tableLayout";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import ExportToExcel from "../../components/exportToExcel";
import { GoDownload } from "react-icons/go";
// import { getAuth, deleteUser } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import {
  query,
  orderByChild,
  equalTo,
  get,
  ref,
  remove,
  update,
} from "firebase/database";
import { database, functions } from "../../config/config";
import ShowOnTop, { Form_Container } from "../../components/showOnTop";
import { Input } from "../../components/InputTemplate";
import EditUserListComponents from "../../components/editUserListComponents";
import { handleDelete } from "../../components/functions/deleteUserList";

const UserListclient = () => {
  const [users, setUsers] = useState([]);
  const [sortedReports, setSortedReports] = useState([]);

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null); // "asc" or "desc"

  console.log("userlist");

  useEffect(() => {
    GetUserList().then((userList) => {
      console.log("userlist", userList);
      const filerType = userList.filter((user) => user.type === "Client");
      setUsers(filerType);
    });
  }, []);

  // const handleDelete = (userId) => {
  //   const isConfirmed = window.confirm(
  //     "Are you sure you want to delete this user?"
  //   );
  //   if (!isConfirmed) return;

  //   const usersRef = ref(database, `users/${userId}`);

  //   remove(usersRef)
  //     .then(() => {
  //       setUsers(users.filter((user) => user.id !== userId));
  //     })
  //     .catch((error) => {
  //       // setErrorMessage(`Error deleting report template: ${error.message}`);
  //     });
  // };

  // const handleDelete = (userId) => {
  //   const isConfirmed = window.confirm(
  //     "Are you sure you want to delete this user and their associated reports?"
  //   );
  //   if (!isConfirmed) return;

  //   // Reference to the user's data
  //   const usersRef = ref(database, `users/${userId}`);

  //   // Reference to the report access permissions for this user
  //   const reportsRef = ref(database, `reportUserAccessPermissions`);
  //   console.log("reportsRef", reportsRef);
  //   // const reportsRef
  //   // Start by deleting the user
  //   remove(usersRef)
  //     .then(() => {
  //       // After user deletion, delete the associated reports
  //       remove(reportsRef, {
  //         query: {
  //           orderByChild: "userId",
  //           equalTo: userId,
  //         },
  //       })
  //         .then(() => {
  //           // If both deletions succeed, update the local state
  //           setUsers(users.filter((user) => user.id !== userId));
  //         })
  //         .catch((error) => {
  //           console.error(
  //             `Error deleting associated reports: ${error.message}`
  //           );
  //           // setErrorMessage(`Error deleting associated reports: ${error.message}`);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error(`Error deleting user: ${error.message}`);
  //       // setErrorMessage(`Error deleting user: ${error.message}`);
  //     });
  // };

  

  // ------------------ ------------------ FILTER ---------------------------------------------------

  const headerFieldMapping = {
    "first name": "firstName",
    "last name": "lastName",
    email: "email",
    phone: "phone",
    company: "company",
    "street address": "streetAddress",
    city: "city",
    state: "state",
    "zip Code": "zipCode",
  };

  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  // Apply filters to users data

  // --------------------------------- SHORTING ---------------------------------------
  const toggleSortOrder = (header) => {
    const field = headerFieldMapping[header];
    if (field) {
      handleSort(field);
    }
  };
  const handleSort = (field) => {
    if (sortField === field) {
      if (sortOrder === "asc") {
        setSortOrder("desc");
      } else if (sortOrder === "desc") {
        setSortOrder(null); // Reset to original order on third click
        setSortField(null);
      } else {
        setSortOrder("asc");
      }
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    const filteredUsers = users.filter((user) => {
      return (
        (!filters.firstName ||
          user.firstName
            ?.toLowerCase()
            .includes(filters.firstName.toLowerCase())) &&
        (!filters.lastName ||
          user.lastName
            ?.toLowerCase()
            .includes(filters.lastName.toLowerCase())) &&
        (!filters.email ||
          user.email?.toLowerCase().includes(filters.email.toLowerCase())) &&
        (!filters.phone || user.phone?.includes(filters.phone)) &&
        (!filters.company ||
          user.company
            ?.toLowerCase()
            .includes(filters.company.toLowerCase())) &&
        (!filters.streetAddress ||
          user.streetAddress
            ?.toLowerCase()
            .includes(filters.streetAddress.toLowerCase())) &&
        (!filters.city ||
          user.city?.toLowerCase().includes(filters.city.toLowerCase())) &&
        (!filters.state ||
          user.state?.toLowerCase().includes(filters.state.toLowerCase())) &&
        (!filters.zipCode || String(user.zipCode).includes(filters.zipCode)) // Convert zipCode to string
      );
    });

    if (sortField) {
      const sorted = [...filteredUsers].sort((a, b) => {
        const aValue = a[sortField]?.toLowerCase() || "";
        const bValue = b[sortField]?.toLowerCase() || "";

        if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
        if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });

      setSortedReports(sorted);
    } else {
      setSortedReports(filteredUsers);
    }
  }, [filters, sortField, users, sortOrder]);

  // Edit List

  const [editList, setEditList] = useState(false);
  const [editComponents, setEditComponents] = useState("");

  return (
    <>
      <div className={`w-full text-sm h-full   ${PixelStyle} `}>
        <HeaderContainer pageTitle='Client User List'>
          <span className='text-black'>Client User List</span>
        </HeaderContainer>
        <div className='border'>
          <div className='flex justify-between items-center w-full px-5 py-2'>
            <h4 className='text-2xl font-semibold'>User List</h4>
            <button
              className='text-zinc-500 p-2 rounded flex items-center gap-2'
              onClick={() => ExportToExcel(ExportPayloadClient(users))}
            >
              <GoDownload className='text-2xl' />
            </button>
          </div>
          <TableLayout
            TableHead={UserTableHead}
            sortField={sortField}
            sortOrder={sortOrder}
            toggleSortOrder={toggleSortOrder}
            headerFieldMapping={headerFieldMapping}
          >
            <>
              <tr className='border-b bg-zinc-50 '>
                {UserTableHead.map((header, index) =>
                  header !== "action" ? (
                    <th scope='col' className='px-6 py-1' key={index}>
                      {/* Render input only for filterable fields */}
                      {header !== "Action" ? (
                        <input
                          placeholder={`Filter by ${header}`}
                          name={headerFieldMapping[header]} // map header to the user field
                          value={filters[headerFieldMapping[header]] || ""}
                          onChange={handleFilterChange}
                          className='border p-1 px-2 rounded-md font-normal outline-blue-500'
                        />
                      ) : null}
                    </th>
                  ) : (
                    <th></th>
                  )
                )}
                <th></th>
              </tr>

              {sortedReports.length > 0 &&
                sortedReports.map((item, index) => {
                  return (
                    item.type === "Client" && (
                      <Tr>
                        {/* <Td>{index + 1}</Td> */}
                        {/* <Td>{item?.type}</Td> */}
                        <Td>{item?.firstName}</Td>
                        <Td>{item?.lastName}</Td>
                        <Td>{item?.email}</Td>
                        <Td>{item?.phone}</Td>
                        <Td>{item?.company}</Td>
                        <Td>{item?.streetAddress}</Td>
                        <Td>{item?.city}</Td>
                        <Td>{item?.state}</Td>
                        <Td>{item?.zipCode}</Td>

                        <td className=''>
                          <div className='text-lg flex gap-1'>
                            {/* <button className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'>
                        <BsCopy />
                      </button> */}
                            {/* <button className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'>
                        <MdOutlineRemoveRedEye />
                      </button> */}
                            <button
                              className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'
                              onClick={() => {
                                setEditList(true);
                                setEditComponents(item);
                              }}
                            >
                              <FiEdit3 />
                            </button>
                            <button
                              className='hover:text-red-500 hover:bg-red-100 p-1.5 rounded-lg'
                              onClick={() => handleDelete(item?.id, setUsers,users)}
                            >
                              <RiDeleteBin6Line />
                            </button>
                          </div>
                        </td>
                      </Tr>
                    )
                  );
                })}
            </>
          </TableLayout>
          {!users.length > 0 && (
            <div className='flex justify-center items-center h-1/2 '>
              <Loader />
            </div>
          )}
        </div>
        <div className='py-20'></div>

        {/* Edit Client List */}
      </div>
      {editList && (
        <EditUserListComponents
          setEditList={setEditList}
          editComponents={editComponents}
          setUsers={setUsers}
          users={users}
          containCompany={true}
        />
      )}
    </>
  );
};

export default UserListclient;
