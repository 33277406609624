import React, { useEffect, useState } from "react";
import SideNav from "./sideNav";
import { BsTextIndentRight } from "react-icons/bs";
import { useAppContext } from "../context/appContext";
import SecondSideNav from "./secondSideNav";
import { MapScheduleGuardManagement } from "./sideNavComponents";
import {
  MdFormatIndentIncrease,
  MdOutlineFormatIndentDecrease,
  MdOutlineFormatIndentIncrease,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

// const sideNav_WIDTH = 15

const Layout = ({ children }) => {
  const { showSideNav, setShowSideNav, removeNav, setRemoveNav, UserType } =
    useAppContext();
  // const [] = useState(false);

  // console.log(removeNav);

  const location = useLocation();
  const PathName = location.pathname;

  return UserType?.type === "Admin" ? (
    <div className='h-screen flex bg-white overflow-hidden'>
      <SideNav
        className={`h-full transition-width ease-out duration-500 hidden lg:block bg-white border-r  border-zinc-100 ${
          !showSideNav ? "w-16" : "w-[20%] lg:w-[16rem]"
        }`}
      />

      <div
        className={`transition-all  relative flex flex-col items-center bg-zinc-50  w-full  ${
          !showSideNav
            ? "lg:w-[calc(100%-4rem)]  "
            : "lg:w-[calc(100%-16rem)] duration-500"
        } h-full`}
      >
        {removeNav && (
          <div
            className={`absolute z-50  transition-width ease-in-out duration-500 w-full h-full lg:hidden    ${
              removeNav ? "w-full block" : "w-0 hidden "
            } `}
          >
            <SecondSideNav
              className={`absolute top-0 left-0 w-60 h-full transition-width ease-in-out duration-500 bg-white ${
                removeNav ? "w-60 " : ""
              }`}
            />

            <div
              className='bg-black/30 h-full w-full'
              onClick={() => setRemoveNav(false)}
            ></div>
          </div>
        )}

        <Header className='h-12 w-full block lg:hidden' />
        <div className='w-full flex justify-center overflow-y-auto lg:h-full h-[calc(100%-3rem)] '>
          <div className=' h-full flex justify-center  w-full xl:max-w-[1600px] 2xl:max-w-[2500px] relative '>
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='relative'>{children}</div>
  );
};

export default Layout;

export const PixelStyle =
  "md:px-10 xl:px-10 2xl:px-28 pb-10 px-10 w-full  globalScroll";

export const Header = ({ className }) => {
  const { showSideNav, setShowSideNav, removeNav, setRemoveNav } =
    useAppContext();
  // console.log(showSideNav);
  return (
    <div
      className={`${className} flex items-center gap-2 justify-between py-2`}
    >
      <button
        className='text-xl hover:bg-zinc-200 border border-transparent hover:border-zinc-400 p-2 rounded-md'
        onClick={() => {
          setShowSideNav(!showSideNav);
          setRemoveNav(!removeNav);
        }}
      >
        {showSideNav ? (
          <MdOutlineFormatIndentDecrease />
        ) : (
          <MdOutlineFormatIndentIncrease />
        )}
      </button>
      <div className='flex items-center gap-2 '>
        {/* <img
          src='https://robohash.org/a5b6f884b905457c348f699eb265c5fa?set=set4&bgset=&size=400x400'
          className='bg-green-200 rounded-full p-1 h-8'
          alt=''
        /> */}
        {/* <h1 className='text-xl font-semibold'>John</h1> */}
      </div>
    </div>
  );
};
