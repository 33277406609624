import React from "react";
import Layout, { PixelStyle } from "../../components/layout";
import { HeaderContainer } from "./viewreport";

const Invoicing = () => {
  return (
    <>
      <div className={`w-full text-sm h-full ${PixelStyle} `}>
        <HeaderContainer pageTitle='Invoicing'>
          <span className='text-black'>Invoicing</span>
        </HeaderContainer>
      </div>
    </>
  );
};

export default Invoicing;
