import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth"; // Import if you're using onAuthStateChanged
import { Navigate } from "react-router-dom"; // Or whatever routing you are using
import { auth } from "./config";

function ProtectedRoute({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // To handle the initial loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user || null);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    // You can replace this with a loading spinner or any loading component
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    // User is not logged in, redirect to login page
    localStorage.removeItem("maxUser");

    return <Navigate to='/' />;
  }

  return children;
}

export default ProtectedRoute;
