import React from "react";
import Layout, { PixelStyle } from "../../components/layout";
import { HeaderContainer } from "./viewreport";
import { NavLink } from "react-router-dom";

const CostumizeAssignreport = () => {
  return (
    <>
      <div className={`w-full text-sm h-full ${PixelStyle} `}>
        <HeaderContainer pageTitle='Customize Report'>
          <NavLink to='/assignreport' className='hover:text-black'>
            assign report
          </NavLink>
          <span className=''>/</span>
          <span className='text-black'>Customize report</span>
        </HeaderContainer>
      </div>
    </>
  );
};

export default CostumizeAssignreport;
