import React, { useEffect, useId, useState } from "react";
import Layout, { PixelStyle } from "../../components/layout";
import { database } from "../../config/config";
import { get, push, ref, set } from "firebase/database";
import { IoIosAdd } from "react-icons/io";
import { MdOutlineAdd } from "react-icons/md";
import { document, tabularTableIcon } from "../../assets";
import { RiDeleteBin6Line } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { HeaderContainer } from "./viewreport";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import { SearchableDropdown } from "../../components/reportComponents";
import {
  TableComponent,
  TabularTableComponent,
  TabularTablePreview,
} from "./tableComponents";
import { useAppContext } from "../../context/appContext";

const CreateReport = () => {
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [chooseTable, setChooseTable] = useState(false);
  const [docTableName, setDocTableName] = useState(`table 1`);
  const [TabuTableName, setTabuTableName] = useState(`table 1`);
  const [documentTableData, setDocumentTableData] = useState([]);
  const [TabularTableData, setTabularTableData] = useState([]);
  const [documentTable, setDocumentTable] = useState("");
  const [tabularTable, setTabularTable] = useState("");
  const [openDocument, setOpenDocument] = useState(false);
  const [openTabular, setOpenTabular] = useState(false);

  // const { reportTempId } = useParams();
  const [reportTempId, setReportTempId] = useState(null);
  const navigate = useNavigate();
  const [reportName, setReportName] = useState("");
  const [tables, setTables] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  // const [successMessage, setSuccessMessage] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  const [showAddTableModal, setShowAddTableModal] = useState(false);
  const [showModal, setShowModal] = useState(false); // Initially hidden
  const [tableIndex, setTableIndex] = useState("");
  const [columnIndex, setColumnIndex] = useState("");
  const [allTables, setAllTables] = useState([]);
  const [reportTemplates, setReportTemplates] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [companyLocationId, setCompanyLocationId] = useState("");

  // ----------------------------------------------------------------------------------

  const fetchCompanyAndLocation = async () => {
    const additionalDataRef = ref(database, `clientLocations`);
    const snapshot = await get(additionalDataRef);
    if (snapshot.exists()) {
      const additionalData = snapshot.val();
      let clientData = Object.keys(additionalData)
        .map((key) => ({
          id: key,
          ...additionalData[key],
        }))
        .filter((item) => !item.isDeleted && item.clientName);

      let uniqueClientNames = new Set();
      clientData.filter((item) => {
        if (uniqueClientNames.has(item.clientName)) {
          return false;
        } else {
          uniqueClientNames.add(item.clientName);
          return true;
        }
      });
      uniqueClientNames = Array.from(uniqueClientNames);
      const clientLocationsMap = {};

      clientData.forEach((item) => {
        if (!clientLocationsMap[item.clientName]) {
          clientLocationsMap[item.clientName] = [];
        }
        clientLocationsMap[item.clientName].push({
          ...item,
          formattedAddress: `${item.address}, ${item.city}, ${item.state} ${item.zipCode}`,
        });
      });
      const clientDataArray = Object.keys(clientLocationsMap).map(
        (clientName) => ({
          id: clientName,
          company: clientName,
          clientLocations: clientLocationsMap[clientName],
        })
      );
      setUsers(clientDataArray);
    }
  };

  useEffect(() => {
    fetchCompanyAndLocation();
  }, []);

  useEffect(() => {
    const reportTemplatesRef = ref(database, "reportTemplates");

    // Fetch data from Firebase Realtime Database
    get(reportTemplatesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          // Convert the object of objects into an array of objects
          const templatesArray = Object.keys(data)
            .map((key) => ({
              id: key,
              ...data[key],
            }))
            .filter(
              (template) =>
                template.name &&
                template.companyName &&
                template.companyLocation
            );
          setReportTemplates(templatesArray);
        } else {
          setErrorMessage("No report templates found");
        }
      })
      .catch((error) => {
        setErrorMessage(`Error fetching report templates: ${error.message}`);
      });
  }, []); // Empty dependency array ensures that the effect runs only once on component mount

  const handleAddTable = (type) => {
    setTables((prevTables) => [
      ...prevTables,
      {
        id: Date.now(),
        type: type,
        name: "",
        columns: [],
      },
    ]);
    setShowAddTableModal(false);
  };

  const handleDeleteTable = (tableIndex) => {
    const updatedTables = [...tables];
    updatedTables.splice(tableIndex, 1);
    setTables(updatedTables);
  };

  const handleTableNameChange = (tableIndex, value) => {
    const updatedTables = [...tables];
    updatedTables[tableIndex].name = value;
    setTables(updatedTables);
  };

  const handleAddColumn = (tableIndex) => {
    const updatedTables = [...tables];
    const newColumn = {};

    switch (
      updatedTables[tableIndex].type // Assuming table type is stored in `type`
    ) {
      case "Document":
        Object.assign(newColumn, {
          sequence: "",
          title: "Title",
          height: "15",
          width: "10",
          format: "Text",
          border: "No",
        });
        break;
      case "Tabular":
        Object.assign(newColumn, {
          sequence: "",
          title: "Title",
          height: "15",
          width: "10",
          format: "Text",
          border: "No",
          fixed: [],
        });
        break;
      default:
        return; // Do nothing if type is not recognized
    }

    updatedTables[tableIndex].columns.push(newColumn);
    setTables(updatedTables);
  };

  const handleAddfixedColumn = (tableIndex, columnIndex) => {
    const updatedTables = [...tables];
    updatedTables[tableIndex].columns[columnIndex].fixed.push("");
    setTables(updatedTables);
  };

  const handleChange = (tableIndex, columnIndex, fieldName, value) => {
    if (value === undefined) return;

    const updatedTables = [...tables];
    updatedTables[tableIndex].columns[columnIndex][fieldName] = value;
    setTables(updatedTables);

    if (fieldName === "format" && value === "fixed value") {
      setTableIndex(tableIndex);
      setColumnIndex(columnIndex);
      setShowModal(true);
      setAddfixedValues(true);
    }
  };

  const handleChangefix = (tableIndex, columnIndex, value, fixedIndex) => {
    if (value === undefined) return; // Avoid updating with undefined value

    const updatedTables = [...tables];
    updatedTables[tableIndex].columns[columnIndex].fixed[fixedIndex] = value;
    setTables(updatedTables);
  };

  const handleDeleteRow = (tableIndex, columnIndex) => {
    const updatedTables = [...tables];
    updatedTables[tableIndex].columns.splice(columnIndex, 1);
    setTables(updatedTables);
  };

  const handleDeleteFixedRow = (tableIndex, columnIndex, fixedIndex) => {
    const updatedTables = [...tables];
    updatedTables[tableIndex].columns[columnIndex].fixed.splice(fixedIndex, 1);
    setTables(updatedTables);
  };

  useEffect(() => {
    if (reportName || companyName || (tables && tables.length > 0)) {
      setIsChangesInCreateReport(true);
      console.log("setIsChangesInCreateReport", "true");
    } else {
      setIsChangesInCreateReport(false);
      console.log("setIsChangesInCreateReport", "false");
    }
  }, [reportName, companyName, tables]);

  const handleUpdateReportTemplate = (e) => {
    e.preventDefault();

    const IsReportName = reportTemplates.some(
      (report) => report.name === reportName
    );
    const IsCompanyName = reportTemplates.some(
      (report) => report.companyName === companyName
    );
    if (
      reportName !== "" &&
      IsReportName &&
      companyName !== "" &&
      IsCompanyName
    ) {
      alert("Report Name and Company Name already exist");
    } else {
      if (reportName !== "" && companyName !== "") {
        const reportTempRef = push(ref(database, "reportTemplates"));
        const reportTempId = reportTempRef.key;

        const reportTemplateRef = ref(
          database,
          `reportTemplates/${reportTempId}`
        );
        const updatedReportTemplate = {
          status: "Active",
          name: reportName,
          tables: tables,
          companyName: companyName,
          companyLocation: companyLocation,
          companyId,
          companyLocationId,
        };
        // console.log("updatedReportTemplate >>", updatedReportTemplate);
        set(reportTemplateRef, updatedReportTemplate)
          .then(() => {
            toast("Report template updated successfully");
            setReportTempId("");
            setCompanyLocation("");
            setCompanyName("");
            setTables([]);
            setReportName("");
            setIsChangesInCreateReport(false);
          })
          .catch((error) => {
            setErrorMessage(`Error updating report template: ${error.message}`);
          });
      } else {
        alert("Please Fill Report Name && Select Company Name");
      }
    }
  };

  // ----------------------------------------------------------------------------------

  // Document Table
  const CreateDocumentTable = () => {
    setOpenDocument(true);
    setChooseTable(false);
    handleAddTable("Document");
    // setDocumentTable((prev) => ({
    //   ...prev,
    //   tableName: docTableName,
    //   type: "document",
    //   documentTableData,
    // }));
  };
  // Tabular Table
  const CreateTabularTable = () => {
    handleAddTable("Tabular");
    setOpenTabular(true);
    setChooseTable(false);
    setTabularTable((prev) => ({
      ...prev,
      tableName: TabuTableName,
      type: "tabular",
      documentTableData,
    }));
  };

  console.log("documentTable", documentTable);

  useEffect(() => {
    setDocumentTable((prev) => ({ ...prev }));
  }, [documentTableData]);

  const [reportData, setReportData] = useState({
    reportName: "",
    companyName: "",
    companyLocation: "",
  });

  const ReportFormDataHandler = (e) => {
    const { name, value } = e.target;
    setReportData((prevReport) => ({
      ...prevReport,
      [name]: value,
    }));
  };

  // all table and all the data add on this
  const ReportDataHandler = () => {};

  const generateNewID = uuidv4();
  const randomID = generateNewID.slice(0, 8);

  useEffect(() => {
    // const usersRef = ref(database, "users");
    // get(usersRef)
    //   .then((snapshot) => {
    //     if (snapshot.exists()) {
    //       const data = snapshot.val();
    //       console.log(">>>", data);
    //       const templatesArray = Object.keys(data)
    //         .filter((key) => data[key].type === "Client")
    //         .map((key) => ({
    //           id: key,
    //           ...data[key],
    //         }));
    //       setUsers(templatesArray);
    //     } else {
    //       setErrorMessage("No User Profiles found");
    //     }
    //   })
    //   .catch((error) => {
    //     setErrorMessage(`Error fetching User Profiles: ${error.message}`);
    //   });

    // ------------------- REPORT TEMPLATE FETCH FOR CHECK IT EXIST OR NOT WHEN USER CLICK ON SAVE REPORT --------------------------

    const reportTemplatesRef = ref(database, "reportTemplates");

    // Fetch data from Firebase Realtime Database
    get(reportTemplatesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const templatesArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setReportTemplates(templatesArray);
        } else {
          setErrorMessage("No report templates found");
        }
      })
      .catch((error) => {
        setErrorMessage(`Error fetching report templates: ${error.message}`);
      });
  }, []);

  // fixed Value
  const fixedPayload = {
    id: randomID,
    value: "",
  };

  const [fixedValues, setFixedValues] = useState("");
  const [addfixedValues, setAddfixedValues] = useState(false);

  // --------------------------------------------

  // const DeleteHandler = (id) => {
  //   console.log("id", id);
  //   const updated = documentTableData.filter((item) => item.id !== id);
  //   setDocumentTableData(updated);
  // };

  const [openTabularPreview, setopenTabularPreview] = useState(false);
  const [openDocumentPreview, setopenDocumentPreview] = useState(false);

  // const documentInputHandler = (e, id) => {
  //   const { name, value } = e.target;
  //   const updatedDocumentTableData = documentTableData.map((item) =>
  //     item?.id === id ? { ...item, [name]: value } : item
  //   );
  //   setDocumentTableData(updatedDocumentTableData);
  // };
  // const TabularInputHandler = (e, id) => {
  //   const { name, value } = e.target;
  //   const updatedDocumentTableData = TabularTableData.map((item) =>
  //     item?.id === id ? { ...item, [name]: value } : item
  //   );
  //   if (name === "format" && value === "fixed value") {
  //     setAddfixedValues(true);`
  //   }

  //   setTabularTableData(updatedDocumentTableData);
  // };

  // console log
  
  console.log();

  useEffect(() => {
    // console.log("documentTableData", documentTableData);
    console.log("fixedValues", fixedValues);
  }, [documentTableData, TabularTableData, fixedValues]);

  // ---------------------------------
  const [successMessage, setSuccessMessage] = useState("");
  const naviagte = useNavigate();

  console.log("tables", tables);

  const [previewTableIds, setPreviewTableIds] = useState([]);

  const PreviewHandler = (tableId) => {
    const have = previewTableIds.includes(tableId);
    if (have) {
      const filter = previewTableIds.filter((table) => table !== tableId);
      setPreviewTableIds(filter);
    } else {
      setPreviewTableIds([...previewTableIds, tableId]);
    }
    // console.warn(have);
  };
  useEffect(() => {
    if (companyName) {
      const find = users.find((item) => item.company === companyName);
      console.log("find", find);
    }
  }, [companyName]);

  // -----------------------------------------------------------------------------------------------------------
  const [isFileSaved, setIsFileSaved] = useState(false); // Track save state
  const { isChangesInCreateReport, setIsChangesInCreateReport } =
    useAppContext();

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isChangesInCreateReport) {
        e.preventDefault();
        e.returnValue = ""; // Standard browser alert on page unload
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isChangesInCreateReport]);

  return (
    <>
      <ToastContainer />
      <div
        className={`flex flex-col gap-2 h-full   text-sm createReportScroll  ${PixelStyle}`}
      >
        <HeaderContainer pageTitle='create Report'>
          <span className='text-black'>create report</span>
        </HeaderContainer>

        <div className='w-full flex gap-3 '>
          <div className='w-full flex items-center justify-between gap-10 bg-white shadow p-3 rounded-lg'>
            <div className='flex items-center gap-3 w-full'>
              <input
                placeholder='Report Name'
                className='border border-zinc-200 p-2 rounded-md w-full'
                // onChange={ReportFormDataHandler}
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                name='reportName'
              />

              <SearchableDropdown
                users={users}
                selectedCompany={companyName}
                setCompanyId={setCompanyId}
                onSelect={setCompanyName}
                setCompanyLocationId={setCompanyLocationId}
                setCompanyLocation={setCompanyLocation}
                // setCompanyName=set
              />

              {/* <input
                className='border border-zinc-200 p-2 rounded-md w-full'
                type='text'
                id='companyLocationID'
                value={companyLocation}
                onChange={(e) => setCompanyLocation(e.target.value)}
                placeholder='Company Location...'
              /> */}

              <div className='border border-zinc-200 p-2 rounded-md w-full text-nowrap overflow-x-auto min-w-40  scrollHeight'>
                {companyLocation ? (
                  companyLocation
                ) : (
                  <span className='text-zinc-400'>Location </span>
                )}
              </div>

              {/* <select
                className='border border-zinc-200 p-2 rounded-md w-full'
                id='companyLocationID'
                value={companyLocationId}
                onChange={(e) => {
                  setCompanyLocationId(e.target.value);
                  setCompanyLocation(
                    users
                      .find((user) => user.id === companyId)
                      .clientLocations.find(
                        (location) => location.id === e.target.value
                      ).formattedAddress
                  );
                }}
                placeholder='Company Location...'
              >
                {!companyLocationId && (
                  <option value=''>Select a Location</option>
                )}
                {companyId &&
                  users
                    .find((user) => user.id === companyId)
                    .clientLocations.map((location, index) => (
                      <option key={index} value={location.id}>
                        {location.formattedAddress}
                      </option>
                    ))}
              </select> */}
            </div>
            <div>
              <button
                onClick={() => setChooseTable(!chooseTable)}
                className={`border  ${
                  chooseTable
                    ? "border-red-500 text-red-500"
                    : "border-blue-500 text-blue-600"
                } p-2 px-5 rounded-md flex items-center gap-1  font-semibold text-nowrap`}
              >
                <MdOutlineAdd
                  className={`text-xl transition duration-300  ${
                    chooseTable && "rotate-45"
                  } `}
                />
                {chooseTable ? "close table" : "Add table"}
              </button>
            </div>
          </div>
          <div className='bg-white shadow p-3 rounded-lg text-nowrap flex items-center'>
            <button
              className=' p-2 px-5 rounded-md bg-blue-500 text-white '
              onClick={handleUpdateReportTemplate}
            >
              Save Report
            </button>
          </div>
        </div>

        <div className='flex flex-col gap-5 items-center justify-start my-5 pb-20 relative  '>
          {chooseTable && (
            <div className='fixed z-10 top-1/2  transform translate-y-[-50%] '>
              <div className='bg-white pb-10 capitalize text-zinc-600 flex flex-col gap-7 p-6 px-20 rounded-lg shadow '>
                <h1 className='text-xl font-bold border-b pb-2'>
                  Select table type
                </h1>
                <div className='flex gap-5'>
                  <div
                    onClick={CreateDocumentTable}
                    className='hover:shadow-lg hover:border-blue-500 hover:text-blue-500 flex flex-col justify-center items-center gap-4 border p-3 px-8 rounded-xl cursor-pointer'
                  >
                    <img src={document} alt='' className='h-32 p-3' />
                    <p className='font-bold border-t w-full text-center py-2'>
                      Document Table
                    </p>
                  </div>
                  <div
                    onClick={CreateTabularTable}
                    className='hover:shadow-lg hover:border-blue-500 hover:text-blue-500 flex flex-col justify-center items-center gap-4 border p-3 px-8 rounded-xl cursor-pointer'
                  >
                    <img src={tabularTableIcon} alt='' className='h-32' />
                    <p className='font-bold border-t w-full text-center py-2'>
                      Tabular Table
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='w-full mb-10 '>
            {tables.map((table, tableIndex) => (
              <>
                {table.type === "Document" && (
                  <div className={`flex flex-col gap-3 w-full my-5 `}>
                    <div className='bg-white  shadow rounded-md overflow-hidden '>
                      <div className='bg-green-100 w-full text-xs text-green-700 py-0.5 font-semibold px-4 '>
                        Document table
                      </div>
                      <div className='p-4 w-full flex flex-col gap-5'>
                        <div className='flex justify-between'>
                          <div className='flex flex-col items-start'>
                            <input
                              placeholder='Table Name'
                              value={table?.name}
                              // onChange={(e) => setDocTableName(e.target.value)}
                              onChange={(e) =>
                                handleTableNameChange(
                                  tableIndex,
                                  e.target.value
                                )
                              }
                              className='bg-transparent outline-none text-2xl font-bold mb-1 text-zinc-600'
                            />
                            {docTableName === "table 1" && (
                              <span className='border-green-500 border rounded-full px-5 text-xs text-green-500'>
                                Editable
                              </span>
                            )}
                          </div>
                          <div className='flex items-center gap-3 '>
                            <button
                              className={`flex items-center gap-2 border p-1.5 px-4 rounded-md ${
                                previewTableIds.includes(table.id) &&
                                "bg-green-50 border-green-300"
                              } `}
                              onClick={() => PreviewHandler(table.id)}
                            >
                              {previewTableIds.includes(table.id) ? (
                                <LuEye />
                              ) : (
                                <LuEyeOff />
                              )}
                              <span className='text-xs'> Preview</span>
                            </button>

                            <button
                              onClick={() => handleDeleteTable(tableIndex)}
                              className={` text-zinc-600 hover:text-red-400 border border-zinc-200 hover:border-red-200  p-1.5 rounded-lg ${
                                docTableName === "table 1" && "px-4 text-xl"
                              }`}
                            >
                              <RiDeleteBin6Line />
                            </button>
                          </div>
                        </div>
                        <div class='relative overflow-x-auto pb-8 '>
                          <table class='w-full text-sm text-left rtl:text-right '>
                            <thead class='text-xs text-gray-700 uppercase bg-gray-50  '>
                              <tr>
                                <th scope='col' class='px-6 py-3'>
                                  Sequence
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  Title
                                </th>
                                {/* <th scope='col' class='px-6 py-3'>
                          Height (px)
                        </th> */}
                                <th scope='col' class='px-6 py-3'>
                                  Width (%)
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  Specific Format
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  Border
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  <button
                                    // onClick={AddDocumentTableHandler}
                                    onClick={() => handleAddColumn(tableIndex)}
                                    className='p-1 px-2 border-2  border-green-400 text-green-500 rounded-lg flex items-center gap-1'
                                  >
                                    <MdOutlineAdd className='text-lg' />
                                    <span className=''>ADD</span>
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {table.columns.map((column, columnIndex) => (
                                <tr class='bg-white border-b  '>
                                  <th
                                    scope='row'
                                    class='px-5 py-4 font-medium text-black whitespace-nowrap '
                                  >
                                    <Input
                                      placeholder='Sequence'
                                      className='w-20'
                                      value={column?.sequence}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "sequence",
                                          e.target.value
                                        )
                                      }
                                      name='sequence'
                                    />
                                  </th>
                                  <td class='px-5 py-4'>
                                    <Input
                                      placeholder='Title'
                                      value={column.title}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "title",
                                          e.target.value
                                        )
                                      }
                                      name='title'
                                    />
                                  </td>
                                  {/* <td class='px-5 py-4'>
                            <Input
                              placeholder='Height'
                              onChange={(e) =>
                                documentInputHandler(e, column?.id)
                              }
                              name='height'
                            />
                          </td> */}
                                  <td class='px-5 py-4'>
                                    {/* <Input
                                      className='w-20'
                                      placeholder='width'
                                      value={column.width}
                                      onChange={(e) =>
                                        e.target.value <= 100 &&
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "width",
                                          e.target.value
                                        )
                                      }
                                      name='width'
                                    /> */}

                                    <select
                                      className='px-2 border p-2 rounded-md'
                                      value={column.width}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "width",
                                          e.target.value
                                        )
                                      }
                                      name='width'
                                    >
                                      <option>50%</option>
                                      <option>100%</option>
                                    </select>
                                  </td>

                                  <td class='px-5 py-4'>
                                    {/* <Input placeholder='Format ' /> */}
                                    <select
                                      className='px-2 border p-2 rounded-md'
                                      value={column.format}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "format",
                                          e.target.value
                                        )
                                      }
                                      name='format'
                                    >
                                      <option>text</option>
                                      <option>number</option>
                                      <option>date</option>
                                      <option>time</option>
                                      <option>long text</option>
                                      <option>photo upload</option>
                                      <option>photo capture</option>
                                      <option>f/c</option>
                                    </select>
                                  </td>
                                  <td class='px-6 py-4'>
                                    <select
                                      className='px-2 border p-2 rounded-md '
                                      value={column.border}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "border",
                                          e.target.value
                                        )
                                      }
                                      name='border'
                                    >
                                      <option>no</option>
                                      <option>yes</option>
                                    </select>
                                  </td>
                                  <td className=''>
                                    <div className='flex justify-center items-center'>
                                      {/* {item?.id} */}
                                      <RiDeleteBin6Line
                                        className='text-xl text-red-500 cursor-pointer'
                                        onClick={() =>
                                          handleDeleteRow(
                                            tableIndex,
                                            columnIndex
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* document preview section */}
                    {previewTableIds.includes(table.id) && (
                      <div className=' bg-white shadow rounded-md overflow-hidden'>
                        <div className='bg-green-100 w-full text-xs text-green-700 py-0.5 font-semibold px-4 '>
                          preview
                        </div>
                        <div className='p-2'>
                          <div className=''>
                            <p className='font-semibold  px-2 border-b pb-1'>
                              {table?.name}
                            </p>
                            <div className='mt-3 grid grid-cols-1 md:grid-cols-2'>
                              {Array.from({
                                length: Math.max(
                                  ...table.columns.map((item) => item.sequence)
                                ),
                              }).map((_, i) => {
                                const item = table.columns.find(
                                  (column) => Number(column.sequence) === i + 1
                                );

                                return (
                                  <div key={i} className='p-2'>
                                    <div key={i + 1}>
                                      {item ? (
                                        <div
                                          className={`grid grid-cols-2 p-2 items-center`}
                                        >
                                          <>
                                            <span className='font-semibold  bg-green-100 border border-green-100 px-2 p-1'>
                                              {item.title
                                                ? item.title
                                                : "Empty Title"}
                                            </span>
                                            <span
                                              className={`p-1 ${
                                                item.width === "100%"
                                                  ? "col-span-2"
                                                  : "col-span-1"
                                              }`}
                                              style={
                                                item.border === "yes"
                                                  ? { borderWidth: "1px" }
                                                  : {}
                                              }
                                            >
                                              {item.format}
                                            </span>
                                          </>
                                        </div>
                                      ) : (
                                        <div className=' bg-zinc-100 rounded-md py-2 '></div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {table.type === "Tabular" && (
                  <>
                    <TabularTableComponent
                      table={table}
                      tableIndex={tableIndex}
                      handleTableNameChange={handleTableNameChange}
                      handleChange={handleChange}
                      handleAddColumn={handleAddColumn}
                      handleDeleteTable={handleDeleteTable}
                      handleDeleteRow={handleDeleteRow}
                      PreviewHandler={PreviewHandler}
                      previewTableIds={previewTableIds}
                    />
                    {previewTableIds.includes(table.id) && (
                      <TabularTablePreview table={table} />
                    )}
                  </>
                )}
              </>
            ))}
          </div>

          {addfixedValues && (
            <div className='   flex items-center justify-center  absolute  top-0 backdrop-blur-sm z-10 h-full  w-full  '>
              <div className='bg-white rounded-lg p-4 shadow-lg w-1/2'>
                <div className='flex justify-between items-center'>
                  <div className=' text-zinc-600 font-semibold'>
                    Fixed Value
                  </div>
                  <button
                    onClick={() =>
                      handleAddfixedColumn(tableIndex, columnIndex)
                    }
                    className='p-1 px-2 border-2 text-sm  border-green-400 text-green-500 rounded-lg flex items-center gap-1'
                  >
                    <MdOutlineAdd className='text-lg' />
                    <span className=''>ADD</span>
                  </button>
                </div>
                <div>
                  <table class='w-full text-sm   text-gray-500 my-4  '>
                    <thead class='text-xs text-gray-700 uppercase bg-gray-50  '>
                      <tr>
                        <th scope='col' class='px-6 py-3 w-full'>
                          value
                        </th>
                        <th scope='col' class='px-6 py-3'>
                          remove
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tables[tableIndex]?.columns[columnIndex]?.fixed?.map(
                        (fix, fixedIndex) => {
                          return (
                            <tr key={fixedIndex} class='bg-white border-b  '>
                              <td class='px-5 py-4 font-medium text-black whitespace-nowrap '>
                                <Input
                                  placeholder='Titles'
                                  className='w-full'
                                  value={fix} //fix.item
                                  onChange={(e) =>
                                    handleChangefix(
                                      tableIndex,
                                      columnIndex,
                                      e.target.value,
                                      fixedIndex
                                    )
                                  }
                                  name='value'
                                />
                              </td>
                              <td className=''>
                                <div className='flex justify-center items-center'>
                                  {/* {item?.id} */}
                                  <RiDeleteBin6Line
                                    className='text-xl text-red-500 cursor-pointer'
                                    onClick={() =>
                                      handleDeleteFixedRow(
                                        tableIndex,
                                        columnIndex,
                                        fixedIndex
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  {/* {fixedValues ? (
                    fixedValues.length > 0 && ( */}
                  <button
                    onClick={() => setAddfixedValues(false)}
                    className='bg-blue-100 w-full p-2 rounded-md mt-2 border border-blue-300 text-blue-600 font-semibold'
                  >
                    Done
                  </button>
                  {/* ) */}
                  {/* ) : ( */}
                  <button
                    onClick={() => setAddfixedValues(false)}
                    className='bg-red-100 w-full p-2 rounded-md mt-2 border border-red-300 text-red-600 font-semibold'
                  >
                    close
                  </button>
                  {/* )} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* document table */}

      {/* {openDocument && (
            <div className={`flex flex-col gap-3 w-full  `}>
              <div className='bg-white  shadow rounded-md overflow-hidden '>
                <div className='bg-green-100 w-full text-xs text-green-700 py-0.5 font-semibold px-4 capitalize'>
                  Document table
                </div>
                <div className='p-4 w-full flex flex-col gap-5'>
                  <div className='flex justify-between'>
                    <div className='flex flex-col items-start'>
                      <input
                        placeholder='Table Name'
                        value={docTableName}
                        onChange={(e) => setDocTableName(e.target.value)}
                        className='bg-transparent outline-none text-2xl font-bold uppercase text-zinc-600'
                      />
                      {docTableName === "table 1" && (
                        <span className='border-green-500 border rounded-full px-5 text-xs text-green-500'>
                          Editable
                        </span>
                      )}
                    </div>
                    <div className='flex items-center gap-3 '>
                      <button
                        className={`flex items-center gap-2 border p-1.5 px-4 rounded-md ${
                          openDocumentPreview && "bg-green-50 border-green-300"
                        } `}
                        onClick={() =>
                          setopenDocumentPreview(!openDocumentPreview)
                        }
                      >
                        {openDocumentPreview ? <LuEye /> : <LuEyeOff />}
                        <span className='text-xs'> Preview</span>
                      </button>

                      <button
                        onClick={() => handleDeleteTable(tableIndex)}
                        className={` text-zinc-600 hover:text-red-400 border border-zinc-200 hover:border-red-200  p-1.5 rounded-lg ${
                          docTableName === "table 1" && "px-4 text-xl"
                        }`}
                      >
                        <RiDeleteBin6Line />
                      </button>
                    </div>
                  </div>
                  <div class='relative overflow-x-auto pb-8 '>
                    <table class='w-full text-sm text-left rtl:text-right text-gray-500 '>
                      <thead class='text-xs text-gray-700 uppercase bg-gray-50  '>
                        <tr>
                          <th scope='col' class='px-6 py-3'>
                            Sequence
                          </th>
                          <th scope='col' class='px-6 py-3'>
                            Title
                          </th>
                        
                          <th scope='col' class='px-6 py-3'>
                            Width (%)
                          </th>
                          <th scope='col' class='px-6 py-3'>
                            Specific Format
                          </th>
                          <th scope='col' class='px-6 py-3'>
                            Border
                          </th>
                          <th scope='col' class='px-6 py-3'>
                            <button
                              onClick={AddDocumentTableHandler}
                              className='p-1 px-2 border-2  border-green-400 text-green-500 rounded-lg flex items-center gap-1'
                            >
                              <MdOutlineAdd className='text-lg' />
                              <span className=''>ADD</span>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentTableData.map((item, index) => (
                          <tr class='bg-white border-b  '>
                            <th
                              scope='row'
                              class='px-5 py-4 font-medium text-black whitespace-nowrap '
                            >
                              <Input
                                placeholder='Sequence'
                                className='w-20'
                                onChange={(e) =>
                                  documentInputHandler(e, item?.id)
                                }
                                name='sequence'
                              />
                            </th>
                            <td class='px-5 py-4'>
                              <Input
                                placeholder='Title'
                                onChange={(e) =>
                                  documentInputHandler(e, item?.id)
                                }
                                name='title'
                              />
                            </td>
                           
                            <td class='px-5 py-4'>
                            
                              <select
                                className='px-2 border p-2 rounded-md'
                                onChange={(e) =>
                                  documentInputHandler(e, item?.id)
                                }
                                name='width'
                              >
                                <option>50%</option>
                                <option>100%</option>
                              </select>
                            </td>
                            <td class='px-5 py-4'>
                            
                              <select
                                className='px-2 border p-2 rounded-md'
                                onChange={(e) =>
                                  documentInputHandler(e, item?.id)
                                }
                                name='format'
                              >
                                <option>text</option>
                                <option>number</option>
                                <option>date</option>
                                <option>time</option>
                                <option>long text</option>
                                <option>photo upload</option>
                                <option>photo capture</option>
                                <option>f/c</option>
                              </select>
                            </td>
                            <td class='px-6 py-4'>
                              <select
                                className='px-2 border p-2 rounded-md '
                                onChange={(e) =>
                                  documentInputHandler(e, item?.id)
                                }
                                name='border'
                              >
                                <option>no</option>
                                <option>yes</option>
                              </select>
                            </td>
                            <td className=''>
                              <div className='flex justify-center items-center'>
                               
                                <RiDeleteBin6Line
                                  className='text-xl text-red-500 cursor-pointer'
                                  onClick={() => DeleteHandler(item?.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              
              {openDocumentPreview && (
                <div className=' bg-white shadow rounded-md overflow-hidden'>
                  <div className='bg-green-100 w-full text-xs text-green-700 py-0.5 font-semibold px-4 capitalize'>
                    preview
                  </div>
                  <div className='p-2'>
                    <div className=''>
                      <p className='font-semibold capitalize px-2 border-b pb-1'>
                        {docTableName}
                      </p>
                      <div className='mt-3'>
                        {documentTableData.map((item, index) => {
                          return (
                            <div>
                              <div className='grid grid-cols-4 p-2 items-center'>
                                <span className='font-semibold '>
                                  {item?.title ? item?.title : "Empty Title"}
                                </span>
                                <span
                                  className={`p-2  ${
                                    item?.width === "100%"
                                      ? "col-span-4"
                                      : "col-span-3"
                                  } `}
                                  style={
                                    item?.border === "yes"
                                      ? { borderWidth: "1px" }
                                      : {}
                                  }
                                >
                                  {item?.format}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )} */}

      {/* -------------------- */}

      {/* tablular table */}

      {/* {openTabular && (
            <div className={`flex flex-col gap-3 relative w-full`}>
              <div className='bg-white  shadow rounded-md overflow-hidden '>
                <div className='bg-blue-100 w-full text-xs text-blue-700 py-0.5 font-semibold px-4 capitalize'>
                  Tabular table
                </div>
                <div className='p-4 w-full flex flex-col gap-5 relative'>
                  <div className='flex justify-between'>
                    <div className='flex flex-col items-start'>
                      <input
                        placeholder='Table Name'
                        value={TabuTableName}
                        onChange={(e) => setTabuTableName(e.target.value)}
                        className='bg-transparent outline-none text-2xl font-bold uppercase text-zinc-600'
                      />
                      {TabuTableName === "table 1" && (
                        <span className='border-blue-500 border rounded-full px-5 text-xs text-blue-500'>
                          Editable
                        </span>
                      )}
                    </div>
                    <div className='flex items-center gap-3 '>
                      <button
                        className={`flex items-center gap-2 border p-1.5 px-4 rounded-md ${
                          openTabularPreview && "bg-blue-50 border-blue-300"
                        } `}
                        onClick={() =>
                          setopenTabularPreview(!openTabularPreview)
                        }
                      >
                        {openTabularPreview ? <LuEye /> : <LuEyeOff />}
                        <span className='text-xs'> Preview</span>
                      </button>

                      <button
                        onClick={() => handleDeleteTable(tableIndex)}
                        className={` text-zinc-600 hover:text-red-400 border border-zinc-200 hover:border-red-200  p-1.5 rounded-lg ${
                          TabuTableName === "table 1" && "px-4 text-xl"
                        }`}
                      >
                        <RiDeleteBin6Line />
                      </button>
                    </div>
                  </div>
                  <div class='relative overflow-x-auto pb-8 '>
                    <table class='w-full text-sm text-left rtl:text-right text-gray-500 capitalize '>
                      <thead class='text-xs text-gray-700 uppercase bg-gray-50  '>
                        <tr>
                          <th scope='col' class='px-6 py-3'>
                            Sequence
                          </th>
                          <th scope='col' class='px-6 py-3'>
                            Title
                          </th>
                         
                          <th scope='col' class='px-6 py-3'>
                            Width (%)
                          </th>
                          <th scope='col' class='px-6 py-3'>
                            Specific Format
                          </th>
                          <th scope='col' class='px-6 py-3'>
                            Border
                          </th>
                          <th scope='col' class='px-6 py-3'>
                            <button
                              onClick={AddTablularTableHandler}
                              className='p-1 px-2 border-2  border-blue-400 text-blue-500 rounded-lg flex items-center gap-1'
                            >
                              <MdOutlineAdd className='text-lg' />
                              <span className=''>ADD</span>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {TabularTableData.map((item, index) => (
                          <tr class='bg-white border-b  '>
                            <th
                              scope='row'
                              class='px-5 py-4 font-medium text-black whitespace-nowrap '
                            >
                              <Input
                                placeholder='Sequence'
                                className='w-20'
                                onChange={(e) =>
                                  TabularInputHandler(e, item?.id)
                                }
                                name='sequence'
                              />
                            </th>
                            <td class='px-5 py-4'>
                              <Input
                                placeholder='Title'
                                onChange={(e) =>
                                  TabularInputHandler(e, item?.id)
                                }
                                name='title'
                              />
                            </td>
                         
                            <td class='px-5 py-4'>
                              
                              <select
                                className='px-2 border p-2 rounded-md'
                                onChange={(e) =>
                                  TabularInputHandler(e, item?.id)
                                }
                                name='width'
                              >
                                <option>50%</option>
                                <option>100%</option>
                              </select>
                            </td>
                            <td class='px-5 py-4'>
                              
                              <select
                                className='px-2 border p-2 rounded-md capitalize'
                                onChange={(e) =>
                                  TabularInputHandler(e, item?.id)
                                }
                                name='format'
                              >
                                <option>text</option>
                                <option>number</option>
                                <option>date</option>
                                <option>time</option>
                                <option>long text</option>
                                <option>fixed value</option>
                                <option>Yes/No</option>
                                <option>photo upload</option>
                                <option>photo capture</option>
                                <option>f/c</option>
                              </select>
                            </td>
                            <td class='px-6 py-4'>
                              <select
                                className='px-2 border p-2 rounded-md '
                                onChange={(e) =>
                                  TabularInputHandler(e, item?.id)
                                }
                                name='border'
                              >
                                <option>no</option>
                                <option>yes</option>
                              </select>
                            </td>
                            <td className=''>
                              <div className='flex justify-center items-center'>
                              
                                <RiDeleteBin6Line
                                  className='text-xl text-red-500 cursor-pointer'
                                  onClick={() => DeleteHandler(item?.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                     
                    </table>
                  </div>
                </div>
              </div>

             
              {openTabularPreview && (
                <div className=' bg-white shadow rounded-md overflow-hidden'>
                  <div className='bg-blue-100 w-full text-xs text-blue-700 py-0.5 font-semibold px-4 capitalize'>
                    preview
                  </div>
                  <div className='p-2'>
                    <div className=''>
                      <p className='font-semibold capitalize px-2 border-b pb-1'>
                        {TabuTableName}
                      </p>
                      <div className='mt-3'>
                        {TabularTableData.map((item, index) => {
                          return (
                            <div>
                              <div className='grid grid-cols-4 p-2 items-center'>
                                <span className='font-semibold '>
                                  {item?.title ? item?.title : "Empty Title"}
                                </span>
                                <span
                                  className={`p-2  ${
                                    item?.width === "100%"
                                      ? "col-span-4"
                                      : "col-span-3"
                                  } `}
                                  style={
                                    item?.border === "yes"
                                      ? { borderWidth: "1px" }
                                      : {}
                                  }
                                >
                                  {item?.format}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )} */}

      {/* -------------------- */}
    </>
  );
};

export default CreateReport;

const Input = ({ placeholder, className, ...props }) => {
  return (
    <input
      placeholder={placeholder}
      className={`border border-zinc-200 outline-blue-500 p-2 rounded-md ${className}`}
      {...props}
    />
  );
};
