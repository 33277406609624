import React, { useEffect, useState } from "react";
import Layout, { PixelStyle } from "../../components/layout";
import { database } from "../../config/config";
import { ref, get, push, set, remove, update } from "firebase/database";
import TableLayout, { Td, Tr } from "../../components/tableLayout";
import Loader from "../../components/loader";
import { BsCopy } from "react-icons/bs";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { GoDownload } from "react-icons/go";
import ExportToExcel from "../../components/exportToExcel";
import { ToastContainer } from "react-toastify";
import PopUp, { Toast } from "../../components/popUp";
import { useAppContext } from "../../context/appContext";

const ViewReport = () => {
  const { UserType } = useAppContext();

  const [reportTemplates, setReportTemplates] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
    company: "",
    location: "",
    status:"",
  });
  const [sortedReports, setSortedReports] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null); // "asc" or "desc"

  const TableHead = [
    "Report Name",
    "Company Name",
    "Location",
    "Status",
    "Action",
  ];
  const [reportUserAccessPermissions, setReportUserAccessPermissions] =
    useState([]);

  useEffect(() => {
    const reportTemplatesRef = ref(database, "reportTemplates");
    const reportUserAccessPermissionsRef = ref(
      database,
      "reportUserAccessPermissions"
    );
    // Fetch data from Firebase Realtime Database
    get(reportTemplatesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const templatesArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setReportTemplates(templatesArray);
        } else {
          setErrorMessage("No report templates found");
        }
      })
      .catch((error) => {
        setErrorMessage(`Error fetching report templates: ${error.message}`);
      });

    // Fetch report user access permissions
    get(reportUserAccessPermissionsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const permissionsArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setReportUserAccessPermissions(permissionsArray);
        } else {
          setErrorMessage("No report user access permissions found");
        }
      })
      .catch((error) => {
        setErrorMessage(
          `Error fetching report user access permissions: ${error.message}`
        );
      });
  }, []);

  useEffect(() => {
    const filteredReports = reportTemplates.filter((item) => {
      console.log("filters", item.status.toLowerCase(),filters.status.toLowerCase(), item.status.toLowerCase() === filters.status.toLowerCase())
      return (
        (filters.name === "" ||
          item.name.toLowerCase().includes(filters.name.toLowerCase())) &&
        (filters.company === "" ||
          item.companyName
            .toLowerCase()
            .includes(filters.company.toLowerCase())) &&
        (filters.location === "" ||
          item.companyLocation
            .toLowerCase()
            .includes(filters.location.toLowerCase())) && 
        (filters.status === "" ||
         item.status.toLowerCase().startsWith(filters.status.toLowerCase()))
      );
    });

    if (sortField) {
      const sorted = [...filteredReports].sort((a, b) => {
        const aValue = a[sortField]?.toLowerCase() || "";
        const bValue = b[sortField]?.toLowerCase() || "";

        if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
        if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });

      setSortedReports(sorted);
    } else {
      setSortedReports(filteredReports);
    }
  }, [filters, reportTemplates, sortField, sortOrder]);

  const headerFieldMapping = {
    "Report Name": "name",
    "Company Name": "companyName",
    Location: "companyLocation",
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSort = (field) => {
    if (sortField === field) {
      if (sortOrder === "asc") {
        setSortOrder("desc");
      } else if (sortOrder === "desc") {
        setSortOrder(null); // Reset to original order on third click
        setSortField(null);
      } else {
        setSortOrder("asc");
      }
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const toggleSortOrder = (header) => {
    const field = headerFieldMapping[header];
    if (field) {
      handleSort(field);
    }
  };

  // const handleDelete = (templateId) => {
  //   const isConfirmed = window.confirm(
  //     "Are you sure you want to delete this report template?"
  //   );
  //   if (!isConfirmed) return;

  //   const templateRef = ref(database, `reportTemplates/${templateId}`);
  //   remove(templateRef)
  //     .then(() => {
  //       setReportTemplates(
  //         reportTemplates.filter((template) => template.id !== templateId)
  //       );
  //     })
  //     .catch((error) => {
  //       setErrorMessage(`Error deleting report template: ${error.message}`);
  //     });
  // };

  const handleDelete = async (templateId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this report template?"
    );
    if (!isConfirmed) {
      return; // If user cancels, do nothing
    }

    console.log("templateId", templateId);

    const templateRef = ref(database, `reportTemplates/${templateId}`);
    const reportUserAccessPermissionsRef = ref(
      database,
      "reportUserAccessPermissions"
    );
    const generatedReportRef = ref(database, "generatedReport");

    try {
      // Remove the report template from Firebase Realtime Database
      await remove(templateRef);

      // Query and remove documents from reportUserAccessPermissions
      const reportUserAccessPermissionsSnapshot = await get(
        reportUserAccessPermissionsRef
      );
      if (reportUserAccessPermissionsSnapshot.exists()) {
        const reportUserAccessPermissionsData =
          reportUserAccessPermissionsSnapshot.val();
        for (const key in reportUserAccessPermissionsData) {
          if (
            reportUserAccessPermissionsData[key].reportTemplateId === templateId
          ) {
            await remove(ref(database, `reportUserAccessPermissions/${key}`));
          }
        }
      }

      // Query and remove documents from generatedReport
      const generatedReportSnapshot = await get(generatedReportRef);
      if (generatedReportSnapshot.exists()) {
        const generatedReportData = generatedReportSnapshot.val();
        for (const key in generatedReportData) {
          if (generatedReportData[key].reportTemplatesId === templateId) {
            await remove(ref(database, `generatedReport/${key}`));
          }
        }
      }

      // Filter out the deleted template from the state
      setReportTemplates(
        reportTemplates.filter((template) => template.id !== templateId)
      );
    } catch (error) {
      setErrorMessage(`Error deleting report template: ${error.message}`);
    }

    /*remove(templateRef)
      .then(() => {
        // Filter out the deleted template from the state
        setReportTemplates(reportTemplates.filter(template => template.id !== templateId));
      })
      .catch((error) => {
        setErrorMessage(`Error deleting report template: ${error.message}`);
      });*/
  };

  const handleReplicate = async (templateId) => {
    const reportTempRef = push(ref(database, "reportTemplates"));
    const newReportTempId = reportTempRef.key;
    const templateRef = ref(database, `reportTemplates/${templateId}`);

    try {
      const snapshot = await get(templateRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const newName = data.name + " copy";
        data.name = newName;

        const newTemplateRef = ref(
          database,
          `reportTemplates/${newReportTempId}`
        );
        await set(newTemplateRef, data);
        setReportTemplates([
          ...reportTemplates,
          { id: newReportTempId, ...data },
        ]);
      } else {
        alert("Record does not exist!");
      }
    } catch (error) {
      alert("Error copying record:", error.message);
    }
  };

  const ExportPayload = (data) => {
    const payload = Array.isArray(data) ? data : [data];
    const headerMapping = {
      companyLocation: "Company Location",
      companyName: "Company Name",
      companyId: "Company ID",
      companyLocationId: "Company Location ID",
    };

    const cleanedLeads = payload.map(
      ({ id, companyId, companyLocationId, tables, ...rest }) => {
        const renamedObject = {};

        Object.keys(rest).forEach((key) => {
          const newKey = headerMapping[key] || key;
          renamedObject[newKey] = rest[key];
        });

        return renamedObject;
      }
    );

    return cleanedLeads;
  };

  console.log(sortedReports);

  const { setShowPopUP, setPopUpData } = useAppContext();

  const handleToast = (message) => {
    setPopUpData(message);
    setShowPopUP(true);
  };

  const [updateStatus, setUpdateStatus] = useState(null);

  const handleUpdateReportTemplate = (e, id) => {
    const reportTemplateRef = ref(database, `reportTemplates/${id}`);
    const updatedReportTemplate = {
      status: e.target.value,
    };
    setUpdateStatus({ [id]: e.target.value });

    console.log("updatedReportTemplate", updatedReportTemplate);

    update(reportTemplateRef, updatedReportTemplate)
      .then(() => {
        // setSuccessMessage("Report template updated successfully");
        // navigate("/viewreport");
        Toast("Status is Updated");
      })
      .catch((error) => {
        setErrorMessage(`Error updating report template: ${error.message}`);
      });
    // }
  };

  // console.log("filters", filters);

  const [status, setStatus] = useState({});

  return (
    <>
      <PopUp />
      <ToastContainer />
      <div className={`w-full text-sm h-full ${PixelStyle} `}>
        <HeaderContainer pageTitle='View Report'>
          <span className='text-black'>View report</span>
        </HeaderContainer>

        <div className='border bg-white rounded '>
          <div className='flex justify-between items-center w-full px-5 py-2'>
            <h4 className='text-2xl font-semibold'>Report Templates</h4>
            <button
              className='text-zinc-500 p-2 rounded flex items-center gap-2'
              onClick={() => ExportToExcel(ExportPayload(reportTemplates))}
            >
              <GoDownload className='text-2xl' />
            </button>
          </div>
          <TableLayout
            TableHead={TableHead}
            sortField={sortField}
            sortOrder={sortOrder}
            toggleSortOrder={toggleSortOrder}
            headerFieldMapping={headerFieldMapping}
          >
            <>
              <tr className='border-b bg-zinc-50 '>
                {["name", "company", "location"].map((header, index) => (
                  <th scope='col' className='px-6 py-1' key={index}>
                    <input
                      placeholder={`Filter by ${
                        header === "name"
                          ? "Report Name"
                          : header === "company"
                          ? "Company Name"
                          : header === "location" ? "Location" : header
                      }`}
                      name={header}
                      value={filters[header]}
                      onChange={handleFilterChange}
                      className='border p-1 px-2 rounded-md font-normal outline-blue-500'
                    />
                  </th>
                ))}
                <th className='px-6 py-1' >

                  <input
                  name="status" onChange={handleFilterChange}
                  className='border p-1 px-2 rounded-md font-normal outline-blue-500'
                  placeholder="Filter by Status"
                  value={filters?.status}
                  />
                  {/* <select name="status" onChange={handleFilterChange}  className='border p-1 px-2 rounded-md font-normal outline-blue-500' >
                    <option value="">Filter by Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select> */}
                </th>
                <th></th>
              </tr>

              {sortedReports.length > 0 ? (
                sortedReports.map((item, index) => {
                  const isPermissionToEdit = reportUserAccessPermissions.some(
                    (report) => report.reportTemplateId === item.id
                  );

                  const value =
                    updateStatus && item?.id === Object.keys(updateStatus)?.[0]
                      ? updateStatus[item?.id]
                      : null;

                  // Use status from state or fallback to item status
                  const currentStatus = status[item?.id] || item?.status;
                  return (
                    <Tr key={index}>
                      <Td>{item?.name}</Td>
                      <Td>{item?.companyName}</Td>
                      <Td>{item?.companyLocation}</Td>
                      <Td>
                        <select
                          value={currentStatus}
                          onChange={(e) => {
                            const selectedStatus = e.target.value;
                            setStatus((prev) => ({
                              ...prev,
                              [item.id]: selectedStatus,
                            }));
                            handleUpdateReportTemplate(e, item?.id);
                          }}
                          className={`font-medium bg-zinc-50 p-2 px-4 rounded-md ${
                            currentStatus === "Active"
                              ? "text-green-700"
                              : "text-red-700"
                          }`}
                        >
                          {currentStatus !== "Active" &&
                            currentStatus !== "Inactive" && (
                              <option>Select the status</option>
                            )}
                          <option value='Active' className='text-green-700'>
                            Active
                          </option>
                          <option value='Inactive' className='text-red-700'>
                            Inactive
                          </option>
                        </select>
                      </Td>
                      <td className=''>
                        <div className='text-lg flex gap-1'>
                          <button
                            className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'
                            onClick={() => handleReplicate(item?.id)}
                          >
                            <BsCopy />
                          </button>
                          {/* <button className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'>
                            <MdOutlineRemoveRedEye />
                          </button> */}

                          <NavLink
                            to={
                              isPermissionToEdit !== true
                                ? `/viewreport/${item.id}`
                                : "/viewreport"
                            }
                          >
                            <button
                              className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'
                              onClick={() => {
                                if (isPermissionToEdit) {
                                  handleToast(
                                    "Report has been assigned and cannot be edited"
                                  );
                                }
                              }}
                            >
                              <FiEdit3 />
                            </button>
                          </NavLink>

                          <button
                            className='hover:text-red-500 hover:bg-red-100 p-1.5 rounded-lg'
                            onClick={() => handleDelete(item?.id)}
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </td>
                    </Tr>
                  );
                })
              ) : (
                <div className='flex justify-center items-center w-full h-1/2 '>
                  <Loader />
                </div>
              )}
            </>
          </TableLayout>
        </div>
        <div className='py-32'></div>
      </div>
    </>
  );
};

export default ViewReport;

export const PageTitle = ({ title, container }) => {
  return (
    <div className='flex justify-between'>
      <div className='text-3xl font-semibold capitalize'>{title}</div>
      <div className=''>{container}</div>
    </div>
  );
};

export const Redirecting = ({ children }) => (
  <div className='font-semibold  text-zinc-500  flex gap-2'>
    <NavLink to='/home'>Home</NavLink>
    <span>/</span>
    {/* <span>View report</span> */}
    {children}
  </div>
);

export const HeaderContainer = ({ children, pageTitle, container }) => (
  <div className='flex flex-col gap-2 my-7 capitalize '>
    <Redirecting>{children}</Redirecting>

    <PageTitle title={pageTitle} container={container} />
  </div>
);
