import React, { useEffect, useRef, useState } from "react";
import Layout, { PixelStyle } from "../../components/layout";
import { get, push, ref, set, update, query, equalTo } from "firebase/database";
import { HeaderContainer } from "./viewreport";
import TableLayout, { Td, Tr } from "../../components/tableLayout";
import { GoDownload } from "react-icons/go";
import { IoAdd, IoClose } from "react-icons/io5";
import { database } from "../../config/config";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdDone, MdOutlineClose } from "react-icons/md";
import { GetCountries, GetState } from "react-country-state-city";

import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Modal } from "react-bootstrap";
import ExportToExcel from "../../components/exportToExcel";
// import MapComponent from "../Map";

const TableHead = [
  "Client Name",
  "Industry",
  "Street Address",
  "City",
  "State",
  "Zip Code",
  "Action",
];

const clientLocations = "clientLocations";
const clientLocationsRef = ref(database, clientLocations);
const filter = createFilterOptions();

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [uniqueClients, setUniqueClients] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [marker, setMarker] = useState(null);
  const [selectedClientIndex, setSelectedClientIndex] = useState(-1);

  useEffect(() => {
    getClientLocations();

    GetCountries().then((result) => {
      const usa = result.find((country) => country.iso2 === "US");
      GetState(usa.id).then((result) => {
        setStateList(result);
      });
    });
  }, []);

  const getClientLocations = async () => {
    get(clientLocationsRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let templatesArray = Object.keys(data)
          .map((key) => ({
            id: key,
            ...data[key],
          }))
          .filter((item) => !item.isDeleted && item.clientName)
          .map((value) => {
            return {
              ...value,
              clientType: "view",
              lng: value.lang,
            };
          });
        setClients(templatesArray);

        let uniqueClientNames = new Set();
        templatesArray.filter((item) => {
          if (uniqueClientNames.has(item.clientName)) {
            return false;
          } else {
            uniqueClientNames.add(item.clientName);
            return true;
          }
        });
        uniqueClientNames = Array.from(uniqueClientNames).map((item) => {
          return {
            title: item,
          };
        });
        setUniqueClients(uniqueClientNames);
      }
    });
  };

  const _addClient = () => {
    const tempClients = [...clients];
    tempClients.push({
      id: new Date().getTime(),
      clientType: "new",
      clientName: "",
      address: "",
      city: "",
      state: "",
      industry:"",
      zipCode: "",
    });
    setClients(tempClients);
  };



  const isInEditMode = (user) =>
    user.clientType === "new" || user.clientType === "update";


  const CustomTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      padding: 0,
      margin: 0,
      "& input": {
        padding: "5px 10px ",
      },
      "& fieldset": {
        border: "0.5px solid  rgb(87 83 78)",
        padding: "0px",
      },
    },
    // width: 150,
    width: "100%",
    borderRadius: "5px",
    padding: "2px",
  });

  const _createOrUpdateLocation = async (clientLocation, index) => {
    const requiredFields = [
      "clientName",
      "address",
      "city",
      "state",
      "zipCode",
    ];
    const errors = [];

    // Validate each required field
    requiredFields.forEach((field) => {
      if (!clientLocation[field]) {
        errors.push(`${field} is required`);
      }
    });

    // If there are validation errors, set the error message and return early
    if (errors.length > 0) {
      setErrorMessage(errors.join(", "));
      return;
    }

    // console.log("industry",industry)
    setErrorMessage("");
    const clientType = clientLocation.clientType;
    const location = {
      address: clientLocation.address,
      city: clientLocation.city,
      state: clientLocation.state,
      zipCode: clientLocation.zipCode,
      clientName: clientLocation.clientName,
      industry: clientLocation.industry || null,
    };
    
    if (clientType === "new") {
      console.log("new client location =", location);
      const key = push(clientLocationsRef).key;
      await set(ref(database, `${clientLocations}/${key}`), location);
      await getClientLocations();
    } else if (clientType === "update") {
      console.log("update client location ", location);
      await update(
        ref(database, `${clientLocations}/${clientLocation.id}`),
        location
      );
      await getClientLocations();
    } else if (clientType === "view") {
      console.log("show save button here");
      const allLocations = [...clients];
      clientLocation.clientType = "update";
      allLocations[index] = clientLocation;
      setClients(allLocations);
    }
  };

  const _deleteClientLocation = async (client, index) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this client?"
    );
    if (!isConfirmed) {
      return; // If user cancels, do nothing
    }
    const storedClient = await get(
      ref(database, `${clientLocations}/${client.id}`)
    );

    const requiredFields = [
      "clientName",
      "address",
      "city",
      "state",
      "zipCode",
    ];
    const errors = [];

    // Validate each required field
    requiredFields.forEach((field) => {
      if (!client[field]) {
        errors.push(`${field} is required`);
      }
    });

    if (!storedClient.val() || errors.length > 0) {
      const tempClients = [...clients];
      tempClients.splice(index, 1);
      setClients(tempClients);
      return;
    }

    delete client.lat;
    delete client.lng;
    await set(ref(database, `${clientLocations}/${client.id}`), {
      ...client,
      isDeleted: true,
    });

    const tempClients = [...clients];
    tempClients.splice(index, 1);
    setClients(tempClients);
  };

  // ------------------------------ FILTERS --------------------------------------------
  // --------------------------------- SHORTING ---------------------------------------

  const [sortedReports, setSortedReports] = useState([]);

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null); // "asc" or "desc"

  const headerFieldMapping = {
    "Client Name": "clientName",
    "Street Address": "address",
    City: "city",
    State: "state",
    Industry:"industry",
    "Zip Code": "zipCode",
  };
  const [filters, setFilters] = useState({
    clientName: "",
    address: "",
    city: "",
    state: "",
    industry:"",
    zipCode: "",
  });

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };
  const toggleSortOrder = (header) => {
    const field = headerFieldMapping[header];
    if (field) {
      handleSort(field);
    }
  };
  const handleSort = (field) => {
    if (sortField === field) {
      if (sortOrder === "asc") {
        setSortOrder("desc");
      } else if (sortOrder === "desc") {
        setSortOrder(null); // Reset to original order on third click
        setSortField(null);
      } else {
        setSortOrder("asc");
      }
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };
  useEffect(() => {
    const filteredReports = clients.filter((client) => {
      return (
        (filters.clientName === "" ||
          (client.clientName &&
            client.clientName
              .toLowerCase()
              .includes(filters.clientName.toLowerCase()))) &&
        (filters.address === "" ||
          (client.address &&
            client.address
              .toLowerCase()
              .includes(filters.address.toLowerCase()))) &&
        (filters.city === "" ||
          (client.city &&
            client.city.toLowerCase().includes(filters.city.toLowerCase()))) &&
        (filters.state === "" ||
          (client.state &&
            client.state.toLowerCase().includes(filters.state.toLowerCase()))) &&
        (filters.industry === "" ||
          (client.industry &&
            client.industry
              .toLowerCase()
              .includes(filters.industry.toLowerCase()))) &&
        (filters.zipCode === "" ||
          (client.zipCode &&
            client.zipCode.toLowerCase().includes(filters.zipCode.toLowerCase())))
      );
    });
    // commit

    if (sortField) {
      const sorted = [...filteredReports].sort((a, b) => {
        const aValue = a[sortField]?.toLowerCase() || "";
        const bValue = b[sortField]?.toLowerCase() || "";

        if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
        if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });

      setSortedReports(sorted);
    } else {
      setSortedReports(filteredReports);
    }
  }, [filters, sortField, clients, sortOrder]);

  console.log("isInEditMode", isInEditMode);
  console.log(clients);

  //   export to excel

  const ExportPayload = (data) => {
    // Ensure data is always an array
    const payload = Array.isArray(data) ? data : [data];

    // Define the header mapping for renaming keys
    const headerMapping = {
      clientName: "Client Name",
      address: "Address",
      city: "City",
      state: "State",
      industry: "Industry",
      zipCode: "Zip Code",
      // Add other key mappings as necessary
    };

    // Define the desired sequence of keys
    const orderedKeys = ["clientName", "address", "city", "state", "zipCode"];

    // Map through each object in the payload
    const cleanedLeads = payload.map(({ id, createdAt, ...rest }) => {
      const renamedObject = {};

      // Add the keys in the defined order
      orderedKeys.forEach((key) => {
        const newKey = headerMapping[key] || key;
        if (rest[key] !== undefined) {
          renamedObject[newKey] = rest[key]; // Add the value to the renamed object
        }
      });

      return renamedObject;
    });

    return cleanedLeads;
  };

  return (
    <>
      <div className={`w-full text-sm h-full ${PixelStyle} `}>
        <HeaderContainer pageTitle='client list'>
          <span className='text-black'>client list</span>
        </HeaderContainer>

        <div className='border bg-white rounded'>
          <div className='flex justify-between items-center w-full px-5 py-2'>
            <h4 className='text-2xl font-semibold'>List</h4>

            <div className='flex items-center gap-4 '>
              <button
                className='text-zinc-500 p-2 rounded flex items-center gap-2 hover:bg-zinc-100 border'
                onClick={() => ExportToExcel(ExportPayload(clients))}
              >
                <GoDownload className='text-xl' />
              </button>
              <button
                onClick={_addClient}
                className='hover:bg-blue-100 text-blue-600 hover:font-medium border border-blue-600 p-1 px-3  rounded-md flex justify-center items-center gap-1'
              >
                <IoAdd className='text-xl' />
                <span>Add</span>
              </button>
            </div>
          </div>

          <TableLayout
            TableHead={TableHead}
            sortField={sortField}
            sortOrder={sortOrder}
            toggleSortOrder={toggleSortOrder}
            headerFieldMapping={headerFieldMapping}
          >
            <>
              <tr className='border-b bg-zinc-50 '>
                {[
                  "Client Name",
                  "Industry",
                  "Street Address",
                  "City",
                  "State",
                  "Zip Code",
                ].map((header, index) => (
                  <th scope='col' className='px-6 py-1' key={index}>
                    <input
                      placeholder={`Filter by ${header}`}
                      name={headerFieldMapping[header]} // map header to the client field
                      value={filters[headerFieldMapping[header]]}
                      onChange={handleFilterChange}
                      className='border p-1 px-2 rounded-md font-normal outline-blue-500'
                    />
                  </th>
                ))}
                <th></th>
              </tr>

              {sortedReports.length > 0
                ? sortedReports.map((user, index) => (
                    <Tr
                      key={user.id}
                      className={isInEditMode(user) && "bg-zinc-300"}
                    >
                      <Td>
                        {!isInEditMode(user) ? (
                          user.clientName
                        ) : (
                          <Autocomplete
                            className={"w-100"}
                            value={user.clientName}
                            onChange={(event, newValue) => {
                              const tempClients = [...clients];
                              const client = tempClients[index];
                              if (typeof newValue === "string") {
                                client.clientName = newValue;
                              } else if (newValue && newValue.inputValue) {
                                client.clientName = newValue.inputValue;
                              } else {
                                client.clientName = newValue.title;
                              }
                              setClients(tempClients);
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;
                              // Suggest the creation of a new value
                              const isExisting = options.some(
                                (option) => inputValue === option.title
                              );
                              if (inputValue !== "" && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  title: `Add "${inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id='free-solo-with-text-demo'
                            options={uniqueClients}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              return option.title;
                            }}
                            renderOption={(props, option) => {
                              const { key, ...optionProps } = props;
                              return (
                                <li key={key} {...optionProps}>
                                  {option.title}
                                </li>
                              );
                            }}
                            freeSolo
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                defaultValue={user.clientName}
                              />
                            )}
                          />
                        )}
                      </Td>
                      <Td>{!isInEditMode(user) ? (
                          user.industry
                        ) : (
                          <CustomTextField
                            defaultValue={user.industry}
                            onChange={(event) =>
                              (user.industry = event.target.value)
                            }
                          />
                        )}
                      </Td>
                      <Td>
                        {!isInEditMode(user) ? (
                          user.address
                        ) : (
                          <CustomTextField
                            defaultValue={user.address}
                            onChange={(event) =>
                              (user.address = event.target.value)
                            }
                          />
                        )}
                      </Td>
                      <Td>
                        {!isInEditMode(user) ? (
                          user.city
                        ) : (
                          <CustomTextField
                            defaultValue={user.city}
                            onChange={(event) =>
                              (user.city = event.target.value)
                            }
                          />
                        )}
                      </Td>
                      <Td>
                        {!isInEditMode(user) ? (
                          user.state
                        ) : (
                          <Select
                            className='border border-[#0000FF] rounded'
                            value={user.state}
                            style={{ width: 150 }}
                            onChange={(event) => {
                              const tempClients = [...clients];
                              const client = tempClients[index];
                              client.state = event.target.value;
                              setClients(tempClients);
                            }}
                            disableUnderline={true}
                            variant='standard'
                          >
                            {stateList.map((item, index) => (
                              <MenuItem key={index} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Td>
                      
                      <Td>
                        {!isInEditMode(user) ? (
                          user.zipCode
                        ) : (
                          <CustomTextField
                            defaultValue={user.zipCode}
                            onChange={(event) =>
                              (user.zipCode = event.target.value)
                            }
                          />
                        )}
                      </Td>
                      {/*<Td >
                                      <button
                                          disabled={!isInEditMode(user)}
                                          className="btn-danger rounded-pill"
                                          onClick={() => {
                                              setSelectedClientIndex(index)
                                              setOpenMap(true)
                                          }}>
                                          Set
                                      </button>
                                      {(user.lat && user.lng) && `${Number(user.lat).toFixed(2)},${Number(user.lng).toFixed(2)}`}
                                  </Td>*/}
                      <Td className='text-center flex gap-3 '>
                        <button
                          className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg text-lg'
                          onClick={() => {
                            _createOrUpdateLocation(user, index);
                          }}
                        >
                          {isInEditMode(user) ? <MdDone /> : <FiEdit3 />}
                        </button>

                        {/* <button
                          className='btn-danger rounded-pill'
                          onClick={() => _createOrUpdateLocation(user, index)}
                        >
                          {isInEditMode(user) ? "Save" : "Edit"}
                        </button> */}
{/* {isInEditMode(user) ? <button onClick={}><IoClose /></button>
: */}

                        <button
                          className='hover:text-red-500 hover:bg-red-100 p-1.5 rounded-lg text-lg'
                          onClick={() => _deleteClientLocation(user, index)}
                        >
                          <RiDeleteBin6Line />
                        </button>
                      </Td>
                    </Tr>
                  ))
                : "Loading Client List..."}
            </>
          </TableLayout>
        </div>
        <div className='py-20'></div>
      </div>
    </>
  );
};

export default ClientList;
