import React, { useEffect, useMemo, useState } from "react";
import Layout, { PixelStyle } from "../../components/layout";
import { ref, get, push, set, remove } from "firebase/database";
import { MdDone, MdOutlineAdd, MdOutlineClose } from "react-icons/md";
import { database } from "../../config/config";
import TableLayout, { Td, Tr } from "../../components/tableLayout";
import Loader from "../../components/loader";
import { RiDeleteBin6Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { FiEdit3 } from "react-icons/fi";
import { HeaderContainer } from "./viewreport";
import { GoDownload } from "react-icons/go";
import ExportToExcel from "../../components/exportToExcel";
import PopUp from "../../components/popUp";
import { useAppContext } from "../../context/appContext";

const TableHead = [
  // "S.N.",
  "User Name & Address",
  "User Role",
  "Report Template",
  "Company Name & Address",
  "access",
  "accessType",
  "Action",
];

const Assignreport = () => {
  const { ShowPopUp, setShowPopUP } = useAppContext();
  const [users, setUsers] = useState([]);
  const [reportTemplates, setReportTemplates] = useState([]);
  const [accessType, setAccessType] = useState("");
  const [reportUserAccessPermissions, setReportUserAccessPermissions] =
    useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [userId, setUserId] = useState(""); // State variable for selected userId
  const [reportTemplateId, setReportTemplateId] = useState(""); // State variable for selected reportTemplateId
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const usersRef = ref(database, "users");
    const reportTemplatesRef = ref(database, "reportTemplates");
    const reportUserAccessPermissionsRef = ref(
      database,
      "reportUserAccessPermissions"
    );

    const sortList = (key) => {
      let arrayCopy = [...this.state.users];
      arrayCopy.sort(this.compareBy(key));
      this.setState({ users: arrayCopy });
    };

    // Fetch users
    get(usersRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const usersArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          // console.log("assign report ", data);
          setUsers(usersArray);
          sortList(users.firstName);
        } else {
          setErrorMessage("No User Profiles found");
        }
      })
      .catch((error) => {
        setErrorMessage(`Error fetching User Profiles: ${error.message}`);
      });

    // Fetch report templates
    get(reportTemplatesRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const templatesArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setReportTemplates(templatesArray);
          // console.log(templatesArray);
        } else {
          setErrorMessage("No report templates found");
        }
      })
      .catch((error) => {
        setErrorMessage(`Error fetching report templates: ${error.message}`);
      });

    // Fetch report user access permissions
    get(reportUserAccessPermissionsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const permissionsArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setReportUserAccessPermissions(permissionsArray);
        } else {
          setErrorMessage("No report user access permissions found");
        }
      })
      .catch((error) => {
        setErrorMessage(
          `Error fetching report user access permissions: ${error.message}`
        );
      });
  }, []);

  const addPermission = () => {
    // Validate selections
    if (!userId || !reportTemplateId || !accessType) {
      alert("Please select User, Report Template, and Access Type.");
      return;
    }

    // Find selected user and report template
    const selectedUser = users.find((user) => user.id === userId);
    const selectedTemplate = reportTemplates.find(
      (template) => template.id === reportTemplateId
    );

    // Check if selected user and template exist
    if (!selectedUser || !selectedTemplate) {
      alert("Selected User or Report Template does not exist.");
      return;
    }

    // Create new permission object
    const newPermission = {
      email: selectedUser.email, // Replace with appropriate property from your user object
      userId: userId,
      reportTemplateName: selectedTemplate.name, // Replace with appropriate property from your template object
      reportTemplateId: reportTemplateId,
      companyLocationId: selectedTemplate.companyLocationId,
      accessType: accessType,
      userType: selectedUser.type,
    };

    //  if the Report Template is alredy exist then this return and no forword...
    console.log(
      "reportUserAccessPermissions",
      reportUserAccessPermissions.some(
        (user) =>
          user.userId === newPermission.userId &&
          user.reportTemplateId === newPermission.reportTemplateId
      )
    );
    if (
      reportUserAccessPermissions.some(
        (user) =>
          user.userId === newPermission.userId &&
          user.reportTemplateId === newPermission.reportTemplateId
      )
    ) {
      return setShowPopUP(true);
    }

    console.log("newPermission is Added: ", newPermission);

    setReportUserAccessPermissions([
      ...reportUserAccessPermissions,
      newPermission,
    ]);

    const newPermissionRef = push(ref(database, "reportUserAccessPermissions"));
    set(newPermissionRef, newPermission);

    setAccessType("");
  };

  const handleEdit = (permmissionId) => {};

  const handleDelete = (templateId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove user access?"
    );
    if (!isConfirmed) {
      return; // If user cancels, do nothing
    }
    const templateRef = ref(
      database,
      `reportUserAccessPermissions/${templateId}`
    );
    // Remove the report template from Firebase Realtime Database
    remove(templateRef)
      .then(() => {
        // Filter out the deleted template from the state
        setReportUserAccessPermissions(
          reportUserAccessPermissions.filter(
            (template) => template.id !== templateId
          )
        );
      })
      .catch((error) => {
        setErrorMessage(`Error deleting report template: ${error.message}`);
      });
  };
  const [searchQuery, setSearchQuery] = useState("");

  // useEffect

  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [filteredUsers, setFilteredUsers] = useState();

  // Ensure search is a string and filter users
  // console.log(filteredUsers);
  useEffect(() => {
    if (search) {
      const filter = users.filter((user) => {
        // Ensure user is an object and not undefined
        if (typeof user !== "object" || user === null) {
          return false;
        }

        // Extract fields safely
        const firstName = user.firstName || "";
        const lastName = user.lastName || "";
        const email = user.email || "";
        const streetAddress = user.streetAddress || "";

        // Check if search starts with firstName, includes lastName, or matches email
        const searchLower = (search || "").toLowerCase();
        const firstNameMatch = firstName.toLowerCase().startsWith(searchLower);
        const fullNameMatch =
          `${firstName.toLowerCase()} ${lastName.toLowerCase()}`.includes(
            searchLower
          );
        const emailMatch = email.toLowerCase().includes(searchLower);
        const streetAddressMatch = streetAddress
          .toLowerCase()
          .includes(searchLower);

        // console.log("User:", user);
        // Determine if the user should be included based on search criteria
        return (
          firstNameMatch || fullNameMatch || emailMatch || streetAddressMatch
        );
      });

      setFilteredUsers(filter);
      setIsOpen(true);
    }
  }, [search, users]);

  // console.log("filteredUsers", filteredUsers);

  const [searchTemplate, setSearchTemplate] = useState("");
  const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);

  const SearchfilteredTemplates = reportTemplates.filter((template) =>
    template.name.toLowerCase().includes(searchTemplate.toLowerCase())
  );

  // Filter templates based on search term

  // const EdithandleSelect = (templateId, templateName) => {
  //   setReportTemplateId(templateId);
  //   setSearchTemplate(templateName); // Update the search input with the selected template name
  //   setShowTemplateDropdown(false); // Hide the dropdown after selection
  // };

  const combinedData = reportUserAccessPermissions.map((permission) => {
    // Find the user based on userId
    const user = users.find((user) => user.id === permission.userId);

    // Find the report template based on reportTemplateId
    const reportTemplate = reportTemplates.find(
      (temp) => temp.id === permission.reportTemplateId
    );

    // Return an object with all the relevant information
    return {
      ...permission, // Include all fields from reportUserAccessPermissions
      user: user || null, // Include the user data, or null if no match
      reportTemplate: reportTemplate || null, // Include the report template data, or null if no match
    };
  });

  console.log("combinedData", combinedData);
  // console.log("reportTemplates", reportTemplates);

  const ExportPayload = (data) => {
    // Ensure data is always an array
    const payload = Array.isArray(data) ? data : [data];

    // Define a mapping from original keys to desired header names
    const headerMapping = {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phone: "Phone",
      streetAddress: "Street Address",
      reportTemplateName: "Report Template Name",
      accessType: "Access Type",
      companyLocation: "Company Location",
      companyName: "Company Name",
      userType: "User Type",
      // Add other mappings as needed
    };

    // Map over the payload and clean the data
    const cleanedLeads = payload.map((item) => {
      const {
        // id,
        user, // Destructure user separately
        reportTemplate: { companyLocation, companyName } = {}, // Default to empty object if reportTemplate is undefined
        reportTemplateName,
        accessType,
      } = item;

      // Check if user exists, then destructure
      const {
        firstName = "",
        lastName = "",
        email = "",
        phone = "",
        streetAddress = "",
        type,
      } = user || {}; // Provide a fallback empty object if user is null or undefined

      // Create the cleaned object with renamed keys
      const cleanedItem = {
        // id,
        firstName,
        lastName,
        email,
        phone,
        streetAddress,
        userType: type,
        reportTemplateName,
        companyName,
        companyLocation,
        accessType,
      };

      // Rename keys based on the headerMapping
      const renamedItem = {};
      Object.keys(cleanedItem).forEach((key) => {
        const newKey = headerMapping[key] || key; // Use mapped name if available, otherwise use the original key
        renamedItem[newKey] = cleanedItem[key];
      });

      return renamedItem;
    });

    return cleanedLeads;
  };

  // SHORTING ----------------------------------------------------------------
  // const [isAscending, setIsAscending] = useState(true);

  const [sortOrder, setSortOrder] = useState(null);
  const [sortField, setSortField] = useState(null);

  // Merging user and reportTemplate data with permissions
  const mergedPermissions = useMemo(() => {
    return reportUserAccessPermissions.map((permission) => {
      const user = users.find((user) => user.id === permission.userId);
      const reportTemplate = reportTemplates.find(
        (temp) => temp.id === permission.reportTemplateId
      );

      return {
        ...permission,
        name: user?.firstName || "",
        companyLocation: reportTemplate?.companyLocation || "",
        reportTemplateName: reportTemplate?.name || "", // Ensure correct field name
      };
    });
  }, [reportUserAccessPermissions, users, reportTemplates]);

  // Sorting logic
  const sortedPermissions = useMemo(() => {
    if (!sortField) return mergedPermissions; // Show original data when not sorted

    return [...mergedPermissions].sort((a, b) => {
      const aValue = a[sortField]?.toLowerCase() || ""; // Handle undefined or null
      const bValue = b[sortField]?.toLowerCase() || "";

      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
  }, [sortField, sortOrder, mergedPermissions]);

  // Handling sort order toggle
  const handleSort = (field) => {
    if (sortField === field) {
      if (sortOrder === "asc") {
        setSortOrder("desc");
      } else if (sortOrder === "desc") {
        setSortOrder(null); // Reset to original order on third click
        setSortField(null);
      } else {
        setSortOrder("asc");
      }
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const toggleSortOrder = (header) => {
    const field = headerFieldMapping[header];
    if (field) {
      handleSort(field);
    }
  };

  // Header Field Mapping for Sorting
  const headerFieldMapping = {
    "User Name & Address": "name",
    "User Role": "userType",
    "Report Template": "reportTemplateName",
    "Company Name & Address": "companyLocation",
  };

  // TableHead definition
  // const TableHead = [
  //   "User Name & Address",
  //   "User Role",
  //   "Report Template",
  //   "Company Name & Location",
  //   "access",
  //   "Action",
  // ];

  // TableHead definition
  // const TableHead = [
  //   "User Name & Address",
  //   "User Role",
  //   "Report Template",
  //   "Company Name & Location",
  //   "access",
  //   "Action",
  // ];

  // Define the headers
  // const TableHead = ["Name", "User Type", "Report Template", "Company Location"];

  // const handleFilterChange = (e) => {
  //   setFilters({
  //     ...filters,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // EDIT ------- BUTN CLICKED

  const [editReportId, setEditReportId] = useState(null);

  const [editUserId, setEditUserId] = useState(null);

  // State for user search input
  const [userSearchTerm, setUserSearchTerm] = useState("");

  // State for user dropdown visibility
  const [isUserDropdownOpen, setUserDropdownOpen] = useState(false);

  // State for report template search input
  const [templateSearchTerm, setTemplateSearchTerm] = useState("");

  // State for template dropdown visibility
  const [isTemplateDropdownVisible, setTemplateDropdownVisible] =
    useState(false);

  // State for selected access type
  const [selectedAccessType, setSelectedAccessType] = useState("");

  useEffect(() => {
    if (userSearchTerm) {
      const filter = users.filter((user) => {
        // Ensure user is an object and not undefined
        if (typeof user !== "object" || user === null) {
          return false;
        }

        // Extract fields safely
        const firstName = user.firstName || "";
        const lastName = user.lastName || "";
        const email = user.email || "";
        const streetAddress = user.streetAddress || "";

        // Check if search starts with firstName, includes lastName, or matches email
        const searchLower = (userSearchTerm || "").toLowerCase();
        const firstNameMatch = firstName.toLowerCase().startsWith(searchLower);
        const fullNameMatch =
          `${firstName.toLowerCase()} ${lastName.toLowerCase()}`.includes(
            searchLower
          );
        const emailMatch = email.toLowerCase().includes(searchLower);
        const streetAddressMatch = streetAddress
          .toLowerCase()
          .includes(searchLower);

        // console.log("User:", user);
        // Determine if the user should be included based on search criteria
        return (
          firstNameMatch || fullNameMatch || emailMatch || streetAddressMatch
        );
      });

      setFilteredUsers(filter);
      setUserDropdownOpen(true);
    }
  }, [userSearchTerm, users]);

  const filteredTemplates = reportTemplates.filter((template) =>
    template.name.toLowerCase().includes(templateSearchTerm.toLowerCase())
  );

  const [showReportData, setShowReportData] = useState("");
  // const [editReportTemplateId, setEditReportTemplateId] = useState(null)
  const handleSelect = (templateId, templateName, templateLocation) => {
    setReportTemplateId(templateId);
    if (searchTemplate) {
      setSearchTemplate(templateName); // Update the search input with the selected template name
    }
    if (templateSearchTerm) {
      setTemplateSearchTerm(templateName);
      setShowReportData({
        ...showReportData,
        companyLocation: templateLocation,
      });
    }
    setTemplateDropdownVisible(false);
    setShowTemplateDropdown(false); // Hide the dropdown after selection
  };

  // console.warn("editUserId", editUserId);
  // console.warn("reportTemplateId", reportTemplateId);

  const UpdateCodeHandler = () => {
    // Validate selections
    // if (!editUserId && !rreportTemplateId && !selectedAccessType) {
    //   alert("Please select User, Report Template, and Access Type.");
    //   return;
    // }

    // Find selected user and report template
    const selectedUser = users.find((user) => user.id === editUserId);

    const selectedTemplate = reportTemplates.find(
      (template) => template.id === reportTemplateId
    );
    const permissions = reportUserAccessPermissions.find(
      (permission) => permission.id === editReportId
    );
    console.log("permissions", permissions);
    console.log("selectedTemplate", selectedTemplate);

    // Check if selected user and template exist
    // if (!selectedUser || !selectedTemplate) {
    //   alert("Selected User or Report Template does not exist.");
    //   return;
    // }

    // Create new permission object

    const updatedPermission = {
      email: selectedUser?.email,
      userId: editUserId,
      reportTemplateName: selectedTemplate?.name || "",
      reportTemplateId: reportTemplateId,
      accessType: selectedAccessType
        ? selectedAccessType
        : permissions?.accessType,

      userType: selectedUser?.type,
    };

    console.warn("updatedPermission", updatedPermission);
    // Update the permission in the state

    const permissionRef = ref(
      database,
      `reportUserAccessPermissions/${editReportId}`
    );
    set(permissionRef, updatedPermission);

    const updatedPermissions = reportUserAccessPermissions.map((permission) =>
      permission.id === editReportId ? updatedPermission : permission
    );

    setReportUserAccessPermissions(updatedPermissions);

    setEditReportId(null);
    setEditUserId(null);
    setUserSearchTerm("");
    setTemplateSearchTerm("");
    setSelectedAccessType("");
    setReportTemplateId("");
  };

  // Filtering --------------------------------------------------------------
  const [filters, setFilters] = useState({
    name: "",
    usertype: "",
    reporttemplate: "",
    location: "",
    accessType:""
  });

  const filteredPermissions = sortedPermissions.filter((permission) => {
    const user = users.find((user) => user.id === permission.userId);
    const template = reportTemplates.find(
      (temp) => temp.id === permission.reportTemplateId
    );
// console.log("permission", filters?.accessType, permission.accessType && permission.accessType === filters?.accessType ? permission.accessType : null)
    return (
      (!filters.name ||
        (user &&
          (user.firstName + " " + user.lastName)
            .toLowerCase()
            .includes(filters.name.toLowerCase()))) &&
      (!filters.usertype ||
        (permission.userType &&
          permission.userType
            .toLowerCase()
            .includes(filters.usertype.toLowerCase()))) &&
      (!filters.reporttemplate ||
        (permission.reportTemplateName &&
          permission.reportTemplateName
            .toLowerCase()
            .includes(filters.reporttemplate.toLowerCase()))) &&
      (!filters.location ||
        (template &&
          (template.companyLocation
            .toLowerCase()
            .includes(filters.location.toLowerCase()) ||
            template.companyName
              .toLowerCase()
              .includes(filters.location.toLowerCase())))) &&
      (filters.accessType === "" ||
        (permission.accessType && permission.accessType.toLowerCase().includes(filters.accessType)))
    );
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  console.log(
    "{combinedData?.user.includes(editUserId)}",
    editUserId,
    users.find((user) => user.id === editUserId)?.type
  );

  useEffect(() => {
    if (userSearchTerm === "") {
      setShowReportData({ ...showReportData, userRole: "" });
    }
    if (templateSearchTerm === "") {
      setShowReportData({ ...showReportData, companyLocation: "" });
    }
  }, [userSearchTerm]);

  console.log("reportTamplate", reportTemplates);
  console.log("filteredTemplates", filteredTemplates);
  return (
    <>
      {ShowPopUp && <PopUp title={`Report Assignment Already Exist`} />}

      <div className={`w-full text-sm h-full ${PixelStyle} `}>
        <HeaderContainer pageTitle='assign Report'>
          <span className='text-black'>assign report</span>
        </HeaderContainer>

        {/* header to give permissoins */}
        <div className='w-full flex gap-3'>
          <div className='w-full flex   justify-between gap-3 lg:gap-10 bg-white shadow p-3 rounded-lg'>
            <div className='flex lg:flex-row flex-col lg:items-center  text-sm  gap-3 w-full'>
              <div className='flex items-center gap-3 w-full'>
                <div className='relative w-full'>
                  <input
                    type='text'
                    placeholder='Search users...'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onBlur={() => setTimeout(() => setIsOpen(false), 500)}
                    className='w-full p-2 border rounded shadow-sm outline-none'
                  />
                  {search && isOpen && (
                    <ul className=' absolute p-3 z-10 w-full mt-1 border border-gray-300 rounded bg-white shadow-lg max-h-52 overflow-y-auto assignReportScroll'>
                      {filteredUsers.length > 0 ? (
                        filteredUsers.map((user, index) => (
                          <li
                            key={index}
                            className='p-2 border-b hover:bg-gray-200 cursor-pointer flex items-center gap-3'
                            onClick={() => {
                              setSearch(
                                user?.firstName +
                                  " " +
                                  user?.lastName +
                                  " (" +
                                  user?.email +
                                  ")" || ""
                              ); // Ensure user has firstName
                              setIsOpen(false);
                              setUserId(user.id);
                            }}
                          >
                            <div className='font-semibold bg-zinc-800 text-white w-10 h-10 flex items-center justify-center rounded-full'>
                              {user.firstName ? user.firstName[0] : "?"}
                              {user.lastName ? user.lastName[0] : "?"}
                            </div>
                            <div className='flex flex-col'>
                              <span className='text-blue-600 text-base'>
                                {user?.firstName + " " + user?.lastName}
                              </span>
                              <span className='text-zinc-400 '>
                                {user?.streetAddress}
                              </span>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li className='p-2 text-gray-500'>No users found</li>
                      )}
                    </ul>
                  )}
                </div>

                <div className='relative w-full'>
                  <input
                    type='text'
                    placeholder='Search Report Template'
                    className='w-full p-2 border rounded shadow-sm outline-none'
                    value={searchTemplate}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchTemplate(value);
                      if (value) {
                        setShowTemplateDropdown(true);
                      } else {
                        setShowTemplateDropdown(false);
                      }
                    }}
                  />
                  {showTemplateDropdown && (
                    <ul className='absolute p-3 z-10 w-full mt-1 border border-gray-300 rounded bg-white shadow-lg max-h-52 overflow-y-auto assignReportScroll'>
                      {SearchfilteredTemplates.length > 0 ? (
                        SearchfilteredTemplates.map(
                          (template) =>
                            template.name && (
                              <li
                                key={template.id}
                                className='p-2 border-b hover:bg-gray-200 cursor-pointer flex items-center gap-3'
                                onClick={() =>
                                  handleSelect(template.id, template.name)
                                }
                              >
                                <div className='flex flex-col'>
                                  <span className=' text-base font-semibold'>
                                    {template.name && template.name}
                                  </span>
                                  <span className=' flex '>
                                    <span className=''>
                                      {template.companyName}{" "}
                                    </span>
                                  </span>
                                  <span className='text-zinc-400 '>
                                    {template.companyLocation &&
                                      template.companyLocation}
                                  </span>
                                </div>
                              </li>
                            )
                        )
                      ) : (
                        <li className='p-2 text-gray-500'>
                          No templates found
                        </li>
                      )}
                    </ul>
                  )}
                </div>

                <select
                  className='py-2 border rounded-md px-2 capitalize w-full'
                  value={accessType}
                  onChange={(e) => setAccessType(e.target.value)}
                >
                  <option value=''>Select Access Type</option>
                  <option value='Populate'>Populate</option>
                  <option value='View-Limited'>View-Limited</option>
                  <option value='View-Full'>View-Full</option>
                  <option value='Edit'>Edit</option>
                </select>
              </div>
              <div className='flex justify-end  xl:hidden '>
                {/* <AddPermissonButtton onClick={addPermission} type={"cancel"} /> */}
                <AddPermissonButtton onClick={addPermission} />
              </div>
            </div>
          </div>
          <div className='hidden xl:flex  items-center gap-2'>
            {/* <AddPermissonButtton onClick={addPermission} type={"cancel"} /> */}
            <AddPermissonButtton onClick={addPermission} />
          </div>
        </div>

        {/* {errorMessage && <p className='text-danger'>{errorMessage}</p>} */}

        {/* <div className='bg-white  p-3 rounded-lg shadow mt-3 flex flex-col gap-5 w-full'> */}
        <div className='bg-white  border rounded-lg shadow mt-3 flex flex-col  w-full'>
          <div className='flex justify-between items-center w-full px-5 py-2'>
            <h4 className='text-2xl font-semibold'>Permissions</h4>

            <button
              className='text-zinc-500 p-2 rounded flex items-center gap-2'
              onClick={() => ExportToExcel(ExportPayload(combinedData))}
            >
              <GoDownload className='text-2xl' />
            </button>
          </div>
          <TableLayout
            TableHead={TableHead}
            sortField={sortField}
            sortOrder={sortOrder}
            toggleSortOrder={toggleSortOrder}
            headerFieldMapping={headerFieldMapping}
          >
            <>
              {/* Filters */}
              <tr className='border-b bg-zinc-50  '>
                {["Name", "userType", "Report Template", "Location"].map(
                  (header, index) => (
                    <th scope='col' className='px-6 py-1' key={index}>
                      <input
                        placeholder={`Filter by ${
                          header === "userType"
                            ? "User Role"
                            : header === "Location"
                            ? "Company & Address"
                            : header
                        }`}
                        name={header.toLowerCase().replace(" ", "")} // e.g., "userType" becomes "usertype"
                        value={filters[header.toLowerCase().replace(" ", "")]}
                        onChange={handleFilterChange}
                        className='border p-1 px-2 rounded-md font-normal outline-blue-500'
                      />
                    </th>
                  )
                )}

<th className='px-6 py-1' >

<input
name="accessType" value={filters?.accessType} onChange={handleFilterChange}
className='border p-1 px-2 rounded-md font-normal outline-blue-500'
placeHolder="Select Access Type"
/>
                  {/* <select name="accessType" onChange={handleFilterChange}  className='border p-1 px-2 rounded-md font-normal outline-blue-500' >
                  <option value=''>Select Access Type</option>
                  <option value='Populate'>Populate</option>
                  <option value='View-Limited'>View-Limited</option>
                  <option value='View-Full'>View-Full</option>
                  <option value='Edit'>Edit</option>
                  </select> */}
                </th>
                <th></th>
              </tr>
              {filteredPermissions.map((permission, index) => (
                <>
                  <Tr
                    key={index}
                    className={`${
                      editReportId === permission.id &&
                      " bg-gray-100 hover:bg-gray-100"
                    }`}
                  >
                    <Td>
                      <b>
                        {users.find((user) => user.id === permission.userId)
                          ?.firstName +
                          " " +
                          users.find((user) => user.id === permission.userId)
                            ?.lastName}
                      </b>
                      <br />
                      {
                        users.find((user) => user.id === permission.userId)
                          ?.streetAddress
                      }{" "}
                      {
                        users.find((user) => user.id === permission.userId)
                          ?.city
                      }
                      <br />
                      <span className='text-secondary'>
                        {
                          users.find((user) => user.id === permission.userId)
                            ?.email
                        }
                      </span>
                    </Td>
                    <Td>{permission.userType}</Td>
                    <Td>{permission.reportTemplateName}</Td>
                    <Td>
                      <b>
                        {
                          reportTemplates.find(
                            (temp) => temp.id === permission.reportTemplateId
                          )?.companyName
                        }
                      </b>
                      <br />
                      {
                        reportTemplates.find(
                          (temp) => temp.id === permission.reportTemplateId
                        )?.companyLocation
                      }
                    </Td>
                    <Td>{permission.accessType}</Td>
                    <Td>
                      <div className='text-lg flex gap-1'>
                        <button
                          className='hover:text-blue-500 hover:bg-blue-100 p-1.5 rounded-lg'
                          onClick={() => {
                            setEditReportId(permission.id);
                            setEditUserId(permission.userId);
                            setReportTemplateId(permission.reportTemplateId);
                          }}
                        >
                          <FiEdit3 />
                        </button>

                        <button
                          className='hover:text-red-500 hover:bg-red-100 p-1.5 rounded-lg'
                          onClick={() => handleDelete(permission.id)}
                        >
                          <RiDeleteBin6Line />
                        </button>
                      </div>
                    </Td>
                  </Tr>
                  {editReportId === permission.id && (
                    <tr className='h-30 w-full bg-gray-300'>
                      <td className='py-3 relative pl-3'>
                        <input
                          type='text'
                          placeholder='Search users...'
                          value={userSearchTerm}
                          onChange={(e) => setUserSearchTerm(e.target.value)}
                          onBlur={() =>
                            setTimeout(() => setUserDropdownOpen(false), 200)
                          }
                          className='w-full p-2 border rounded shadow-sm outline-none'
                        />
                        {userSearchTerm && isUserDropdownOpen && (
                          <ul className='absolute z-10 p-3 z-10 w-full mt-1 border border-gray-300 rounded bg-white shadow-lg max-h-52 overflow-y-auto assignReportScroll'>
                            {filteredUsers.length > 0 ? (
                              filteredUsers.map((user, index) => (
                                <li
                                  key={index}
                                  className='p-2 border-b hover:bg-gray-200 cursor-pointer flex items-center gap-3'
                                  onClick={() => {
                                    setUserSearchTerm(
                                      user?.firstName +
                                        " " +
                                        user?.lastName +
                                        " (" +
                                        user?.email +
                                        ")" || ""
                                    );
                                    setShowReportData({
                                      ...showReportData,
                                      userRole: user.type,
                                    });
                                    setUserDropdownOpen(false);
                                    setEditUserId(user.id);
                                  }}
                                >
                                  <div className='font-semibold bg-zinc-800 text-white w-10 h-10 flex items-center justify-center rounded-full'>
                                    {user.firstName ? user.firstName[0] : "?"}
                                    {user.lastName ? user.lastName[0] : "?"}
                                  </div>
                                  <div className='flex flex-col'>
                                    <span className='text-blue-600 text-base'>
                                      {user?.firstName + " " + user?.lastName}
                                    </span>
                                    <span className='text-zinc-400'>
                                      {user?.streetAddress}
                                    </span>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <li className='p-2 text-gray-500'>
                                No users found
                              </li>
                            )}
                          </ul>
                        )}
                      </td>
                      <td className='px-6 py-1 text-zinc-800 '>
                        {userSearchTerm && showReportData?.userRole}
                      </td>
                      <td className='py-3 relative'>
                        <input
                          type='text'
                          placeholder='Search Report Template'
                          className='w-full p-2 border rounded shadow-sm outline-none'
                          value={templateSearchTerm}
                          onChange={(e) => {
                            const value = e.target.value;
                            setTemplateSearchTerm(value);
                            if (value) {
                              setTemplateDropdownVisible(true);
                            } else {
                              setTemplateDropdownVisible(false);
                            }
                          }}
                        />
                        {templateSearchTerm && isTemplateDropdownVisible && (
                          <ul className='absolute p-3 z-10 w-full mt-1 border border-gray-300 rounded bg-white shadow-lg max-h-52 overflow-y-auto assignReportScroll'>
                            {filteredTemplates.length > 0 ? (
                              filteredTemplates.map((template) => (
                                <li
                                  key={template?.id}
                                  className='p-2 border-b hover:bg-gray-200 cursor-pointer flex items-center gap-3'
                                  onClick={() =>
                                    handleSelect(
                                      template?.id,
                                      template?.name,
                                      template?.companyLocation
                                    )
                                  }
                                >
                                  <span className='flwx flex-col'>
                                    <p className='text-md'>{template?.name}</p>
                                    <p className='font-semibold'>
                                      {template?.companyName}
                                    </p>
                                    <p className='text-zinc-500'>{template?.companyLocation}</p>
                                  </span>
                                </li>
                              ))
                            ) : (
                              <li className='p-2 text-gray-500'>
                                No templates found
                              </li>
                            )}
                          </ul>
                        )}
                      </td>
                      <td className='px-6 py-1'>
                        {templateSearchTerm && showReportData?.companyLocation}
                      </td>
                      {/* <td>
                        <input
                          type='text'
                          placeholder='Search Report Template'
                          className='w-full p-2 border rounded shadow-sm outline-none'
                          value={searchTemplate}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSearchTemplate(value);
                            if (value) {
                              setShowTemplateDropdown(true);
                            } else {
                              setShowTemplateDropdown(false);
                            }
                          }}
                        />
                        {showTemplateDropdown && (
                          <ul className='absolute p-3 z-10 w-full mt-1 border border-gray-300 rounded bg-white shadow-lg max-h-52 overflow-y-auto assignReportScroll'>
                            {filteredTemplates.length > 0 ? (
                              filteredTemplates.map(
                                (template) =>
                                  template.name && (
                                    <li
                                      key={template.id}
                                      className='p-2 border-b hover:bg-gray-200 cursor-pointer flex items-center gap-3'
                                      onClick={() =>
                                        handleSelect(template.id, template.name)
                                      }
                                    >
                                      {template.name && template.name}
                                    </li>
                                  )
                              )
                            ) : (
                              <li className='p-2 text-gray-500'>
                                No templates found
                              </li>
                            )}
                          </ul>
                        )}
                      </td> */}

                      <td>
                        <select
                          className='py-2 border rounded-md px-2 capitalize w-full'
                          value={selectedAccessType}
                          onChange={(e) =>
                            setSelectedAccessType(e.target.value)
                          }
                        >
                          <option value=''>Select Access Type</option>
                          <option value='Populate'>Populate</option>
                          <option value='View-Limited'>View-Limited</option>
                          <option value='View-Full'>View-Full</option>
                          <option value='Edit'>Edit</option>
                        </select>
                      </td>
                      <td>
                        <div className='flex justify-end pr-2'>
                          <div className='flex gap-2 items-center'>
                            <button
                              className='text-lg bg-white hover:bg-red-100 text-red-600 border hover:border-red-500 p-2 px-3  rounded-md'
                              onClick={() => {
                                setEditReportId(null);

                                setEditUserId(null);
                                setUserSearchTerm("");
                                setTemplateSearchTerm("");
                                setSelectedAccessType("");
                                setReportTemplateId("");
                              }}
                            >
                              <MdOutlineClose />
                            </button>
                            <button
                              className='text-lg bg-white hover:bg-green-100 text-green-600 border hover:border-green-500 p-2 px-3 rounded-md'
                              onClick={UpdateCodeHandler}
                            >
                              <MdDone />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </>
          </TableLayout>

          {/* <div className='py-32 '></div> */}
          {!reportUserAccessPermissions.length > 0 && (
            <div className='flex justify-center items-center py-32'>
              {/* <Loader /> */}
              No report user access permissions found
            </div>
          )}
        </div>

        <div className='py-32'></div>
      </div>
    </>
  );
};

export default Assignreport;

const AddPermissonButtton = ({ onClick, type, title }) => (
  <div className='bg-white xl:shadow lg:p-3 pt-2 rounded-lg text-nowrap flex items-center'>
    <button
      className={` p-2 px-5 rounded-md  ${
        type === "cancel"
          ? "hover:bg-red-500 border hover:text-white"
          : "bg-blue-500 text-white"
      } `}
      onClick={onClick}
    >
      {type === "cancel" ? "cancel" : "Add Permission"}
    </button>
  </div>
);
