import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { GetUserList } from "./userList";
import { database } from "../../config/config";
import { get, ref } from "firebase/database";
// import "https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap";
// console.log("abc");

const Home = () => {
  console.log("abc");

  return (
    <>
      {" "}
      <Dashboard />
    </>
  );
};

export default Home;

const Dashboard = () => {
  const [userCounts, setUserCounts] = useState({
    clientUsers: 0,
    managementUsers: 0,
    securityUsers: 0,
    totalUsers: 0,
    clientList: 0,
  });

  const [clientList, setClientList] = useState([]);
  useEffect(() => {
    // Fetch user counts
    GetUserList().then((userList) => {
      const clientUsers = userList.filter((user) => user.type === "Client");
      const managementUsers = userList.filter(
        (user) => user.type === "Management"
      );
      const securityUsers = userList.filter((user) => user.type === "Security");

      setUserCounts((prev) => ({
        ...prev,
        clientUsers: clientUsers.length,
        managementUsers: managementUsers.length,
        securityUsers: securityUsers.length,
        totalUsers: userList.length,
      }));
    });

    // Fetch client locations
    const clientLocationsRef = ref(database, "clientLocations");
    get(clientLocationsRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const templatesArray = Object.keys(data)
          .map((key) => ({
            id: key,
            ...data[key],
          }))
          .filter((item) => !item.isDeleted && item.clientName)
          .map((value) => ({
            ...value,
            clientType: "view",
            lng: value.lang,
          }));
        console.log("templatesArray", templatesArray);
        setClientList(templatesArray);
        setUserCounts((prev) => ({
          ...prev,
          clientList: templatesArray.length,
        }));
      }
    });
  }, []);

  // Group and count industries in the clientList - dev
  const industryCounts = clientList.reduce((acc, item) => {
    const industry = item?.industry;
    if (industry) {
      acc[industry] = (acc[industry] || 0) + 1;
    }
    return acc;
  }, {});
  console.log(userCounts);
  return (
    <div className='p-10 bg-gray-50 min-h-screen w-11/12 '>
      <div className='bg-gradient-to-r from-blue-800 to-blue-300 text-white py-24 px-4 rounded-lg shadow-md mb-10'>
        <h1 className='text-start font-thin  text-4xl alice-regular'>
          Welcome to Maximal Administrator Portal
        </h1>
      </div>
      <div className='grid gap-8 w-full mx-auto pb-32'>
        {/* Registered Users */}
        <Section title='Registered Users' cols={4} font='alice-regular'>
          <Card title='Total Users' value={userCounts?.totalUsers} />
          <Card title='Client Users' value={userCounts?.clientUsers} />
          <Card title='Management Users' value={userCounts?.managementUsers} />
          <Card title='Security Users' value={userCounts?.securityUsers} />
        </Section>

        {/* Daily Notifications */}
        <Section title='Daily Notifications' cols={4}>
          <Card title='Total Number of Notifications' value='11' />
          <Card title='Geolocation Notifications' value='7' />
          <Card title='Time Clock Notifications' value='2' />
          <Card title='Scheduling Notifications' value='2' />
        </Section>

        <div className='grid grid-cols-2 gap-4'>
          {/* Clients */}
          <Section title='Clients' cols={2}>
            <Card
              title='Total Number of Clients'
              value={userCounts?.clientList}
            />
            <Card title='Total Weekly Client Hours' value='00,000' />
          </Section>

          {/* Invoices */}
          <Section title='Invoices' cols={2}>
            <Card title='Number of Invoices' value='000' />
            <Card title='Total Open Invoices Amount' value='$000,000.00' />
          </Section>
        </div>

        {/* Clients by Industry */}
        <Section title='Clients by Industry' cols={4}>
          {Object.keys(industryCounts).sort().map((industry, index) => (
            <Card
              key={index}
              title={industry}
              value={industryCounts[industry]} // Show count of each industry
            />
          ))}
          {/* <Card title='Residential' value='00' />
          <Card title='Commercial' value='0' />
          <Card title='Non-profit Organization' value='0' />
          <Card title='Retail' value='0' />
          <Card title='Education' value='0' />
          <Card title='Construction' value='0' />
          <Card title='Government' value='0' />
          <Card title='Industrial and Manufacturing' value='0' /> */}
        </Section>

        {/* Clients by State */}
        <Section title='Clients by State' cols={4}>
          {clientList.length > 0 &&
            [...new Set(clientList.map((client) => client.state))]
              .sort()
              .map((state) => ({
                state,
                count: clientList.filter((client) => client.state === state)
                  .length,
              }))
              .map((item, index) => (
                <Card key={index} title={item.state} value={item.count} />
              ))}
        </Section>
      </div>
    </div>
  );
};

const Section = ({ title, cols, mobileCol, children, font }) => (
  <section>
    <h2 className={`text-2xl  mb-4 libre-caslon-text-regular `}>
      {" "}
      <span className='border-b-2 border-black '>{title}</span>
    </h2>
    <div className={`grid grid-cols-${cols}  gap-4`}>{children}</div>
  </section>
);

const Card = ({ title, value }) => (
  <div className='bg-white p-2 pb-14 pr-10 border rounde  text-start libre-caslon-text-regular '>
    <h3 className='text-gray-600 text-base font-medium mb-1 '>{title}</h3>
    <p className='text-3xl font-semibold text-gray-800'>{value}</p>
  </div>
);
