import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth, database } from "../../config/config";
import { Link, useNavigate } from "react-router-dom";
import { login, logo, miniLogo } from "../../assets";
import LoginLayout, { Input } from "../../components/loginLayout";
import { child, get, ref } from "firebase/database";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     await signInWithEmailAndPassword(auth, email, password).then(
  //       (userCredential) => {
  //         console.log("userCredential", userCredential);
  //       }
  //     );
  //     const user = auth.currentUser;
  //     if (user) {
  //       const idTokenResult = await user.getIdTokenResult();

  //       console.log("idTokenResult", idTokenResult);
  //       const isAdmin = idTokenResult.claims.admin;
  //       if (isAdmin) {
  //         setLoading(false);
  //         localStorage.setItem("maxUser", JSON.stringify(idTokenResult));

  //         navigate("/home"); // Redirect admin users
  //       } else {
  //         auth.signOut();
  //         navigate("/unauthorised"); // Redirect regular users
  //         setLoading(false);
  //       }
  //     } else {
  //       setError("User not found after login.");
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Login failed:", error);
  //     if (error.code === "auth/invalid-credential") {
  //       setError("The credentials are invalid.");
  //     } else {
  //       setError("An error occurred during login. Please try again later.");
  //     }
  //   }
  // };

  const usersRef = ref(database, "users");

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission
    console.log("Login attempt with:", email, password); // Log email and password
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      const idTokenResult = await user.getIdTokenResult();
      console.log("idTokenResult", idTokenResult);
      if (user) {
        console.log("user", user);
        console.log("Login successful, checking user in database..."); // Log on successful login
        // Check if user exists in the database
        const userSnapshot = await get(child(usersRef, user.uid));
        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          console.log("userDatalogin", userData);
          if (userData?.verified === true) {
            localStorage.setItem("maxUser", JSON.stringify(userData));
            // console.log("LOGIN====>", userData)
            // navigate("/home"); // Redirect on successful login and user found
            window.location.reload();
            window.location.href = "/home"
          } else {
            setError("User is not Verified Yet!");
          }
        } else {
          // console.log("User is not found in admin");
          await auth.signOut();
          navigate("/");
          setError("Access denied. Only admin can log in here.");
          // window.location.reload();
        }
      }
    } catch (error) {
      console.error("Login failed:", error); // Log any error
      if (error.code === "auth/invalid-credential") {
        setError("The credentials are invalid.");
      } else {
        // For other errors, you can either display a generic message
        // or handle specific errors similarly by checking error.code
        setError("An error occurred during login. Please try again later.");
      }
    }
  };

  return (
    <LoginLayout
      error={error}
      loading={loading}
      onSubmit={handleLogin}
      registerLink='/register'
      Title='Sign In'
      layoutType='Sign in'
    >
      <Input
        type='email'
        className='border '
        id='email'
        placeholder='E-mail'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <Input
        type='password'
        className=''
        id='password'
        placeholder='Password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
    </LoginLayout>
  );
};

export default AdminLogin;

const CreateTabularTable = () => {};
