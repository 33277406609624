import React from "react";
import { useAppContext } from "../../../src/context/appContext";
// import { useAppContext } from "../../context/appContext";

const ClientHome = () => {
  const { UserType } = useAppContext();
  return (
    <div className='max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg'>
      <h1 className='text-2xl font-bold mb-4'>
        Dear {UserType?.firstName} {UserType?.lastName}
      </h1>
      <p className='mb-4'>
        Welcome to your personalized portal. Here, you can easily access daily
        reports from our security officers. Our goal is to provide you with the
        transparency and peace of mind that comes with knowing your property is
        well-protected.
      </p>
      <p className='mb-4'>
        Should you have any questions or need assistance, please do not hesitate
        to contact our support team at{" "}
        <a
          href='mailto:support@maximalsecurityservices.com'
          className='text-blue-600 hover:underline'
        >
          support@maximalsecurityservices.com
        </a>
        . Thank you for choosing Maximal Security Services LLC for your security
        needs.
      </p>
      <p className='mt-6'>
        Best regards,
        <br />
        Maximal Security Services
      </p>
    </div>
  );
};

export default ClientHome;
