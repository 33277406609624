import { useEffect, useState } from "react";

export const InputField = ({ placeholder, value, onChange, name }) => {
  return (
    <input
      placeholder={placeholder}
      className='border border-zinc-200 p-2 rounded-md w-full'
      value={value}
      onChange={onChange}
      name={name}
    />
  );
};

export const SearchableDropdown = ({
  users,
  selectedCompany,
  onSelect,
  setCompanyId,
  setCompanyLocationId,
  setCompanyLocation,
}) => {
  const [searchTerm, setSearchTerm] = useState();
  const [filteredOptions, setFilteredOptions] = useState(users);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSearchTerm(selectedCompany ? selectedCompany : "");
  }, [selectedCompany]);

  useEffect(() => {
    setFilteredOptions(
      searchTerm
        ? users.filter((user) =>
            user.company?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : users
    );
  }, [searchTerm, users]);

  const handleSelect = (company) => {
    setSearchTerm(company);
    onSelect(company);
    setIsOpen(false);
    console.log(company);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };
  console.log("searchTerm", filteredOptions);
  return (
    <div className='relative w-full'>
      <input
        type='text'
        className='py-2 border rounded-md px-2 capita lize w-full'
        value={searchTerm}
        onChange={handleInputChange}
        onClick={() => setIsOpen(true)}
        placeholder='Select a Company'
      />
      {searchTerm && isOpen && (
        <ul className='absolute z-10 border rounded-md mt-1 bg-white w-full max-h-60 overflow-auto text-nowrap'>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((user, index) => (
              <li
                key={index}
                // className='py-2 px-4 hover:bg-gray-200 cursor-pointer flex flex-col'
                onClick={() => {
                  handleSelect(user.company);
                  setCompanyId(user.id);
                }}
              >
                {user.clientLocations.map((location, index) => (
                  <div
                    key={index}
                    className='py-2 px-4 hover:bg-gray-200 cursor-pointer flex flex-col'
                    onClick={() => {
                      setCompanyLocationId(location.id);
                      setCompanyLocation(location.formattedAddress);
                    }}
                  >
                    <p className='font-semibold'>{user?.company}</p>
                    {/* <p className=' flex'>
                      <p className='bg-zinc-200 px-3 rounded text-xs'>
                        {location?.state}
                      </p>
                    </p> */}
                    <p className='text-sm text-zinc-400'>
                      {location.address}, {location.city}, {location?.state} {location.zipCode}
                    </p>
                  </div>
                ))}

                {/* <span className='font-semibold'>{user?.company}</span>
                <span className='text-sm text-zinc-400'>
                  {user?.streetAddress} {user?.city} {user?.zipCode}
                </span> */}
              </li>
            ))
          ) : (
            <li className='py-2 px-4'>No options found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export const Button = ({
  onClick,
  label,
  additionalClasses = "",
  children,
}) => {
  return (
    <button
      onClick={onClick}
      className={`border p-2 px-5 rounded-md flex items-center gap-1 font-semibold ${additionalClasses}`}
    >
      {children}
      {label}
    </button>
  );
};
