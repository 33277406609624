import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate, NavLink } from "react-router-dom";
import Layout, { PixelStyle } from "../../components/layout";
import { HeaderContainer } from "./viewreport";
import { ref, get, set } from "firebase/database";
import { database } from "../../config/config";
import { MdOutlineAdd } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import DocumentTableTemplate from "../../components/documentTableTemplate";
import TabularTableTamplate from "../../components/tabularTableTamplate";
import { document, tabularTableIcon } from "../../assets";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SearchableDropdown } from "../../components/reportComponents";

import {
  TableComponent,
  TablePreview,
  TabularTableComponent,
  TabularTablePreview,
} from "./tableComponents";

const ReportCustomize = () => {
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [chooseTable, setChooseTable] = useState(false);
  const [docTableName, setDocTableName] = useState(`table 1`);
  const [TabuTableName, setTabuTableName] = useState(`table 1`);
  const [documentTableData, setDocumentTableData] = useState([]);
  const [TabularTableData, setTabularTableData] = useState([]);
  const [documentTable, setDocumentTable] = useState("");
  const [tabularTable, setTabularTable] = useState("");
  const [openDocument, setOpenDocument] = useState(false);
  const [openTabular, setOpenTabular] = useState(false);

  const { reportTempId } = useParams();
  const navigate = useNavigate();
  const [reportName, setReportName] = useState("");
  const [tables, setTables] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  // const [successMessage, setSuccessMessage] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  const [showAddTableModal, setShowAddTableModal] = useState(false);
  const [showModal, setShowModal] = useState(false); // Initially hidden
  const [tableIndex, setTableIndex] = useState("");
  const [columnIndex, setColumnIndex] = useState("");
  const [allTables, setAllTables] = useState([]);
  const [addfixedValues, setAddfixedValues] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [companyLocationId, setCompanyLocationId] = useState("");
  const [status, setStatus] = useState("");

  // ----------------------------------------------------------------------------------
  const fetchReportTemplate = async (reportTempId) => {
    if (reportTempId) {
      const reportTemplateRef = ref(
        database,
        `reportTemplates/${reportTempId}`
      );
      get(reportTemplateRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setReportName(data.name);
            setTables(data.tables || []);
            const filteredUser = users.find(
              (user) => data.companyName === user.company
            );
            if (filteredUser) {
              setCompanyName(data.companyName);
              setCompanyId(data.companyId);
              setCompanyLocation(data.companyLocation);
              setCompanyLocationId(data.companyLocationId);
              setStatus(data.status);
            }
          } else {
            setErrorMessage("Report template not found");
          }
        })
        .catch((error) => {
          setErrorMessage(`Error fetching report template: ${error.message}`);
        });
    }
  };

  const fetchCompanyAndLocation = async () => {
    const additionalDataRef = ref(database, `clientLocations`);
    const snapshot = await get(additionalDataRef);
    if (snapshot.exists()) {
      const additionalData = snapshot.val();
      let clientData = Object.keys(additionalData)
        .map((key) => ({
          id: key,
          ...additionalData[key],
        }))
        .filter((item) => !item.isDeleted && item.clientName);

      let uniqueClientNames = new Set();
      clientData.filter((item) => {
        if (uniqueClientNames.has(item.clientName)) {
          return false;
        } else {
          uniqueClientNames.add(item.clientName);
          return true;
        }
      });
      uniqueClientNames = Array.from(uniqueClientNames);
      const clientLocationsMap = {};

      clientData.forEach((item) => {
        if (!clientLocationsMap[item.clientName]) {
          clientLocationsMap[item.clientName] = [];
        }
        clientLocationsMap[item.clientName].push({
          ...item,
          formattedAddress: `${item.address}, ${item.city}, ${item.state} ${item.zipCode}`,
        });
      });
      const clientDataArray = Object.keys(clientLocationsMap).map(
        (clientName) => ({
          id: clientName,
          company: clientName,
          clientLocations: clientLocationsMap[clientName],
        })
      );
      setUsers(clientDataArray);
    }
  };

  useEffect(() => {
    fetchCompanyAndLocation();
  }, []);

  useEffect(() => {
    fetchReportTemplate(reportTempId);
  }, [users]);
  // useEffect(() => {
  //   const usersRef = ref(database, "users");
  //   get(usersRef)
  //     .then(async (snapshot) => {
  //       if (snapshot.exists()) {
  //         const data = snapshot.val();

  //         console.log("===========", data);

  //         const templatesArray = Object.keys(data)
  //           .filter((key) => data[key].type === "Client" && data[key].company)
  //           .map((key) => ({
  //             id: key,
  //             ...data[key],
  //           }));

  //         const updatedTemplatesArray = await Promise.all(
  //           templatesArray.map(async (template) => {
  //             const additionalDataRef = ref(database, `clientLocations`);
  //             const snapshot = await get(additionalDataRef);
  //             if (snapshot.exists()) {
  //               const additionalData = snapshot.val();
  //               const clientData = Object.keys(additionalData)
  //                 .filter((key) => additionalData[key].clientId === template.id)
  //                 .map((key) => ({
  //                   id: key,
  //                   ...additionalData[key],
  //                 }));
  //               return {
  //                 ...template,
  //                 clientLocations: clientData,
  //               };
  //             }
  //             return template;
  //           })
  //         );

  //         setUsers(updatedTemplatesArray);

  //         await fetchReportTemplate(reportTempId, updatedTemplatesArray);
  //       } else {
  //         setErrorMessage("No User Profiles found");
  //       }
  //     })
  //     .catch((error) => {
  //       setErrorMessage(`Error fetching User Profiles: ${error.message}`);
  //     });
  // }, [reportTempId]);

  const handleAddTable = (type) => {
    setTables((prevTables) => [
      ...prevTables,
      {
        id: Date.now(),
        type: type,
        name: "",
        columns: [],
      },
    ]);
    setShowAddTableModal(false);
  };

  const handleDeleteTable = (tableIndex) => {
    const updatedTables = [...tables];
    updatedTables.splice(tableIndex, 1);
    setTables(updatedTables);
  };

  const handleTableNameChange = (tableIndex, value) => {
    const updatedTables = [...tables];
    updatedTables[tableIndex].name = value;
    setTables(updatedTables);
  };

  const handleAddColumn = (tableIndex) => {
    console.log("tableIndex:", tableIndex); // Debug log
    if (tableIndex !== undefined && tableIndex !== null) {
      const updatedTables = [...tables];
      console.log("Before adding column:", updatedTables[tableIndex]);
      if (!updatedTables[tableIndex].columns) {
        updatedTables[tableIndex].columns = [];
      }
      const newColumn = {};
      console.log("Table type:", updatedTables[tableIndex].type); // Debug log
      switch (updatedTables[tableIndex].type) {
        case "Document":
          Object.assign(newColumn, {
            sequence: "",
            title: "Title",
            height: "15",
            width: "10",
            format: "Text",
            border: "No",
          });
          break;
        case "Tabular":
          Object.assign(newColumn, {
            sequence: "",
            title: "Title",
            height: "15",
            width: "10",
            format: "Text",
            border: "No",
            fixed: [],
          });
          break;
        default:
          console.log("Unknown table type:", updatedTables[tableIndex].type); // Debug log
          return; // Do nothing if type is not recognized
      }

      updatedTables[tableIndex].columns.push(newColumn);
      console.log("After adding column:", updatedTables[tableIndex]); // Debug log
      setTables(updatedTables);
      console.log("Updated tables state:", tables); // Debug log after state update
    }
  };
  // add fixed value
  const handleAddfixedColumn = (tableIndex, columnIndex) => {
    const updatedTables = [...tables];

    // Check if the fixed array is initialized
    if (!updatedTables[tableIndex].columns[columnIndex].fixed) {
      updatedTables[tableIndex].columns[columnIndex].fixed = []; // Initialize the array if it's not
    }

    updatedTables[tableIndex].columns[columnIndex].fixed.push("");
    setTables(updatedTables);
  };

  const handleChange = (tableIndex, columnIndex, fieldName, value) => {
    if (value === undefined) return;

    const updatedTables = [...tables];

    // Update the specific field with the new value
    updatedTables[tableIndex].columns[columnIndex][fieldName] = value;

    // If the field being changed is a fixed value, save the change
    if (fieldName === "fixed") {
      updatedTables[tableIndex].columns[columnIndex].fixed = value;
    }

    setTables(updatedTables);

    // If the format field is changed to "fixed value", show the modal to edit fixed values
    if (fieldName === "format" && value === "fixed value") {
      setTableIndex(tableIndex);
      setColumnIndex(columnIndex);
      setShowModal(true);
      setAddfixedValues(true);
    }
  };

  const handleChangefix = (tableIndex, columnIndex, value, fixedIndex) => {
    if (value === undefined) return; // Avoid updating with undefined value

    const updatedTables = [...tables];
    updatedTables[tableIndex].columns[columnIndex].fixed[fixedIndex] = value;
    setTables(updatedTables);
  };

  const handleDeleteRow = (tableIndex, columnIndex) => {
    const updatedTables = [...tables];
    updatedTables[tableIndex].columns.splice(columnIndex, 1);
    setTables(updatedTables);
  };

  const handleDeleteFixedRow = (tableIndex, columnIndex, fixedIndex) => {
    const updatedTables = [...tables];
    updatedTables[tableIndex].columns[columnIndex].fixed.splice(fixedIndex, 1);
    setTables(updatedTables);
  };

  const handleUpdateReportTemplate = (e) => {
    e.preventDefault();
    if (reportName !== "") {
      const reportTemplateRef = ref(
        database,
        `reportTemplates/${reportTempId}`
      );
      const updatedReportTemplate = {
        name: reportName,
        tables: tables,
        companyName: companyName,
        companyLocation: companyLocation,
        companyId,
        companyLocationId,
        status: status,
      };

      set(reportTemplateRef, updatedReportTemplate)
        .then(() => {
          setSuccessMessage("Report template updated successfully");
          navigate("/viewreport");
        })
        .catch((error) => {
          setErrorMessage(`Error updating report template: ${error.message}`);
        });
    }
  };

  // const handleAddOption = (tableIndex, columnIndex, newOption) => {
  //   setTables((prevTables) => {
  //     const updatedTables = prevTables.map((table, tIndex) => {
  //       if (tIndex !== tableIndex) return table;

  //       const updatedColumns = table.columns.map((column, cIndex) => {
  //         if (cIndex !== columnIndex) return column;

  //         const options = column.options || []; // Initialize options array if not already initialized
  //         return {
  //           ...column,
  //           options: [...options, newOption],
  //         };
  //       });

  //       return {
  //         ...table,
  //         columns: updatedColumns,
  //       };
  //     });

  //     return updatedTables;
  //   });
  // };

  // const handlePreview = () => {
  //   handleUpdateReportTemplate();
  //   const reportData = { tables };
  //   navigate(`/ReportPreview/${reportTempId}`, { state: reportData });
  // };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // ----------------------------------------------------------------------------------

  // Document Table
  const CreateDocumentTable = () => {
    setOpenDocument(true);
    setChooseTable(false);
    handleAddTable("Document");
    // setDocumentTable((prev) => ({
    //   ...prev,
    //   tableName: docTableName,
    //   type: "document",
    //   documentTableData,
    // }));
  };
  const DeleteDocumentTable = () => {
    setDocumentTableData([]);
    setDocumentTable("");
    setOpenDocument(false);
  };

  // Tabular Table
  const CreateTabularTable = () => {
    handleAddTable("Tabular");
    setOpenTabular(true);
    setChooseTable(false);
    setTabularTable((prev) => ({
      ...prev,
      tableName: TabuTableName,
      type: "Tabular",
      documentTableData,
    }));
  };
  const DeleteTabluarTable = () => {
    setTabularTableData([]);
    setTabularTable("");
    setOpenTabular(false);
  };

  console.log("documentTable", documentTable);

  useEffect(() => {
    setDocumentTable((prev) => ({ ...prev }));
  }, [documentTableData]);

  const [reportData, setReportData] = useState({
    reportName: "",
    companyName: "",
    companyLocation: "",
  });

  const ReportFormDataHandler = (e) => {
    const { name, value } = e.target;
    setReportData((prevReport) => ({
      ...prevReport,
      [name]: value,
    }));
  };

  // all table and all the data add on this
  const ReportDataHandler = () => {};

  const generateNewID = uuidv4();
  const randomID = generateNewID.slice(0, 8);

  const payload = {
    id: randomID,
    sequence: "",
    title: "",
    height: "",
    width: "50%",
    format: "text",
    border: "no",
  };

  const AddDocumentTableHandler = () => {
    const newEntry = { ...payload };

    setDocumentTableData((prevData) => [...prevData, newEntry]);
  };
  const AddTablularTableHandler = () => {
    const newEntry = { ...payload };
    setTabularTableData((prevData) => [...prevData, newEntry]);
  };

  // fixed Value
  const fixedPayload = {
    id: randomID,
    value: "",
  };

  const [fixedValues, setFixedValues] = useState("");

  const FixedInputHandler = (e, id) => {
    const { name, value } = e.target;
    const updatedDocumentTableData = fixedValues.map((item) =>
      item?.id === id ? { ...item, [name]: value } : item
    );
    setFixedValues(updatedDocumentTableData);
  };

  const DeleteFixedValueHandler = (id) => {
    console.log("id", id);
    const updated = fixedValues.filter((item) => item.id !== id);
    setFixedValues(updated);
  };

  // --------------------------------------------

  const DeleteHandler = (id) => {
    console.log("id", id);
    const updated = documentTableData.filter((item) => item.id !== id);
    setDocumentTableData(updated);
  };

  const [openTabularPreview, setopenTabularPreview] = useState(false);
  const [openDocumentPreview, setopenDocumentPreview] = useState(false);

  const documentInputHandler = (e, id) => {
    const { name, value } = e.target;
    const updatedDocumentTableData = documentTableData.map((item) =>
      item?.id === id ? { ...item, [name]: value } : item
    );
    setDocumentTableData(updatedDocumentTableData);
  };
  const TabularInputHandler = (e, id) => {
    const { name, value } = e.target;
    const updatedDocumentTableData = TabularTableData.map((item) =>
      item?.id === id ? { ...item, [name]: value } : item
    );
    if (name === "format" && value === "fixed value") {
      setAddfixedValues(true);
    }

    setTabularTableData(updatedDocumentTableData);
  };

  // console log
  console.log();

  useEffect(() => {
    // console.log("documentTableData", documentTableData);
    console.log("fixedValues", fixedValues);
  }, [documentTableData, TabularTableData, fixedValues]);

  // ---------------------------------
  const [successMessage, setSuccessMessage] = useState("");

  console.log("tables", tables);

  const [previewTableIds, setPreviewTableIds] = useState([]);

  const PreviewHandler = (tableId) => {
    const have = previewTableIds.includes(tableId);
    if (have) {
      const filter = previewTableIds.filter((table) => table !== tableId);
      setPreviewTableIds(filter);
    } else {
      setPreviewTableIds([...previewTableIds, tableId]);
    }
    // console.warn(have);
  };

  // console.log("previewTableIds", previewTableIds);
  useEffect(() => {
    if (companyName) {
      const find = users.find((item) => item.company === companyName);
      console.log("find", find);
    }
  }, [companyName]);

  console.log("tables", tables);
  console.warn("previewTableIds", previewTableIds);

  // ----------------------------STATUS UPDATE---------------------------------------

  const StatusBtn = ({ title, onClick }) => (
    <button
      onClick={onClick}
      className={`border mr-4 tracking-wider capitalize  ${
        status === "Active"
          ? "border-green-700 text-green-700"
          : status === "Inactive"
          ? "border-red-700 text-red-700"
          : "border-yellow-600 text-yellow-600"
      }
       p-2 px-8 rounded-md flex items-center gap-1 font-semibold text-nowrap`}
    >
      {title ? title : "Loading Status"}
    </button>
  );

  const StatusUpdateHandler = () => {
    setStatus(status === "Active" ? "Inactive" : "Active");
  };
  console.log("USERS ", users);
  // console.log("Report TEMPLATE ", reportTemplates);
  console.log("clientLocations ");
  return (
    <>
      <div
        className={`flex flex-col gap-2 h-full   text-sm createReportScroll pb-20 ${PixelStyle}`}
      >
        <HeaderContainer
          pageTitle='Customize Report'
          container={<StatusBtn title={status} onClick={StatusUpdateHandler} />}
        >
          <NavLink to='/viewreport' className='hover:text-black'>
            View report
          </NavLink>
          <span className=''>/</span>
          <span className='text-black'>Customize report</span>
        </HeaderContainer>

        <div className='w-full flex gap-3'>
          <div
            className={`w-full flex justify-between gap-10 bg-white shadow p-3 rounded-lg border ${
              status === "Inactive" ? "border-red-500" : ""
            } `}
          >
            <div className='flex gap-3 w-full'>
              <input
                placeholder='Report Name'
                className='border border-zinc-200 p-2 rounded-md w-full'
                // onChange={ReportFormDataHandler}
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                name='reportName'
              />

              <SearchableDropdown
                users={users}
                selectedCompany={companyName}
                setCompanyId={setCompanyId}
                onSelect={setCompanyName}
                setCompanyLocationId={setCompanyLocationId}
                setCompanyLocation={setCompanyLocation}
                // setCompanyName=set
              />

              <div className='border border-zinc-200 p-2 rounded-md w-full text-nowrap overflow-x-auto min-w-40  scrollHeight'>
                {companyLocation ? (
                  companyLocation
                ) : (
                  <span className='text-zinc-400'>Location </span>
                )}
              </div>
            </div>
            <div>
              <button
                onClick={() => setChooseTable(!chooseTable)}
                className={`border  ${
                  chooseTable
                    ? "border-red-500 text-red-500"
                    : "border-blue-500 text-blue-600"
                } p-2 px-5 rounded-md flex items-center gap-1  font-semibold text-nowrap`}
              >
                <MdOutlineAdd
                  className={`text-xl transition duration-300  ${
                    chooseTable && "rotate-45"
                  } `}
                />
                {chooseTable ? "close table" : "Add table"}
              </button>
            </div>
          </div>
          <div
            className={`bg-white border shadow p-3 rounded-lg text-nowrap flex items-center  ${
              status === "Inactive" ? "border-red-500" : ""
            }`}
          >
            <button
              className=' p-2 px-5 rounded-md hover:bg-red-500 border hover:text-white '
              onClick={() => navigate("/viewreport")}
            >
              Cancel
            </button>
          </div>
          <div
            className={`bg-white border shadow p-3 rounded-lg text-nowrap flex items-center  ${
              status === "Inactive" ? "border-red-500" : ""
            }`}
          >
            <button
              className=' p-2 px-5 rounded-md bg-blue-500 text-white '
              onClick={handleUpdateReportTemplate}
            >
              Save Report
            </button>
          </div>
        </div>
        <div className='flex flex-col gap-5 items-center justify-start my-5 pb-20 relative  '>
          {chooseTable && (
            <div className='fixed z-10 top-1/2  transform translate-y-[-50%] '>
              <div className='bg-white pb-10 capitalize text-zinc-600 flex flex-col gap-7 p-6 px-20 rounded-lg shadow '>
                <h1 className='text-xl font-bold border-b pb-2'>
                  Select table type
                </h1>
                <div className='flex gap-5'>
                  <div
                    onClick={CreateDocumentTable}
                    className='hover:shadow-lg hover:border-blue-500 hover:text-blue-500 flex flex-col justify-center items-center gap-4 border p-3 px-8 rounded-xl cursor-pointer'
                  >
                    <img src={document} alt='' className='h-32 p-3' />
                    <p className='font-bold border-t w-full text-center py-2'>
                      Document Table
                    </p>
                  </div>
                  <div
                    onClick={CreateTabularTable}
                    className='hover:shadow-lg hover:border-blue-500 hover:text-blue-500 flex flex-col justify-center items-center gap-4 border p-3 px-8 rounded-xl cursor-pointer'
                  >
                    <img src={tabularTableIcon} alt='' className='h-32' />
                    <p className='font-bold border-t w-full text-center py-2'>
                      Tabular Table
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='w-full mb-10 '>
            {tables.map((table, tableIndex) => (
              <>
                {table.type === "Document" && (
                  <div className={`flex flex-col gap-3 w-full my-5 `}>
                    <div className='bg-white  shadow rounded-md overflow-hidden '>
                      <div className='bg-green-100 w-full text-xs text-green-700 py-0.5 font-semibold px-4 capitalize'>
                        Document table
                      </div>
                      <div className='p-4 w-full flex flex-col gap-5'>
                        <div className='flex justify-between'>
                          <div className='flex flex-col items-start'>
                            <input
                              placeholder='Table Name'
                              value={table?.name}
                              // onChange={(e) => setDocTableName(e.target.value)}
                              onChange={(e) =>
                                handleTableNameChange(
                                  tableIndex,
                                  e.target.value
                                )
                              }
                              className='bg-transparent outline-none text-2xl font-bold text-zinc-600'
                            />
                            {docTableName === "table 1" && (
                              <span className='border-green-500 border rounded-full px-5 text-xs text-green-500'>
                                Editable
                              </span>
                            )}
                          </div>
                          <div className='flex items-center gap-3 '>
                            <button
                              className={`flex items-center gap-2 border p-1.5 px-4 rounded-md ${
                                previewTableIds.includes(table.id) &&
                                "bg-green-50 border-green-300"
                              } `}
                              onClick={() => PreviewHandler(table.id)}
                            >
                              {previewTableIds.includes(table.id) ? (
                                <LuEye />
                              ) : (
                                <LuEyeOff />
                              )}
                              <span className='text-xs'> Preview</span>
                            </button>

                            <button
                              onClick={() => handleDeleteTable(tableIndex)}
                              className={` text-zinc-600 hover:text-red-400 border border-zinc-200 hover:border-red-200  p-1.5 rounded-lg ${
                                docTableName === "table 1" && "px-4 text-xl"
                              }`}
                            >
                              <RiDeleteBin6Line />
                            </button>
                          </div>
                        </div>
                        <div class='relative overflow-x-auto pb-8 '>
                          <table class='w-full text-sm text-left rtl:text-right '>
                            <thead class='text-xs text-gray-700 uppercase bg-gray-50  '>
                              <tr>
                                <th scope='col' class='px-6 py-3'>
                                  Sequence
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  Title
                                </th>
                                {/* <th scope='col' class='px-6 py-3'>
                          Height (px)
                        </th> */}
                                <th scope='col' class='px-6 py-3'>
                                  Width (%)
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  Specific Format
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  Border
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  <button
                                    // onClick={AddDocumentTableHandler}
                                    onClick={() => handleAddColumn(tableIndex)}
                                    className='p-1 px-2 border-2  border-green-400 text-green-500 rounded-lg flex items-center gap-1'
                                  >
                                    <MdOutlineAdd className='text-lg' />
                                    <span className=''>ADD</span>
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {table.columns.map((column, columnIndex) => (
                                <tr class='bg-white border-b  '>
                                  <th
                                    scope='row'
                                    class='px-5 py-4 font-medium text-black whitespace-nowrap '
                                  >
                                    <Input
                                      placeholder='Sequence'
                                      className='w-20'
                                      value={column?.sequence}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "sequence",
                                          e.target.value
                                        )
                                      }
                                      name='sequence'
                                    />
                                  </th>
                                  <td class='px-5 py-4'>
                                    <Input
                                      placeholder='Title'
                                      value={column.title}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "title",
                                          e.target.value
                                        )
                                      }
                                      name='title'
                                    />
                                  </td>
                                  {/* <td class='px-5 py-4'>
                            <Input
                              placeholder='Height'
                              onChange={(e) =>
                                documentInputHandler(e, column?.id)
                              }
                              name='height'
                            />
                          </td> */}
                                  <td class='px-5 py-4'>
                                    {/* <Input
                                      className='w-20'
                                      placeholder='width'
                                      value={column.width}
                                      onChange={(e) =>
                                        e.target.value <= 100 &&
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "width",
                                          e.target.value
                                        )
                                      }
                                      name='width'
                                    /> */}

                                    <select
                                      className='px-2 border p-2 rounded-md'
                                      value={column.width}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "width",
                                          e.target.value
                                        )
                                      }
                                      name='width'
                                    >
                                      <option>50%</option>
                                      <option>100%</option>
                                    </select>
                                  </td>
                                  <td class='px-5 py-4'>
                                    {/* <Input placeholder='Format ' /> */}
                                    <select
                                      className='px-2 border p-2 rounded-md'
                                      value={column.format}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "format",
                                          e.target.value
                                        )
                                      }
                                      name='format'
                                    >
                                      <option>text</option>
                                      <option>number</option>
                                      <option>date</option>
                                      <option>time</option>
                                      <option>long text</option>
                                      <option>photo upload</option>
                                      <option>photo capture</option>
                                      <option>f/c</option>
                                    </select>
                                  </td>
                                  <td class='px-6 py-4'>
                                    <select
                                      className='px-2 border p-2 rounded-md '
                                      value={column.border}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "border",
                                          e.target.value
                                        )
                                      }
                                      name='border'
                                    >
                                      <option>no</option>
                                      <option>yes</option>
                                    </select>
                                  </td>
                                  <td className=''>
                                    <div className='flex justify-center items-center'>
                                      {/* {item?.id} */}
                                      <RiDeleteBin6Line
                                        className='text-xl text-red-500 cursor-pointer'
                                        onClick={() =>
                                          handleDeleteRow(
                                            tableIndex,
                                            columnIndex
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* document preview section */}
                    {previewTableIds.includes(table.id) && (
                      <div className=' bg-white shadow rounded-md overflow-hidden'>
                        <div className='bg-green-100 w-full text-xs text-green-700 py-0.5 font-semibold px-4 capitalize'>
                          preview
                        </div>
                        <div className='p-2'>
                          <div className=''>
                            <p className='font-semibold capitalize px-2 border-b pb-1'>
                              {table?.name}
                            </p>
                            <div className='mt-3 grid grid-cols-1 md:grid-cols-2'>
                              {Array.from({
                                length: Math.max(
                                  ...table.columns.map((item) => item.sequence)
                                ),
                              }).map((_, i) => {
                                const item = table.columns.find(
                                  (column) => Number(column.sequence) === i + 1
                                );

                                return (
                                  <div key={i} className='p-2'>
                                    <div key={i + 1}>
                                      {item ? (
                                        <div
                                          className={`grid grid-cols-2 p-2 items-center`}
                                        >
                                          <>
                                            <span className='font-semibold  bg-green-100 border border-green-100 px-2 p-1'>
                                              {item.title
                                                ? item.title
                                                : "Empty Title"}
                                            </span>
                                            <span
                                              className={`p-1 ${
                                                item.width === "100%"
                                                  ? "col-span-2"
                                                  : "col-span-1"
                                              }`}
                                              style={
                                                item.border === "yes"
                                                  ? { borderWidth: "1px" }
                                                  : {}
                                              }
                                            >
                                              {item.format}
                                            </span>
                                          </>
                                        </div>
                                      ) : (
                                        <div className=' bg-zinc-100 rounded-md py-2 '></div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {table.type === "Tabularr" && (
                  <div className={`flex flex-col gap-3 relative w-full my-5`}>
                    <div className='bg-white  shadow rounded-md overflow-hidden '>
                      <div className='bg-blue-100 w-full text-xs text-blue-700 py-0.5 font-semibold px-4 capitalize'>
                        Tabular table
                      </div>
                      <div className='p-4 w-full flex flex-col gap-5 relative'>
                        <div className='flex justify-between'>
                          <div className='flex flex-col items-start'>
                            <input
                              placeholder='Table Name'
                              value={table?.name}
                              // onChange={(e) => setDocTableName(e.target.value)}
                              onChange={(e) =>
                                handleTableNameChange(
                                  tableIndex,
                                  e.target.value
                                )
                              }
                              className='bg-transparent outline-none text-2xl font-bold uppercase text-zinc-600'
                            />
                            {TabuTableName === "table 1" && (
                              <span className='border-blue-500 border rounded-full px-5 text-xs text-blue-500'>
                                Editable
                              </span>
                            )}
                          </div>
                          <div className='flex items-center gap-3 '>
                            <button
                              className={`flex items-center gap-2 border p-1.5 px-4 rounded-md ${
                                openTabularPreview &&
                                "bg-blue-50 border-blue-300"
                              } `}
                              onClick={() => PreviewHandler(table.id)}
                            >
                              {previewTableIds.includes(table.id) ? (
                                <LuEye />
                              ) : (
                                <LuEyeOff />
                              )}
                              <span className='text-xs'> Preview</span>
                            </button>

                            <button
                              onClick={() => handleDeleteTable(tableIndex)}
                              className={` text-zinc-600 hover:text-red-400 border border-zinc-200 hover:border-red-200  p-1.5 rounded-lg ${
                                TabuTableName === "table 1" && "px-4 text-xl"
                              }`}
                            >
                              <RiDeleteBin6Line />
                            </button>
                          </div>
                        </div>

                        <div class='relative overflow-x-auto pb-8 '>
                          <table class='w-full text-sm text-left rtl:text-right  capitalize '>
                            <thead class='text-xs text-gray-700 uppercase bg-gray-50  '>
                              <tr>
                                <th scope='col' class='px-6 py-3'>
                                  Sequence
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  Title
                                </th>
                                {/* <th scope='col' class='px-6 py-3'>
                       Height (px)
                     </th> */}
                                <th scope='col' class='px-6 py-3'>
                                  Width (%)
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  Specific Format
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  Border
                                </th>
                                <th scope='col' class='px-6 py-3'>
                                  <button
                                    onClick={() => handleAddColumn(tableIndex)}
                                    className='p-1 px-2 border-2  border-blue-400 text-blue-500 rounded-lg flex items-center gap-1'
                                  >
                                    <MdOutlineAdd className='text-lg' />
                                    <span className=''>ADD</span>
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {table.columns.map((column, columnIndex) => (
                                <tr class='bg-white border-b  '>
                                  <th
                                    scope='row'
                                    class='px-5 py-4 font-medium text-black whitespace-nowrap '
                                  >
                                    <Input
                                      placeholder='Sequence'
                                      className='w-20'
                                      type='number'
                                      value={column.sequence}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "sequence",
                                          e.target.value
                                        )
                                      }
                                      name='sequence'
                                    />
                                  </th>
                                  <td class='px-5 py-4'>
                                    <Input
                                      placeholder='Title'
                                      type='text'
                                      value={column.title}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "title",
                                          e.target.value
                                        )
                                      }
                                      name='title'
                                    />
                                  </td>

                                  <td class='px-5 py-4'>
                                    <Input
                                      className='w-20'
                                      placeholder='width'
                                      value={column.width}
                                      onChange={(e) =>
                                        e.target.value <= 100 &&
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "width",
                                          e.target.value
                                        )
                                      }
                                      name='width'
                                    />
                                  </td>
                                  <td class='px-5 py-4'>
                                    {/* <Input placeholder='Format ' /> */}
                                    <select
                                      className='px-2 border p-2 rounded-md capitalize'
                                      value={column.format}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "format",
                                          e.target.value
                                        )
                                      }
                                      name='format'
                                    >
                                      <option value='input'>Text</option>
                                      <option value='number'>Number</option>
                                      <option value='date'>Date</option>
                                      <option value='time'>Time</option>
                                      <option value='textarea'>
                                        Long Text
                                      </option>
                                      <option value='fixed value'>
                                        Fixed Value
                                      </option>
                                      <option value='Yes/No'>Yes/No</option>
                                      <option value='photo Upload'>
                                        Photo Upload
                                      </option>
                                      <option value='photo Capture'>
                                        Photo Capture
                                      </option>
                                      <option value='F&C'>F&C</option>
                                    </select>
                                  </td>
                                  <td class='px-6 py-4'>
                                    <select
                                      className='px-2 border p-2 rounded-md '
                                      value={column.border}
                                      onChange={(e) =>
                                        handleChange(
                                          tableIndex,
                                          columnIndex,
                                          "border",
                                          e.target.value
                                        )
                                      }
                                      name='border'
                                    >
                                      <option value='Yes'>Yes</option>
                                      <option value='No'>No</option>
                                    </select>
                                  </td>
                                  <td className=''>
                                    <div className='flex justify-center items-center'>
                                      {/* {item?.id} */}
                                      <RiDeleteBin6Line
                                        className='text-xl text-red-500 cursor-pointer'
                                        onClick={() =>
                                          handleDeleteRow(
                                            tableIndex,
                                            columnIndex
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                            {/* fixed value */}
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Tabular preview section */}
                    {previewTableIds.includes(table.id) && (
                      <div className=' bg-white shadow rounded-md overflow-hidden'>
                        <div className='bg-green-100 w-full text-xs text-green-700 py-0.5 font-semibold px-4 capitalize'>
                          preview
                        </div>
                        <div className='p-2'>
                          <div className=''>
                            <p className='font-semibold capitalize px-2 border-b pb-1'>
                              {table?.name}
                            </p>
                            <div className='mt-3 grid grid-cols-1 md:grid-cols-2'>
                              {Array.from({
                                length: Math.max(
                                  ...table.columns.map((item) => item.sequence)
                                ),
                              }).map((_, i) => {
                                const item = table.columns.find(
                                  (column) => Number(column.sequence) === i + 1
                                );

                                return (
                                  <div key={i} className='p-2'>
                                    <div key={i + 1}>
                                      {item ? (
                                        <div
                                          className={`grid grid-cols-2 p-2 items-center`}
                                        >
                                          <>
                                            <span className='font-semibold  bg-green-100 px-2 p-1'>
                                              {item.title
                                                ? item.title
                                                : "Empty Title"}
                                            </span>
                                            <span
                                              className={`p-2 ${
                                                item.width === "100%"
                                                  ? "col-span-2"
                                                  : "col-span-1"
                                              }`}
                                              style={
                                                item.border === "yes"
                                                  ? { borderWidth: "1px" }
                                                  : {}
                                              }
                                            >
                                              {item.format}
                                            </span>
                                          </>
                                        </div>
                                      ) : (
                                        <div className=' bg-zinc-100 rounded-md py-2 '></div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {table.type === "Tabular" && (
                  <>
                    <TabularTableComponent
                      table={table}
                      tableIndex={tableIndex}
                      handleTableNameChange={handleTableNameChange}
                      handleChange={handleChange}
                      handleAddColumn={handleAddColumn}
                      handleDeleteTable={handleDeleteTable}
                      handleDeleteRow={handleDeleteRow}
                      PreviewHandler={PreviewHandler}
                      previewTableIds={previewTableIds}
                    />
                    {previewTableIds.includes(table.id) && (
                      <TabularTablePreview table={table} />
                    )}
                  </>
                )}
              </>
            ))}
          </div>

          {addfixedValues && (
            <div className='   flex items-center justify-center  absolute  top-0 backdrop-blur-sm z-10 h-full  w-full  '>
              <div className='bg-white rounded-lg p-4 shadow-lg w-1/2'>
                <div className='flex justify-between items-center'>
                  <div className=' text-zinc-600 font-semibold'>
                    Fixed Value
                  </div>
                  <button
                    onClick={() =>
                      handleAddfixedColumn(tableIndex, columnIndex)
                    }
                    className='p-1 px-2 border-2 text-sm  border-green-400 text-green-500 rounded-lg flex items-center gap-1'
                  >
                    <MdOutlineAdd className='text-lg' />
                    <span className=''>ADD</span>
                  </button>
                </div>
                <div>
                  <table class='w-full text-sm   text-gray-500 my-4  '>
                    <thead class='text-xs text-gray-700 uppercase bg-gray-50  '>
                      <tr>
                        <th scope='col' class='px-6 py-3 w-full'>
                          value
                        </th>
                        <th scope='col' class='px-6 py-3'>
                          remove
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tables[tableIndex]?.columns[columnIndex]?.fixed?.map(
                        (fix, fixedIndex) => {
                          return (
                            <tr key={fixedIndex} class='bg-white border-b  '>
                              <td class='px-5 py-4 font-medium text-black whitespace-nowrap '>
                                <Input
                                  placeholder='Sequence'
                                  className='w-full'
                                  value={fix} //fix.item
                                  onChange={(e) =>
                                    handleChangefix(
                                      tableIndex,
                                      columnIndex,
                                      e.target.value,
                                      fixedIndex
                                    )
                                  }
                                  name='value'
                                />
                              </td>
                              <td className=''>
                                <div className='flex justify-center items-center'>
                                  {/* {item?.id} */}
                                  <RiDeleteBin6Line
                                    className='text-xl text-red-500 cursor-pointer'
                                    onClick={() =>
                                      handleDeleteFixedRow(
                                        tableIndex,
                                        columnIndex,
                                        fixedIndex
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  {/* {fixedValues ? (
                    fixedValues.length > 0 && ( */}
                  <button
                    onClick={() => setAddfixedValues(false)}
                    className='bg-blue-100 w-full p-2 rounded-md mt-2 border border-blue-300 text-blue-600 font-semibold'
                  >
                    Done
                  </button>
                  {/* ) */}
                  {/* ) : ( */}
                  <button
                    onClick={() => setAddfixedValues(false)}
                    className='bg-red-100 w-full p-2 rounded-md mt-2 border border-red-300 text-red-600 font-semibold'
                  >
                    close
                  </button>
                  {/* )} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReportCustomize;

const Input = ({ placeholder, className, ...props }) => {
  return (
    <input
      placeholder={placeholder}
      className={`border border-zinc-200 outline-blue-500 p-2 rounded-md ${className}`}
      {...props}
    />
  );
};
