import React from "react";
import { useAppContext } from "../../context/appContext";

const ManegmentHome = () => {
  const {UserType} = useAppContext()
  return (
    <div className='p-5'>
      <div className='bg-white shadow-md rounded-lg p-5'>
        <h2 className='text-2xl font-bold mb-4 text-gray-800'>
          Dear {UserType?.firstName} {UserType?.lastName}
        </h2>
        <p className='mb-4 text-gray-600'>
          Please populate fields below to choose reports available for your
          review.
        </p>
        <p className='text-gray-600'>
          Kind Regards,
          <br />
          Maximal Security Services
        </p>
      </div>
    </div>
  );
};

export default ManegmentHome;
