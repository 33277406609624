import React, { useEffect, useState } from "react";
import TableLayout, { Td, Tr } from "../../components/tableLayout";
import { PixelStyle } from "../../components/layout";
import { GoDownload } from "react-icons/go";
import { get, ref, update } from "firebase/database";
import { database, storage } from "../../config/config";
import { useParams } from "react-router-dom";
import moment from "moment";
import { formattedDate } from "../../components/functions/formatDates";
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';


const ViewManagementReport = () => {
  const [reportData, setReportData] = useState({});
  const [reportTemplates, setReportTemplates] = useState([]);
  const { managereportId:reportId } = useParams();
  console.log("managereportId",reportId)
  const [reportName, setReportName] = useState("");
  const [tables, setTables] = useState([]);
  const [users, setUsers] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [companyLocationId, setCompanyLocationId] = useState("");
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const TableHead = ["Table", "Action"];
  const headerFieldMapping = {
    Table: "Table",
  };
  
  const generatedReportRef = ref(database, `generatedReport/${reportId}`);
  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const snapshot = await get(generatedReportRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setReportData(data);

          // Fetch report templates
          const reportTemplatesRef = ref(database, "reportTemplates");
          const reportTemplatesSnapshot = await get(reportTemplatesRef);
          if (reportTemplatesSnapshot.exists()) {
            const fetchData = reportTemplatesSnapshot.val();
            const templatesData = Object.values(fetchData);

            setReportTemplates(templatesData);

            // Fetch the specific report template based on the reportTemplatesId
            const reportTemplateRef = ref(
              database,
              `reportTemplates/${data.reportTemplatesId}`
            );
            const templateSnapshot = await get(reportTemplateRef);
            if (templateSnapshot.exists()) {
              const templateData = templateSnapshot.val();
              console.log("templateData", templateData);
              setReportName(templateData.name);
              setTables(templateData.tables || []);
              setCompanyName(templateData.companyName);
              setCompanyId(templateData.companyId);
              setCompanyLocation(templateData.companyLocation);
              setCompanyLocationId(templateData.companyLocationId);
              setStatus(templateData.status);
            } else {
              setErrorMessage("Report template not found");
            }
          }
        } else {
          console.log("No data available for this report");
        }
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    };

    fetchReportData();
  }, [reportId, users]);

  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };


  // console.log("tables data", tables);
  console.log("reportDAta", reportData);

  const [wantToSubmitToggle , setWantToSubmitToggle] = useState(false)


  const SubmitUpdateHandler = async () => {
    if(reportData?.isSubmitted === true){

      try {
        // const generatedReportRef = ref(database, `generatedReports/${reportId}`);
      await update(generatedReportRef, {
        isSubmittedByManagement: true,
      });
      console.log("Report updated successfully");
      setWantToSubmitToggle(false)
    } catch (error) {
      console.error("Error updating report:", error);
    }
  }
  }


  const [isEditable, setIsEditable] = useState(false)

  const [documentSubTable,setDocumentSubTable] = useState("")

  // const [tableData, setTableData] = useState(item?.table || []);

  // const handleInputChange = (e, contentIndex, tableDocumentIndex) => {
  //   const newValue = e.target.value;
  
  //   // Update the value in the tableData state
  //   const updatedTableData = [...tableData];
  //   updatedTableData[contentIndex].row[tableDocumentIndex].value = newValue;
  
  //   setTableData(updatedTableData);
  
  //   // Log the updated value
  //   console.log('Updated value:', newValue);
  // };

  const [handleCancel,setHandleCancel] = useState(false) 
  return (
    <div className={`w-full text-sm h-full ${PixelStyle}`}>

{wantToSubmitToggle &&<div className="flex justify-center items-center h-full">
  <div className="bg-white shadow-lg rounded-lg p-6 w-1/3">
    <h2 className="text-xl font-semibold text-center mb-4">Want to Submit</h2>
    <div className="flex justify-around">
      <button className="bg-green-500 text-white px-4 py-2" onClick={SubmitUpdateHandler}>Yes</button>
      <button className="bg-red-500 text-white px-4 py-2" onClick={() => setWantToSubmitToggle(false)}>No</button>
    </div>
  </div>
</div>}

      <div className='border bg-white rounded'>
        <div className='flex justify-between items-center w-full px-5 py-2'>
          <h4 className='text-2xl font-semibold'>Report Templates</h4>
          {/* <button className='text-zinc-500 p-2 rounded flex items-center gap-2'>
            <GoDownload className='text-2xl' />
          </button> */}


         {reportData?.isSubmittedByManagement? <div className='text-green-600 font-semibold'>Already Submitted</div> : <button className="bg-blue-500 text-white px-4 py-2 " onClick={()=>setWantToSubmitToggle(true)} >Want to submit</button>}
        </div>

<div className="p-10">


        <div>
          IsSubmitted :{" "}
          {reportData?.isSubmittedByManagement === true
            ? "submitted"
            : "not Submitted"}
        </div>
        <div>report name: {reportName}</div>
        <div>Company Name: {companyName}</div>
        <div>Location: {companyLocation}</div>
        <div>created At: { formattedDate(reportData?.createdAt)}</div>
        <div>updated At: {formattedDate(reportData?.updatedAt)}</div>
</div>

        <div className='grid gap-4 p-2'>
          {reportData &&
            reportData?.tables?.length > 0 &&
            reportData?.tables.map((item, index) => {
              return (
                <div
                  key={index}
                  className='border rounded-lg shadow-md p-4 bg-white relative'
                >
                  

                  {tables[index] && tables[index]?.type === "Document" ? (
                    <DocumentComponent 
  item={item}
  reportId={reportId}
  index={index}
  tables={tables}
/>
                    
//                     <div className='grid md:grid-cols-2 grid-cols-1'>
                    
//                     <div className='flex justify-end'>

// {!isEditable ? 
//                   <div className='border p-2 px-3 rounded bg-black text-white' onClick={() => setIsEditable(true)}>edit</div> :
//                   <div className='border p-2 px-3 rounded bg-black text-white' onClick={() => setHandleCancel(true)}>cancle</div>
//                   }
//                   </div>
                  

//                       {item.table[0].row.map(
//                         (tableDocumentData, tableDocumentIndex) => {
//                       // const filterTablebySequence = tables[index]?.columns[tableDocumentIndex].sequence === String(tableDocumentIndex+1) && tables[index]?.columns[tableDocumentIndex].width
                      
//                       const filterTablebySequence = tables[index]?.columns.filter((item,index)=> item?.sequence === String(tableDocumentIndex+1))
//                       // console.log("filterTablebySequence",filterTablebySequence[0]?.width)

//                       return (
//                             <div key={tableDocumentIndex} className={`p-2 ${filterTablebySequence[0]?.width === '100%' ? 'col-span-2 w-full' :""} `}>
//                               {tableDocumentData ? (
//                                 <DocumentPreview
//                                   item={item}
//                                   tableDocumentData={tableDocumentData}
//                                   tableDocumentIndex={tableDocumentIndex}
//                                   isEditable={isEditable}
//                                   setIsEditable={setIsEditable}
//                                   reportId={reportId}  index={index}
//                                   handleCancel={handleCancel} setHandleCancel={setHandleCancel}
//                                   // originalDocumentTableData={originalDocumentTableData} setOriginalDocumentTableData={setOriginalDocumentTableData} documentSubTable ={documentSubTable} setDocumentSubTable={setDocumentSubTable}
//                                 />
//                               ) : (
//                                 <div className=' bg-zinc-100 rounded-md py-2 '></div>
//                               )}
//                             </div>
//                           );
//                         }
//                       )}
//                     </div>
                  ) : tables[index]?.type === "Tabular" ? (
                    <div>
                      <p className='font-semibold capitalize px-2 border-b pb-1'>
                        {item?.title}
                      </p>
                      <table className='w-full text-sm text-left rtl:text-right capitalize border border-zinc-300'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-50 border border-zinc-300'>
                          <tr>
                            {tables[index]?.columns.map((item, index) => {
                              return (
                                <th
                                  scope='col'
                                  className='px-6 py-3 border border-zinc-300'
                                  key={index + 1}
                                  style={{
                                    width: `${item?.width}%`,
                                  }}
                                >
                                  
                                  {item?.title}
                                </th>
                              );
                            })}
                            <th>Action</th>
                          </tr>
                        </thead>

                        <TableComponent item={item} isEditable={isEditable}  setIsEditable={setIsEditable} reportId={reportId}  index={index}/>
{/* 
                        <tbody>
  {item?.table.map((content, contentIndex) => (
    <tr key={contentIndex}>
      {content?.row.map((tableTabularData, tableDocumentIndex) => (
        <td
          className='px-5 py-4 text-black border border-zinc-300'
          key={tableDocumentIndex + 1}
          style={{
            width: `${tableTabularData?.width}%`,
            height: `${tableTabularData?.height}px`
          }}
        >
          {tableTabularData?.format === 'Yes/No'
            ? tableTabularData?.value === 'false'
              ? "No"
              : "Yes"
            : tableTabularData?.format === 'fixed value'
            ? tableTabularData?.title
            : tableTabularData?.format === 'photo Upload' ||
              tableTabularData?.format === 'photo Capture'
            ? (
              <div className="flex flex-wrap">
                {tableTabularData?.value && tableTabularData?.value.length > 0 && tableTabularData?.value.map((imagesSrc, index) => (
                  <img src={imagesSrc} key={index} alt='img' />
                ))}
              </div>
            )
            : (
              <input
                value={tableTabularData?.value}
                type={tableTabularData?.format}
                onChange={(e) => handleInputChange(e, contentIndex, tableDocumentIndex)}
              />
            )
          }
        </td>
      ))}
    </tr>
  ))}
</tbody> */}


                        {/* <tbody>
                          {item?.table.map((content, contentIndex) => (
                            <tr key={contentIndex}>
                              {content?.row.map((tableTabularData, tableDocumentIndex) => (
                                <td
                                  className='px-5 py-4 text-black border border-zinc-300'
                                  key={tableDocumentIndex + 1}
                                  style={{
                                    width: `${tableTabularData?.width}%`,
                                    height: `${tableTabularData?.height}px`
                                  }}
                                >
                                  {tableTabularData?.format === 'Yes/No' ? tableTabularData?.value === 'false' ?"No" : "Yes" :   tableTabularData?.format === 'fixed value' ? (
                                    tableTabularData?.title
                                  ) : tableTabularData?.format === 'photo Upload' || tableTabularData?.format === 'photo Capture' ? (
                                    <div className="flex flex-wrap">
                                      {tableTabularData?.value && tableTabularData?.value.length > 0 && tableTabularData?.value.map((imagesSrc, index) => (
                                        <img src={imagesSrc} key={index} alt='img' />
                                      ))}
                                    </div>
                                  ) : (
                                    <input value={tableTabularData?.value} type={tableTabularData?.format} onChange={ChangeEditableHandler} />
                                    // tableTabularData?.value
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody> */}
                      </table>
                    </div>
                  ) : null}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ViewManagementReport;






export const DocumentComponent = ({ item, reportId, index ,tables}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [handleCancel, setHandleCancel] = useState(false);
  const [tableData, setTableData] = useState(item?.table || []);
  const [originalDocumentTableData, setOriginalDocumentTableData] = useState(item?.table || []);
  // const [tables, setTables] = useState([]); // Initialize with your tables data

  const generatedReportRef = ref(database, `generatedReport/${reportId}/tables/${index}`);

  const handleInputChange = (e, tableDocumentIndex) => {
    let newValue = e.target.value;
    if (e.target.type === 'date') {
      const [year, month, day] = newValue.split("-");
      newValue = `${month}/${day}/${year}`;
    }

    const updatedTableData = [...tableData];
    // Update the correct row in the table data
    updatedTableData[0].row[tableDocumentIndex].value = newValue;
    setTableData(updatedTableData);
  };

  const TableUpdateHandler = async () => {
    try {
      await update(generatedReportRef, {
        table: tableData
      });
      setOriginalDocumentTableData(tableData);
      setIsEditable(false);
    } catch (error) {
      console.error("Error updating report:", error);
    }
  };

  useEffect(() => {
    if (handleCancel === true) {
      setTableData(originalDocumentTableData);
      setIsEditable(false);
      setHandleCancel(false);
    }
  }, [handleCancel, originalDocumentTableData]);

  const formatDateToInput = (dateString) => {
    if (!dateString) return '';
    const parts = dateString.split("/");
    if (parts.length !== 3) return '';
    const [month, day, year] = parts;
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  const renderDocumentPreview = (tableDocumentData, tableDocumentIndex,filterTablebySequence) => {
    const widthFull = filterTablebySequence[0]?.width ==="100%"
    const HaveImage = tableDocumentData?.format === 'photo upload' || tableDocumentData?.format === 'photo capture'
    return (
      <div className={`grid  p-2 items-start ${widthFull ? "grid-cols-4" : "grid-cols-2"}  `}>
        <div>
        <div className="font-semibold bg-green-100 border border-green-100 px-2 p-1">
          {tableDocumentData.title || "Empty Title"}
        </div>
       {HaveImage && <div className='p-4'>  <div className='bg-blue-100 border mt-3 cursor-pointer py-10 border-blue-200 text-blue-700 flex justify-center items-center'>+ add image
        </div>
        </div>
        
        }
        </div>

        <div
          className={`p-1 ${widthFull ? "col-span-3" : ""}`}
          style={tableDocumentData.border === "yes" ? { borderWidth: "1px" } : {}}
        >
          {tableDocumentData.format === 'Yes/No' ? (
            <div>{tableDocumentData.value === 'false' ? "No" : "Yes"}</div>
          ) : HaveImage ? (
            <div className="flex flex-wrap w-full gap-2">
              {tableDocumentData.value && Array.isArray(tableDocumentData.value) && 
                tableDocumentData.value.map((imagesSrc, imgIndex) => (
                  <img src={imagesSrc} key={imgIndex} alt='img' className="max-w-[200px]" />
              ))}
            </div>
          ) : 
         <textarea
  value={tableDocumentData.format === 'date' 
    ? formatDateToInput(tableDocumentData.value) 
    : tableDocumentData.value || ''}
  onChange={(e) => handleInputChange(e, tableDocumentIndex)}
  type={tableDocumentData.format === 'date' ? 'date' : 'text'}
  className="w-full border border-gray-300 p-1 rounded"
  disabled={!isEditable}
  as="textarea"
  rows={1}
  style={{ whiteSpace: 'pre-wrap', resize: 'none', overflowWrap: 'break-word' }}
  onInput={(e) => {
    e.target.style.height = 'auto'; // Reset height first
    e.target.style.height = `${e.target.scrollHeight}px`; // Set height to the scroll height
  }}
/>

           }
          
          {/* : (
            <input
              value={tableDocumentData.format === 'date' 
                ? formatDateToInput(tableDocumentData.value) 
                : tableDocumentData.value || ''}
              onChange={(e) => handleInputChange(e, tableDocumentIndex)}
              type={tableDocumentData.format === 'date' ? 'date' : 'text'}
              className="w-full border border-gray-300 p-1 rounded"
              disabled={!isEditable}
            />
          )  } */}
        </div>
      </div>
    );
  };

  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
      <div className="flex justify-end col-span-full">
        {!isEditable ? (
          <button 
            className="border p-2 px-3 rounded bg-black text-white cursor-pointer"
            onClick={() => setIsEditable(true)}
          >
            Edit
          </button>
        ) : (
          <div className="flex gap-2">
            <button 
              className="border p-2 px-3 rounded bg-green-600 text-white cursor-pointer"
              onClick={TableUpdateHandler}
            >
              Save
            </button>
            <button 
              className="border p-2 px-3 rounded bg-red-600 text-white cursor-pointer"
              onClick={() => setHandleCancel(true)}
            >
              Cancel
            </button>
          </div>
        )}
      </div>

      {item.table[0].row.map((tableDocumentData, tableDocumentIndex) => {
        const filterTablebySequence = (tables && tables[index]?.columns?.filter(
          (item) => item?.sequence === String(tableDocumentIndex + 1)
        )) || [];

        console.log("filterTablebySequence",filterTablebySequence)
        return (
          <div
            key={tableDocumentIndex}
            className={`p-2 ${filterTablebySequence[0]?.width === "100%" ? "col-span-2" : "col-span-1"}`}
          >
            {tableDocumentData ? (
              renderDocumentPreview(tableDocumentData, tableDocumentIndex,filterTablebySequence)
            ) : (
              <div className="bg-zinc-100 rounded-md py-2" />
            )}
          </div>
        );
      })}
    </div>
  );
};



// import { ref, update } from 'firebase/database';
// import { database, storage } from './your-firebase-config';

export const TableComponent = ({ item, reportId, index }) => {
  const [tableData, setTableData] = useState(item?.table || []);
  const [isEditable, setIsEditable] = useState(false);
  const [originalData, setOriginalData] = useState(item?.table || []);
  const [uploading, setUploading] = useState(false);

  const handleInputChange = (e, contentIndex, tableTabularIndex) => {
    const newValue = e.target.value;
    const updatedTableData = [...tableData];
    updatedTableData[contentIndex].row[tableTabularIndex].value = newValue;
    setTableData(updatedTableData);
  };

  const handleEdit = () => {
    if (!isEditable) {
      // Store original data when entering edit mode
      setOriginalData([...tableData]);
    }
    setIsEditable(!isEditable);
  };

  const handleCancel = () => {
    // Restore original data when canceling
    setTableData([...originalData]);
    setIsEditable(false);
  };

  const handleImageUpload = async (e, contentIndex, tableTabularIndex) => {
    const files = Array.from(e.target.files);
    setUploading(true);

    try {
      const uploadPromises = files.map(async (file) => {
        // const imageRef = storageRef(storage, `reportImages/${reportId}/images/${file.name}`);
        const imageRef = storageRef(storage, `reportImages/${file.name}`);
        await uploadBytes(imageRef, file);
        return getDownloadURL(imageRef);
      });

      const imageUrls = await Promise.all(uploadPromises);

      const updatedTableData = [...tableData];
      
      if (!Array.isArray(updatedTableData[contentIndex].row[tableTabularIndex].value)) {
        updatedTableData[contentIndex].row[tableTabularIndex].value = [];
      }
      updatedTableData[contentIndex].row[tableTabularIndex].value = [
        ...updatedTableData[contentIndex].row[tableTabularIndex].value,
        ...imageUrls
      ];

      setTableData(updatedTableData);
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Error uploading images. Please try again.");
    } finally {
      setUploading(false);
    }
  };
  console.log("tableData tableData tableData",tableData)

  const handleRemoveImage = (contentIndex, tableTabularIndex, imageIndex) => {
    const updatedTableData = [...tableData];
    const images = updatedTableData[contentIndex].row[tableTabularIndex].value;
    images.splice(imageIndex, 1);
    setTableData(updatedTableData);
  };

  const generatedReportRef = ref(database, `generatedReport/${reportId}/tables/${index}`);

  const TableUpdateHandler = async () => {
    try {
      await update(generatedReportRef, {
        table: tableData
      });
      setOriginalData([...tableData]); // Update original data after successful save
      setIsEditable(false);
    } catch (error) {
      console.error("Error updating report:", error);
      alert("Error saving changes. Please try again.");
    }
  };

  const renderCell = (tableTabularData, contentIndex, tableTabularIndex) => {
    if (tableTabularData?.format === 'Yes/No') {
      return tableTabularData?.value === 'false' ? "No" : "Yes";
    }

    if (tableTabularData?.format === 'fixed value') {
      return tableTabularData?.title;
    }

    if (tableTabularData?.format === 'photo Upload' || tableTabularData?.format === 'photo Capture') {
      return (
        <div className="space-y-2">
          {isEditable && (
            <div className="flex items-center space-x-2">
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={(e) => handleImageUpload(e, contentIndex, tableTabularIndex)}
                className="hidden"
                id={`image-upload-${contentIndex}-${tableTabularIndex}`}
                disabled={uploading}
              />
              <label
                htmlFor={`image-upload-${contentIndex}-${tableTabularIndex}`}
                className="px-3 py-1 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600"
              >
                {uploading ? "Uploading..." : "Add Images"}
              </label>
            </div>
          )}
          <div className="flex flex-wrap gap-2">
            {tableTabularData?.value && Array.isArray(tableTabularData?.value) && 
              tableTabularData?.value.map((imagesSrc, imgIndex) => (
                <div key={imgIndex} className="relative group">
                  <img 
                    src={imagesSrc} 
                    alt={`uploaded-${imgIndex}`}
                    className="max-w-[100px] h-auto rounded"
                  />
                  {isEditable && (
                    <button
                      onClick={() => handleRemoveImage(contentIndex, tableTabularIndex, imgIndex)}
                      className="absolute top-0 right-0 bg-red-800 p-2 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      ×
                    </button>
                  )}
                </div>
              ))}
          </div>
        </div>
      );
    }

    return (
      <input
        value={tableTabularData?.value || ''}
        type={tableTabularData?.format || 'text'}
        onChange={(e) => handleInputChange(e, contentIndex, tableTabularIndex)}
        disabled={!isEditable}
        className={`w-full p-2 rounded ${isEditable ? 'border border-gray-300' : 'bg-transparent'}`}
      />
    );
  };

  return (
    <tbody>
      {tableData.map((content, contentIndex) => (
        <tr key={contentIndex}>
          {content?.row.map((tableTabularData, tableTabularIndex) => (
            <td
              className={`px-5 py-4 text-black border border-zinc-300 ${
                isEditable ? "bg-zinc-50" : ""
              }`}
              key={tableTabularIndex + 1}
              style={{
                width: `${tableTabularData?.width}%`,
                height: `${tableTabularData?.height}px`
              }}
            >
              {renderCell(tableTabularData, contentIndex, tableTabularIndex)}
            </td>
          ))}
          <td className="px-4 py-2 border border-zinc-300">
            <div className="flex gap-2">
              <button
                onClick={isEditable ? handleCancel : handleEdit}
                className={`px-3 py-1 rounded ${
                  isEditable
                    ? "bg-gray-500 hover:bg-gray-600"
                    : "bg-blue-500 hover:bg-blue-600"
                } text-white transition-colors`}
              >
                {isEditable ? "Cancel" : "Edit"}
              </button>
              {isEditable && (
                <button
                  onClick={TableUpdateHandler}
                  className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                >
                  Save
                </button>
              )}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};







// const DocumentPreview = ({ item, tableDocumentData, tableDocumentIndex,reportId,handleCancel,setHandleCancel, index, isEditable,setIsEditable,documentSubTable,setDocumentSubTable}) => {
//   const [tableData, setTableData] = useState(item?.table || []);
//   const [originalDocumentTableData, setOriginalDocumentTableData] = useState(item?.table || []);
  

//   const generatedReportRef = ref(database, `generatedReport/${reportId}/tables/${index}`);

//   const handleInputChange = (e, contentIndex, tableDocumentIndex) => {
//     let newValue = e.target.value;
//     // Check if the format is date and convert it to "MM/DD/YYYY"
//     if (e.target.type === 'date') {
//       const [year, month, day] = newValue.split("-");
//       newValue = `${month}/${day}/${year}`;
//     }

//     // Update the value in the tableData state
//     const updatedTableData = [...tableData];
//     updatedTableData[contentIndex].row[tableDocumentIndex].value = newValue;

//     setTableData(updatedTableData);
//   };

//   const TableUpdateHandler = async () => {
//     try {
//       // Update the 'tables' data in the database
//       await update(generatedReportRef, {
//         table: tableData
//       });

//       console.log("Report updated successfully");
//       setOriginalDocumentTableData(tableData);
//       setIsEditable(false);
//     } catch (error) {
//       console.error("Error updating report:", error);
//     }
//   };

//   // console.log("handleCancel",handleCancel)
// useEffect(() => {
//   if (handleCancel === true) {
//     setTableData(originalDocumentTableData);
//     setIsEditable(false);
//     setHandleCancel(false);
//     console.log("setIsEditable");
//   }
// }, [handleCancel]);

//   console.log("table", tableData);

//   const formatDateToInput = (dateString) => {
//     const [month, day, year] = dateString.split("/");
//     return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
//   };

//   return (
//     <div className={`grid grid-cols-2 p-2 items-start`}>
//       <>
//         <div className='font-semibold bg-green-100 border border-green-100 px-2 p-1'>
//           {tableDocumentData.title ? tableDocumentData.title : "Empty Title"}
//         </div>
//         <div
//           className={`p-1 ${tableDocumentData.width === "100%" ? "col-span-2" : "col-span-1"}`}
//           style={tableDocumentData.border === "yes" ? { borderWidth: "1px" } : {}}
//         >
//           {tableDocumentData?.format === 'Yes/No' ? tableDocumentData?.value === 'false' ? "No" : "Yes" :
//             tableDocumentData?.format === 'photo upload' || tableDocumentData?.format === 'photo capture' ? (
//               <div className="flex flex-wrap w-full gap-2">
//                 {tableDocumentData?.value && tableDocumentData?.value.length > 0 && tableDocumentData?.value.map((imagesSrc, index) => (
//                   <img src={imagesSrc} key={index} alt='img' />
//                 ))}
//               </div>
//             ) :
//             <input
//               value={tableDocumentData?.format === 'date' ? formatDateToInput(tableDocumentData?.value) : tableDocumentData?.value}
//               onChange={(e) => handleInputChange(e, index, tableDocumentIndex)}
//               type={tableDocumentData.format}
//               className="border border-gray-300 p-1 rounded"
//               disabled={!isEditable}
//             />
//           }
//         </div>
//       </>
//     </div>
//   );
// };



// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------



// const TableComponent = ({ item ,reportId,index}) => {
//   // Initialize the state with the table data
//   const [tableData, setTableData] = useState(item?.table || []);
//   const [isEditable,setIsEditable] = useState(false)

//   const handleInputChange = (e, contentIndex, tableTabularIndex) => {
//     const newValue = e.target.value;

//     // Update the value in the tableData state
//     const updatedTableData = [...tableData];
//     updatedTableData[contentIndex].row[tableTabularIndex].value = newValue;

//     setTableData(updatedTableData);
//   };

//   console.log("index",index)
  
//   const generatedReportRef = ref(database, `generatedReport/${reportId}/tables/${index}`);
  
//   const TableUpdateHandler = async () => {
    
//     try {
//       await update(generatedReportRef, {
//         table: tableData
//       });
  
//       console.log("Report updated successfully");
//       setIsEditable(false);
//     } catch (error) {
//       console.error("Error updating report:", error);
//     }
//   };
  

//   // console.log("table",tableData)


//   return (
//     <tbody>
//       {tableData.map((content, contentIndex) => (
//         <tr key={contentIndex}>
//           {content?.row.map((tableTabularData, tableTabularIndex) => (
//             <td
//               className={`px-5 py-4 text-black border border-zinc-300 ${isEditable ? "bg-zinc-100" : ""}`}
//               key={tableTabularIndex + 1}
//               style={{
//                 width: `${tableTabularData?.width}%`,
//                 height: `${tableTabularData?.height}px`
//               }}
//             >
//               {tableTabularData?.format === 'Yes/No'
//                 ? tableTabularData?.value === 'false'
//                   ? "No"
//                   : "Yes"
//                 : tableTabularData?.format === 'fixed value'
//                 ? tableTabularData?.title
//                 : tableTabularData?.format === 'photo Upload' || tableTabularData?.format === 'photo Capture'
//                 ? (
//                   <div className="flex flex-wrap">
//                     {tableTabularData?.value && tableTabularData?.value.length > 0 && tableTabularData?.value.map((imagesSrc, index) => (
//                       <img src={imagesSrc} key={index} alt='img' />
//                     ))}
//                   </div>
//                 ) : (
//                   <input
//                     value={tableTabularData?.value}
//                     type={tableTabularData?.format}
//                     onChange={(e) => handleInputChange(e, contentIndex, tableTabularIndex)}
//                     disabled={!isEditable} // Disable input if isEditable is false
//                   />
//                 )
//               }
//             </td>
//           ))}
//           <td>
//             <button onClick={() => setIsEditable(!isEditable)}>{isEditable ? "Cancel" : "Edit"}</button>
//             <button onClick={TableUpdateHandler}>{isEditable && "Done"}</button>
//           </td>
//         </tr>
//       ))}
//     </tbody>
//   );
// };
  
 