import { LuEye, LuEyeOff } from "react-icons/lu";
import { MdOutlineAdd } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Input } from "../../components/InputTemplate";

// ------- TABULAR TABLE COMPONENTS ---------

export const TableInputField = ({
  placeholder,
  value,
  onChange,
  type,
  className,
}) => {
  return (
    <input
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      type={type}
      className={`bg-transparent outline-none ${className}`}
    />
  );
};
export const TableActionButtons = ({ onPreview, onDelete, isPreviewOpen }) => {
  return (
    <div className='flex items-center gap-3'>
      <button
        className={`flex items-center gap-2 border p-1.5 px-4 rounded-md ${
          isPreviewOpen ? "bg-blue-50 border-blue-300" : ""
        }`}
        onClick={onPreview}
      >
        {isPreviewOpen ? <LuEye /> : <LuEyeOff />}
        <span className='text-xs'> Preview</span>
      </button>

      <button
        onClick={onDelete}
        className='text-zinc-600 hover:text-red-400 border border-zinc-200 hover:border-red-200 p-1.5 rounded-lg'
      >
        <RiDeleteBin6Line />
      </button>
    </div>
  );
};

// ------TABLE PREVIEW

export const TabularTablePreview = ({ table }) => {
  const columns = table.columns || []; // Ensure columns is an array
  console.log("===>", columns);
  const maxSequence =
    columns.length > 0
      ? Math.max(...columns.map((item) => Number(item?.sequence) || 0))
      : 0;

  const maxLength =
    columns.length > 0
      ? Math.max(...columns.map((col) => (col.fixed ? col.fixed.length : 0)))
      : 0;
  return (
    <div className=' bg-white shadow rounded-md overflow-hidden'>
      <div className='bg-blue-100 w-full text-xs text-blue-700 py-0.5 font-semibold px-4 capitalize'>
        preview
      </div>
      <div className='p-2'>
        <div className=''>
          <p className='font-semibold capitalize px-2 border-b pb-1'>
            {table?.name}
          </p>

          <div className='mt-3'>
            <table className='w-full text-sm text-left rtl:text-right capitalize border border-zinc-300'>
              <thead className='text-xs text-gray-700 uppercase bg-gray-50 border border-zinc-300'>
                <tr>
                  {Array.from({ length: maxSequence }).map((_, i) => {
                    const item = columns.find(
                      (column) => Number(column.sequence) === i + 1
                    );
                    return (
                      <th
                        scope='col'
                        className='px-6 py-3 border border-zinc-300'
                        key={i + 1}
                        style={{
                          width: `${item?.width}%`,
                        }}
                      >
                        {item?.title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: maxLength }).map((_, rowIndex) => (
                  <tr key={rowIndex} className='bg-white'>
                    {Array.from({
                      length: maxSequence,
                    }).map((_, i) => {
                      const item = table.columns.find(
                        (column) => Number(column?.sequence) === i + 1
                      );
                      return (
                        <td
                          key={i + 1}
                          className='px-5 py-4 font-medium text-black whitespace-nowrap border border-zinc-300'
                        >
                          {item?.fixed && item.fixed[rowIndex] !== undefined
                            ? item.fixed[rowIndex]
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TabularTableComponent = ({
  table,
  handleTableNameChange,
  handleChange,
  handleDeleteRow,
  handleAddColumn,
  handleDeleteTable,
  tableIndex,
  PreviewHandler,
  previewTableIds,
}) => {
  console.log("table.name", table.name);
  return (
    <div className='bg-white shadow rounded-md overflow-hidden'>
      <div className='bg-blue-100 w-full text-xs text-blue-700 py-0.5 font-semibold px-4 capitalize'>
        Tabular table
      </div>
      <div className='p-4 w-full flex flex-col gap-5 relative'>
        <div className='flex justify-between'>
          <div className='flex flex-col items-start'>
            <input
              placeholder='Table Name'
              value={table.name}
              onChange={(e) =>
                handleTableNameChange(tableIndex, e.target.value)
              }
              className='bg-transparent outline-none text-2xl font-bold  text-zinc-600'
            />
            {table.name === "" && (
              <span className='border-blue-500 border rounded-full px-5 text-xs text-blue-500'>
                Editable
              </span>
            )}
          </div>
          <TableActionButtons
            onPreview={() => PreviewHandler(table.id)}
            onDelete={() => handleDeleteTable(tableIndex)}
            isPreviewOpen={previewTableIds.includes(table.id)}
          />
        </div>

        <div className='relative overflow-x-auto pb-8'>
          <table className='w-full text-sm text-left capitalize'>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
              <tr>
                <th className='px-6 py-3'>Sequence</th>
                <th className='px-6 py-3'>Title</th>
                <th className='px-6 py-3'>Width (%)</th>
                <th className='px-6 py-3'>Specific Format</th>
                <th className='px-6 py-3'>Border</th>
                <th className='px-6 py-3'>
                  <button
                    onClick={() => handleAddColumn(tableIndex)}
                    className='p-1 px-2 border-2 border-blue-400 text-blue-500 rounded-lg flex items-center gap-1'
                  >
                    <MdOutlineAdd className='text-lg' />
                    <span>ADD</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {table.columns.map((column, columnIndex) => (
                <tr class='bg-white border-b  '>
                  <th
                    scope='row'
                    class='px-5 py-4 font-medium text-black whitespace-nowrap '
                  >
                    <Input
                      placeholder='Sequence'
                      className='w-20'
                      type='number'
                      value={column.sequence}
                      onChange={(e) =>
                        handleChange(
                          tableIndex,
                          columnIndex,
                          "sequence",
                          e.target.value
                        )
                      }
                      name='sequence'
                    />
                  </th>
                  <td class='px-5 py-4'>
                    <Input
                      placeholder='Title'
                      type='text'
                      value={column.title}
                      onChange={(e) =>
                        handleChange(
                          tableIndex,
                          columnIndex,
                          "title",
                          e.target.value
                        )
                      }
                      name='title'
                    />
                  </td>

                  <td class='px-5 py-4'>
                    <Input
                      className='w-20'
                      placeholder='width'
                      value={column.width}
                      onChange={(e) =>
                        e.target.value <= 100 &&
                        handleChange(
                          tableIndex,
                          columnIndex,
                          "width",
                          e.target.value
                        )
                      }
                      name='width'
                    />
                  </td>
                  <td class='px-5 py-4'>
                    {/* <Input placeholder='Format ' /> */}
                    <select
                      className='px-2 border p-2 rounded-md capitalize'
                      value={column.format}
                      onChange={(e) =>
                        handleChange(
                          tableIndex,
                          columnIndex,
                          "format",
                          e.target.value
                        )
                      }
                      name='format'
                    >
                      <option value='input'>Text</option>
                      <option value='number'>Number</option>
                      <option value='date'>Date</option>
                      <option value='time'>Time</option>
                      <option value='textarea'>Long Text</option>
                      <option value='fixed value'>Fixed Value</option>
                      <option value='Yes/No'>Yes/No</option>
                      <option value='photo Upload'>Photo Upload</option>
                      <option value='photo Capture'>Photo Capture</option>
                      <option value='F&C'>F&C</option>
                    </select>
                  </td>
                  <td class='px-6 py-4'>
                    <select
                      className='px-2 border p-2 rounded-md '
                      value={column.border}
                      onChange={(e) =>
                        handleChange(
                          tableIndex,
                          columnIndex,
                          "border",
                          e.target.value
                        )
                      }
                      name='border'
                    >
                      <option>no</option>
                      <option>yes</option>
                    </select>
                  </td>
                  <td className=''>
                    <div className='flex justify-center items-center'>
                      {/* {item?.id} */}
                      <RiDeleteBin6Line
                        className='text-xl text-red-500 cursor-pointer'
                        onClick={() => handleDeleteRow(tableIndex, columnIndex)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
