import React from "react";
import { logo, miniLogo } from "../assets";
import { Link } from "react-router-dom";

const LoginLayout = ({
  error,
  loading,
  onSubmit,
  children,
  Title,
  registerLink,
  loginLink,
  layoutType,
}) => {
  return (
    <div className='flex  justify-center w-full h-screen p-2 relative '>
      {/* <div className='fixed top-4 left-4'>
        <img src={miniLogo} alt='' className='h-10 md:h-20' />
      </div> */}
      <div className='flex w-full'>
        <div
          // style={{ backgroundImage: `url(${login})` }}
          className='h-full  hidden  md:flex items-center -mt-20 justify-center w-[70%] '
          // className='bg-blue-500 h-full w-3/5 bg-cover bg-center bg-no-repeat overflow-hidden rounded-xl flex justify-center items-center'
        >
          <img src={logo} alt='' className=' h-[65%]' />
          {/* <p className='text-3xl text-white'>Updating soon...</p> */}
        </div>
        <LoginForm
          error={error}
          loading={loading}
          onSubmit={onSubmit}
          Title={Title}
          registerLink={registerLink}
          loginLink={loginLink}
          layoutType={layoutType}
          className='w-[30%]'
        >
          {children}
        </LoginForm>
      </div>
    </div>
  );
};

export default LoginLayout;

export const Input = ({
  value,
  onChange,
  placeholder,
  className,
  type,
  ...props
}) => (
  <input
    type={type ? type : "text"}
    className={`  p-2 text-sm md:text-xl outline-none border border-zinc-400 rounded-md ${className}`}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    {...props}
  />
);

const LoginForm = ({
  error,
  loading,
  onSubmit,
  children,
  Title,
  registerLink,
  loginLink,
  layoutType,
  className,
}) => {
  return (
    <div
      className={` flex md:p-0 flex-col justify-center  md:w-auto items-center ${className} `}
    >
      {error && (
        <div
          className='text-red-700 bg-red-100 rounded px-3 py-0.5 my-3 font-semibold text-sm '
          role='alert'
        >
          {error}
        </div>
      )}
      <div className=' rounded-lg border p-5 w-full'>
        <div className='text-2xl md:text-4xl font-bold text-zinc-700 text-center'>
          {Title}
        </div>
        <form onSubmit={onSubmit} className='md:p-10 pt-5 w-full'>
          <div className='flex flex-col gap-4'>
            {children}

            <div className='flex flex-col'>
              <button
                type='submit'
                className='bg-blue-700 mt-8 text-white px-10 py-2 rounded-lg capitalize'
              >
                {loading ? "loading..." : layoutType}
              </button>
              <div className='flex justify-end'>
                {registerLink && (
                  <div className='flex justify-center gap-1 text-sm md:text-base '>
                    Forgot Password
                    <span className='text-blue-800 font-medium '>
                      <Link to={registerLink}>
                        <span>Reset here </span>
                      </Link>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
        {registerLink && (
          <div className='flex justify-center gap-1 text-sm md:text-base '>
            Need an account's
            <span className='text-blue-800 font-medium '>
              <Link to={registerLink}>
                <span>Sign up here </span>
              </Link>
            </span>
          </div>
        )}
        {loginLink && (
          <div className='flex justify-center gap-1 text-sm md:text-base '>
            Already have an account
            <span className='text-blue-800 font-medium '>
              <Link to={loginLink}>Log In</Link>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
