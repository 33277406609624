import logo from "./logo.svg";
import "./App.css";
import Layout from "./components/layout";
import Routers from "./routes";
import 'react-toastify/dist/ReactToastify.css';



function App() {


  return <Routers />;
}

export default App;
