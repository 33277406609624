import React, { useState } from "react";
import ShowOnTop, { Form_Container } from "./showOnTop";
import { Input } from "./InputTemplate";
import { ref, update } from "firebase/database";
import { database } from "../config/config";

const EditUserListComponents = ({
  setEditList,
  editComponents,
  setUsers,
  users,
  containCompany,
}) => {
  const [inputValue, setInputValue] = useState(editComponents || {});

  const InputHandler = (e) => {
    const { name, value } = e.target;
    setInputValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const EditUserList = () => {
    const usersRef = ref(database, `users/${editComponents?.id}`);
    const updatedReportTemplate = {
      city: inputValue?.city || "",
      email: inputValue?.email || "",
      firstName: inputValue?.firstName || "",
      lastName: inputValue?.lastName || "",
      state: inputValue?.state || "",
      streetAddress: inputValue?.streetAddress || "",
      type: inputValue?.type || "",
      zipCode: inputValue?.zipCode || "",
      phone: inputValue?.phone || "",
      // Only add 'company' if containCompany is true
      ...(containCompany && { company: inputValue?.company || null }),
    };

    console.log(updatedReportTemplate);

    update(usersRef, updatedReportTemplate)
      .then(() => {
        const newUser = users.filter((item) => item?.id !== editComponents?.id);
        const CombineUser = [
          ...newUser,
          { id: editComponents?.id, ...updatedReportTemplate },
        ];

        console.log("Combined User List", CombineUser);
        // Assuming setUsers is a state setter for the user list
        setUsers(CombineUser);
        setEditList(false);
        console.log("Update Success");
      })
      .catch((error) => {
        console.error("Update error", error);
        // Handle error
      });
  };

  console.log("inputValue", inputValue?.phone);

  return (
    <ShowOnTop setEditList={setEditList}>
      <Form_Container>
        <div className='flex items-center justify-between mb-7 '>
          <h1 className='capitalize text-2xl font-semibold'>
          Edit User Information
          </h1>
          <div className='bg-blue-100 px-4 py-1 border border-blue-300 text-blue-700 text-sm rounded-md'>
            {editComponents?.type}
          </div>
        </div>
        <div className='px-5 flex flex-col gap-3'>
          <div className='flex gap-3'>
            <Input
              name='firstName'
              placeholder='first name'
              className='border'
              onChange={InputHandler}
              value={inputValue?.firstName}
            />
            <Input
              name='lastName'
              placeholder='last name'
              className='border'
              onChange={InputHandler}
              value={inputValue?.lastName}
            />
          </div>

          {/* <Input
            name='email'
            placeholder='email'
            className='border w-full text-zinc-600'
            // onChange={InputHandler}
            value={inputValue?.email}
disabled
          /> */}
          <div className='flex gap-3'>
            <Input
              name='phone'
              placeholder='phone'
              className='border'
              onChange={InputHandler}
              value={inputValue?.phone}
            />
            <Input
              name='state'
              placeholder='state'
              className='border'
              onChange={InputHandler}
              value={inputValue?.state}
            />
          </div>
          <div className='flex gap-3'>
            <Input
              name='streetAddress'
              placeholder='street address'
              className='border'
              onChange={InputHandler}
              value={inputValue?.streetAddress}
            />
            <Input
              name='city'
              placeholder='city'
              className='border'
              onChange={InputHandler}
              value={inputValue?.city}
            />
          </div>
          {containCompany && (
            <Input
              name='company'
              placeholder='company'
              className='border'
              onChange={InputHandler}
              value={inputValue?.company}
            />
          )}
          <Input
            name='zipCode'
            placeholder='zip code'
            className='border'
            onChange={InputHandler}
            value={inputValue?.zipCode}
          />
          <div className='mt-7 flex justify-end'>
            <button
              className='bg-blue-600 text-white p-2 px-6 rounded-md'
              onClick={EditUserList}
            >
              Update
            </button>
          </div>
        </div>
      </Form_Container>
    </ShowOnTop>
  );
};

export default EditUserListComponents;
