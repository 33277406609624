import React, { useEffect, useState, createContext, useContext } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Home from "../pages/admin/home";
import ViewReport from "../pages/admin/viewreport";
import UserList from "../pages/admin/userList";
import CreateReport from "../pages/admin/createReport";
import Assignreport from "../pages/admin/assignreport";
import AdminLogin from "../pages/admin/adminLogin";
import Register from "../pages/admin/Register";
import SecurityWatch from "../pages/admin/securityWatch";
import ClientList from "../pages/admin/clientList";
import ClientHours from "../pages/admin/clientHours";
import Invoicing from "../pages/admin/invoicing";
import ReportCustomize from "../pages/admin/reportCustomize";
import CostumizeAssignreport from "../pages/admin/costumizeAssignreport";
import AssignPermission from "../pages/admin/assignPermission";
import Geolocationsetup from "../pages/admin/geolocationsetup";
import Timeclocksetup from "../pages/admin/timeclocksetup";
import Setupnewclient from "../pages/admin/setupnewclient";
import Modifyclient from "../pages/admin/modifyclient";
import Generateinvoice from "../pages/admin/generateinvoice";
import Viewopeninvoices from "../pages/admin/viewopeninvoices";
import Viewpaidinvoices from "../pages/admin/viewpaidinvoices";
import Invoicestatement from "../pages/admin/invoicestatement";
import DeleteInvoice from "../pages/admin/deleteInvoice";
import Managementlist from "../pages/admin/managementlist";
import SecurityList from "../pages/admin/securityList";
import UserListclient from "../pages/admin/userList.client";
import ProtectedRoute from "../config/protectedRoute";
import { auth } from "../config/config";
import Layout from "../components/layout";
import { useAppContext } from "../context/appContext";
import ClientLogin from "../pages/clientPortal/clientLogin";
import DummyPage from "../components/dummyPage";
import ClientHome from "../pages/clientPortal/clientHome";
import SecurityLogin from "../pages/management/ManagementLogin";
import SecurityHome from "../pages/management/manegmentHome";
import ClientReport from "../pages/clientPortal/clientReport";
import ManagementLogin from "../pages/management/ManagementLogin";
import ManegmentHome from "../pages/management/manegmentHome";
import ManagementReport from "../pages/management/managementReport";
import AdminRegister from "../pages/admin/Register";
import UserPermission from "../config/userPermission";
import ViewManagementReport from "../pages/management/viewManagementReport";

// Create a context to manage unsaved changes
const UnsavedChangesContext = createContext();

export const useUnsavedChanges = () => {
  return useContext(UnsavedChangesContext);
};

const Routers = () => {
  const { UserType } = useAppContext();
  const [currentUser, setCurrentUser] = useState(null);
  const [isChangesInCreateReport, setIsChangesInCreateReport] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.title = "Admin Portal - Maximal Security";
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // This effect checks sfsd if the user is trying to navigate away from CreateReport

  const pathName = location.pathname;
  // console.log("PathName", PathName);

  // useEffect(() => {
  //   const hasReloaded = sessionStorage.getItem("hasReloaded");
  //   if ((UserType === null || UserType === undefined) && !hasReloaded) {
  //     sessionStorage.setItem("hasReloaded", "true");
  //     window.location.reload();
  //     setTimeout(() => {
  //       sessionStorage.setItem("hasReloaded", "false");
  //     }, 3000);
  //   }
  // }, [UserType]);

  // useEffect(() => {
  //   if (UserType?.type === "Admin" && UserType?.verified === true) {
  //     navigate("/home");
  //   }else{
      
  //     navigate("/");
  //     localStorage.removeItem("maxUser");
  //   }
  //   //console
  // }, [UserType]);


  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const User = JSON.parse(localStorage.getItem("maxUser"));


  const Protecter = ({element}) => {
    
    if (User?.type === "Admin" && User?.verified === true) {
      setIsAuthenticated(true)
    }else{
    setIsAuthenticated(false)
    navigate("/");
    localStorage.removeItem("maxUser");
    console.warn("Not Authenticated")
  }
return isAuthenticated ? element : null
}
console.log("UserType",UserType)

  return (
    <UnsavedChangesContext.Provider
      value={{ isChangesInCreateReport, setIsChangesInCreateReport }}
    >
      <Layout>
        <Routes>
          {/* Public routes */}
          {/* <Route path='/' element={<DummyPage />} /> */}
          {/* management login */}
          <Route path='/' element={<AdminLogin />} />
          <Route path='/register' element={<AdminRegister />} />

          {/* Protected routes */}
          <Route
            path='*'
            element={
              <ProtectedRoute>
                <Routes>
                  {/* Admin routes */}

                  <Route path='/home' element={<Protecter element={<Home />} />} />
                  <Route path='/viewreport' element={<ViewReport />} />
                  <Route
                    path='/viewreport/:reportTempId'
                    element={<ReportCustomize />}
                  />
                  <Route path='/createreport' element={<CreateReport />} />
                  <Route
                    path='/createreport/:reportTempId'
                    element={<CreateReport />}
                  />
                  <Route path='/assignreport' element={<Assignreport />} />
                  <Route
                    path='/assignreport/:assignreportId'
                    element={<CostumizeAssignreport />}
                  />
                  <Route path='/securitywatch' element={<SecurityWatch />} />
                  <Route path='/clientlist' element={<ClientList />} />
                  <Route path='/clienthours' element={<ClientHours />} />
                  <Route path='/invoicing' element={<Invoicing />} />
                  <Route
                    path='/assignpermissions'
                    element={<AssignPermission />}
                  />
                  <Route
                    path='/geolocationsetup'
                    element={<Geolocationsetup />}
                  />
                  <Route path='/timeclocksetup' element={<Timeclocksetup />} />
                  <Route path='/setupnewclient' element={<Setupnewclient />} />
                  <Route
                    path='/modifyclient'
                    element={
                      <UserPermission role='Admin' element={<Modifyclient />} />
                    }
                  />
                  <Route
                    path='/generateinvoice'
                    element={<Generateinvoice />}
                  />
                  <Route
                    path='/viewopeninvoices'
                    element={<Viewopeninvoices />}
                  />
                  <Route
                    path='/viewpaidinvoices'
                    element={<Viewpaidinvoices />}
                  />
                  <Route
                    path='/invoicestatement'
                    element={<Invoicestatement />}
                  />
                  <Route path='/deleteInvoice' element={<DeleteInvoice />} />
                  <Route path='/userclient' element={<UserListclient />} />
                  <Route
                    path='/security'
                    element={
                      <UserPermission role='Admin' element={<SecurityList />} />
                    }
                  />
                  <Route path='/managements' element={<Managementlist />} />

                  {/* Client routes */}
                  <Route
                    path='/client-home'
                    element={
                      <UserPermission role='Client' element={<ClientHome />} />
                    }
                  />
                  <Route
                    path='/client-report'
                    element={
                      <UserPermission
                        role='Client'
                        element={<ClientReport />}
                      />
                    }
                  />

                  {/* Security routes */}
                  <Route
                    path='/management-home'
                    element={
                      <UserPermission
                        role='Management'
                        element={<ManegmentHome />}
                      />
                    }
                  />
                  <Route
                    path='/management-report'
                    element={
                      <UserPermission
                        role='Management'
                        element={<ManagementReport />}
                      />
                    }
                  />
                  <Route
                    path='/view-management-report/:reportId'
                    element={
                      <UserPermission
                        role='Management'
                        element={<ViewManagementReport />}
                      />
                    }
                  />
                </Routes>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Layout>
    </UnsavedChangesContext.Provider>
  );
};

export default Routers;

// import React, { useEffect, useState, createContext, useContext } from "react";
// import {
//   Navigate,
//   Route,
//   Routes,
//   useNavigate,
//   useLocation,
// } from "react-router-dom";
// import Home from "../pages/admin/home";
// import ViewReport from "../pages/admin/viewreport";
// import UserList from "../pages/admin/userList";
// import CreateReport from "../pages/admin/createReport";
// import Assignreport from "../pages/admin/assignreport";
// import AdminLogin from "../pages/admin/adminLogin";
// import Register from "../pages/admin/Register";
// import SecurityWatch from "../pages/admin/securityWatch";
// import ClientList from "../pages/admin/clientList";
// import ClientHours from "../pages/admin/clientHours";
// import Invoicing from "../pages/admin/invoicing";
// import ReportCustomize from "../pages/admin/reportCustomize";
// import CostumizeAssignreport from "../pages/admin/costumizeAssignreport";
// import AssignPermission from "../pages/admin/assignPermission";
// import Geolocationsetup from "../pages/admin/geolocationsetup";
// import Timeclocksetup from "../pages/admin/timeclocksetup";
// import Setupnewclient from "../pages/admin/setupnewclient";
// import Modifyclient from "../pages/admin/modifyclient";
// import Generateinvoice from "../pages/admin/generateinvoice";
// import Viewopeninvoices from "../pages/admin/viewopeninvoices";
// import Viewpaidinvoices from "../pages/admin/viewpaidinvoices";
// import Invoicestatement from "../pages/admin/invoicestatement";
// import DeleteInvoice from "../pages/admin/deleteInvoice";
// import Managementlist from "../pages/admin/managementlist";
// import SecurityList from "../pages/admin/securityList";
// import UserListclient from "../pages/admin/userList.client";
// import ProtectedRoute from "../config/protectedRoute";
// import { auth } from "../config/config";
// import Layout from "../components/layout";
// import { useAppContext } from "../context/appContext";
// import ClientLogin from "../pages/clientPortal/clientLogin";
// import DummyPage from "../components/dummyPage";
// import ClientHome from "../pages/clientPortal/clientHome";
// import SecurityLogin from "../pages/management/ManagementLogin";
// import SecurityHome from "../pages/management/manegmentHome";
// import ClientReport from "../pages/clientPortal/clientReport";
// import ViewClientReport from "../pages/clientPortal/viewClientReport";
// import ManagementLogin from "../pages/management/ManagementLogin";
// import ManegmentHome from "../pages/management/manegmentHome";
// import ManagementReport from "../pages/management/managementReport";
// import AdminRegister from "../pages/admin/Register";
// import UserPermission from "../config/userPermission";
// import ViewManagementReport from "../pages/management/viewManagementReport";

// // Create a context to manage unsaved changes
// const UnsavedChangesContext = createContext();

// export const useUnsavedChanges = () => {
//   return useContext(UnsavedChangesContext);
// };

// const Routers = () => {
//   const { UserType } = useAppContext();
//   const [currentUser, setCurrentUser] = useState(null);
//   const [isChangesInCreateReport, setIsChangesInCreateReport] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();

//   // Auth state monitoring
//   useEffect(() => {
//     document.title = "Admin Portal - Maximal Security";
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       setCurrentUser(user || null);
//     });
//     return () => unsubscribe();
//   }, []);

//   useEffect(() => {
//     if (UserType) {
//       // Only proceed if UserType is available
//       // const redirectPaths = {
//       //   Management: '/managementhome',
//       //   Client: '/clienthome',
//       //   Admin: '/home'
//       // };

//       // if (location.pathname === '/' && redirectPaths[UserType.type]) {
//       //   navigate(redirectPaths[UserType.type], { replace: true });
//       // }
//       if (UserType?.type === "Admin" && location.pathname === "/admin") {
//         // navigate("/home")
//       }
//     }
//   }, [UserType, location.pathname]);
//   // <Route path="*" element={UserType?.type === "Admin" ? <Navigate to="/home" replace />:<Navigate to="/Admin" replace />} />

//   const publicRoutes = [
//     // { path: '/', element: <DummyPage /> },
//     { path: "/admin", element: <AdminLogin /> },
//     { path: "/admin/register", element: <AdminRegister /> },
//     // { path: '/clients', element: <ClientLogin /> },
//     // { path: '/client/register', element: <Register /> },
//     // { path: '/management', element: <ManagementLogin /> },
//     // { path: '/management/register', element: <Register /> },
//   ];

//   const protectedRoutes = {
//     Admin: [
//       { path: "/home", element: <Home /> },
//       { path: "/viewreport", element: <ViewReport /> },
//       { path: "/viewreport/:reportTempId", element: <ReportCustomize /> },
//       { path: "/createreport", element: <CreateReport /> },
//       { path: "/createreport/:reportTempId", element: <CreateReport /> },
//       { path: "/assignreport", element: <Assignreport /> },
//       {
//         path: "/assignreport/:assignreportId",
//         element: <CostumizeAssignreport />,
//       },
//       { path: "/securitywatch", element: <SecurityWatch /> },
//       { path: "/clientlist", element: <ClientList /> },
//       { path: "/clienthours", element: <ClientHours /> },
//       { path: "/invoicing", element: <Invoicing /> },
//       { path: "/assignpermissions", element: <AssignPermission /> },
//       { path: "/geolocationsetup", element: <Geolocationsetup /> },
//       { path: "/timeclocksetup", element: <Timeclocksetup /> },
//       { path: "/setupnewclient", element: <Setupnewclient /> },
//       { path: "/modifyclient", element: <Modifyclient /> },
//       { path: "/generateinvoice", element: <Generateinvoice /> },
//       { path: "/viewopeninvoices", element: <Viewopeninvoices /> },
//       { path: "/viewpaidinvoices", element: <Viewpaidinvoices /> },
//       { path: "/invoicestatement", element: <Invoicestatement /> },
//       { path: "/deleteInvoice", element: <DeleteInvoice /> },
//       { path: "/userclient", element: <UserListclient /> },
//       { path: "/security", element: <SecurityList /> },
//       { path: "/managements", element: <Managementlist /> },
//     ],

//     // Client: [
//     //   { path: '/clienthome', element: <ClientHome /> },
//     //   { path: '/clientreport', element: <ClientReport /> },
//     //   { path: '/clientreport/:clientReportId', element: <ViewClientReport /> },
//     // ],
//     // Management: [
//     //   { path: '/managementhome', element: <ManegmentHome /> },
//     //   { path: '/managementreport', element: <ManagementReport /> },
//     //   { path: '/viewmanagementreport/:managereportId', element: <ViewManagementReport /> },
//     // ],
//   };
//   // console.log("protectedRoutes[UserType.type]",protectedRoutes[UserType.type], protectedRoutes[UserType.type].find((item,index) => item.path === location.pathname))
//   return (
//     <UnsavedChangesContext.Provider
//       value={{ isChangesInCreateReport, setIsChangesInCreateReport }}
//     >
//       <Layout>
//         <Routes>
//           {/* Public Routes */}
//           {publicRoutes.map(({ path, element }) => (
//             <Route key={path} path={path} element={element} />
//           ))}

//           {protectedRoutes.Admin?.map((item, index) => (
//             <Route
//               key={index}
//               path={item.path}
//               // element={<ProtectedRoute element={item.element} />}
//               element={item.element}
//             />
//           ))}

//           {/* Catch-all route */}
//           <Route
//             path='*'
//             element={
//               UserType ? (
//                 <Navigate to='/home' replace />
//               ) : (
//                 <Navigate to='/admin' replace />
//               )
//             }
//           />
//         </Routes>
//       </Layout>
//     </UnsavedChangesContext.Provider>
//   );
// };

// export default Routers;
