import React, { createContext, useContext, useEffect, useState } from "react";
import { database } from "../config/config";
import { get, ref } from "firebase/database";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);
const ContextProvider = ({ children }) => {
  const [openReport, setOpenReport] = useState(false);
  const [openInVoicing, setOpenInVoicing] = useState(false);
  const [openscheduleGuardManagement, setOpenscheduleGuardManagement] =
    useState(false);

  const [showSideNav, setShowSideNav] = useState(true);

  const [isVisibleReport, setIsVisibleReport] = useState(false);
  const [isVisibleSchedule, setIsVisibleSchedule] = useState(false);
  const [isVisibleInvoicing, setVisibleInvoicing] = useState(false);
  const [isUserList, setIsUserList] = useState(false);
  const [OpenUserList, setOpenUserList] = useState(false);
  const [removeNav, setRemoveNav] = useState(false);
  const [ShowPopUp, setShowPopUP] = useState(false);
  const [popUpData, setPopUpData] = useState("");
  // SHOW ERROR POP UP
  // useEffect(() => {
  //   if (ShowPopUp === true) {
  //     setTimeout(() => {
  //       setShowPopUP(false);
  //     }, 3000);
  //   }
  // }, [ShowPopUp]);

  const HandlePopUpClose = () => {
    setShowPopUP(false);
  };

  const [isChangesInCreateReport, setIsChangesInCreateReport] = useState(false);

  // const handleSideNavClick = (path) => {
  //   // Only check for unsaved changes if navigating to "/profile"
  //   if (path === "/profile" && isChangesInCreateReport) {
  //     const confirmLeave = window.confirm(
  //       "You have unsaved changes. Do you want to save the file before leaving?"
  //     );
  //     if (confirmLeave) {
  //       navigate(path); // Proceed with navigation after saving
  //     } else {
  //       return; // Stay on the current page if the user cancels
  //     }
  //   } else {
  //     navigate(path); // Navigate directly if it's not the profile page or no unsaved changes
  //   }
  // };

  const User = JSON.parse(localStorage.getItem("maxUser"));

  // console.log("AppContextUserType", UserType?.type);

  // useEffect(() => {
  //   const handleLocationChange = () => {
  //     if (UserType === null || UserType === undefined) {
  //       window.location.reload();
  //     }
  //   };

  //   window.addEventListener("popstate", handleLocationChange);
  //   return () => {
  //     window.removeEventListener("popstate", handleLocationChange);
  //   };
  // }, [UserType]);

  const [UserType, setUserType] = useState(User);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      try {
        // Your auth check logic here
        // setUserType based on result
      } catch (error) {
        console.error("Auth check failed:", error);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);

  return (
    <AppContext.Provider
      value={{
        openReport,
        setOpenReport,
        openscheduleGuardManagement,
        setOpenscheduleGuardManagement,
        showSideNav,
        setShowSideNav,
        openInVoicing,
        setOpenInVoicing,
        isVisibleReport,
        setIsVisibleReport,
        isVisibleSchedule,
        setIsVisibleSchedule,
        isVisibleInvoicing,
        setVisibleInvoicing,
        isUserList,
        setIsUserList,
        OpenUserList,
        setOpenUserList,
        removeNav,
        setRemoveNav,
        ShowPopUp,
        setShowPopUP,
        popUpData,
        setPopUpData,
        HandlePopUpClose,
        isChangesInCreateReport,
        setIsChangesInCreateReport,
        UserType,
        isLoading,
        setIsLoading,
        // reportTemplates,
        // setReportTemplates,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
