import React from "react";

const Loader = () => {
  return (
    <div className="p-10"> 
      <div className='loader'></div>
    </div>
  );
};

export default Loader;
